import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { useAuth } from "../auth/authContext";
import SidebarMenu from "./sidebar";
import { BASE_URL } from '../utils/config';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';

const WebLinks = () => {
    const toast = React.useRef(null);
    const { userDetails } = useAuth();
    const [globalFilter, setGlobalFilter] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleAdd, setvisibleAdd] = useState(false);
    const [visibleUpdate, setvisibleUpdate] = useState(false);
    const [url, setUrl] = useState('');
    const [currentLinkId, setCurrentLinkId] = useState('');
    const [webLinks, setWebLinks] = useState([]);
    const rowsPerPage = 8;
    const navigate = useNavigate();

    const userRepo = JSON.parse(localStorage.getItem('userRepo'));


    useEffect(() => {
        if (!userRepo) {
            navigate("/repos");
        }
    },[]);

    useEffect(() => {
        if(userRepo){
            fetchWebLinks();
        } 
    }, []);

    const fetchWebLinks = async () => {
        try {
            if (!userDetails || !BASE_URL) {
                console.error('User details or BASE_URL not available');
                return;
            }

            const response = await fetch(`${BASE_URL}/team_details/get_webURL_details_by_UserId?UserId=${userDetails.UserId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            //console.log(data);

            const filteredWeblinks = data.filter(Weblink => Weblink.RepositoryId === userRepo.Id);
            setWebLinks(filteredWeblinks);
        } catch (error) {
            console.error('Error fetching web links:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch web links' });
        }
    };

    const onGlobalFilterChange = (e) => {
        setGlobalFilter(e.target.value);
        setCurrentPage(1);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end align-items-center">
                <Button label="Add" onClick={() => setvisibleAdd(true)} />
                <InputText
                    value={globalFilter}
                    onChange={onGlobalFilterChange}
                    placeholder="Search..."
                />
            </div>
        );
    };

    const createWebLink = async () => {
        try {
            if (url.trim() === "") {
                console.error(`URL can't be empty`);
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: `URL can't be empty` });
                return;
            }

            const newWebLink = {
                RepositoryId: userRepo.Id,
                URL: url,
                UserId: userDetails.UserId
            };

            const response = await fetch(`${BASE_URL}/create_WebURL`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify(newWebLink)
            });

            if (!response.ok) {
                throw new Error('Failed to create web link');
            }

            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Web link created successfully' });
            fetchWebLinks();
            setvisibleAdd(false);
            setUrl('');


        } catch (error) {
            console.error('Error creating web link:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to create web link' });
        }
    };

    const handleEdit = (rowData) => {
        setUrl(rowData.URL);
        setvisibleUpdate(true);
        setCurrentLinkId(rowData.Id);
    };

    const handleDelete = (rowData) => {
        setCurrentLinkId(rowData.Id);
        confirmDelete(rowData.Id);
    };

    const renderEditIcon = (rowData) => {
        return (
            <div className='flex gap-3'>
                <Tooltip target=".custom-target-icon" />

                 <i className={`pi pi-link custom-target-icon ${rowData.ProcessingStatus === 'Complete' ? 'disabled-icon' : ''}`}
                    onClick={rowData.ProcessingStatus !== 'Complete' ? () => handleLinkWeblink(rowData) : null}
                    style={{ cursor: rowData.ProcessingStatus === 'Complete' ? 'not-allowed' : 'pointer' }}
                    data-pr-tooltip={rowData.ProcessingStatus === 'Complete' ? 'Action disabled' : 'Link the document'}
                    data-pr-position="left"></i>
                {/* <i
                    className="pi pi-link custom-target-icon"
                    onClick={() => handleLinkWeblink(rowData)}
                    data-pr-tooltip="Link the weblink"
                    data-pr-position="left"
                    style={{ cursor: 'pointer' }}
                ></i> */}
                {/* <i
                    className="pi pi-minus-circle custom-target-icon"
                    onClick={() => handleUnLinkWeblink(rowData)}
                    data-pr-tooltip="un-Link the weblink"
                    data-pr-position="top"
                    style={{ cursor: 'pointer' }}
                ></i> */}
                <i
                    className="pi pi-pencil custom-target-icon"
                    onClick={() => handleEdit(rowData)}
                    data-pr-tooltip="Edit weblink"
                    data-pr-position="bottom"
                    style={{ cursor: 'pointer' }}></i>
                <i
                    className="pi pi-trash custom-target-icon"
                    onClick={() => handleDelete(rowData)}
                    data-pr-tooltip="Delete weblink"
                    data-pr-position="right"
                    style={{ cursor: 'pointer' }}></i>
            </div>
        );
    };

    const handleLinkWeblink = async (data) => {
        console.log("data=========>",data)
        try {

            //     toast.current.show({ severity: 'warn', summary: 'Warning', detail: `ALl the feils are required` });

            const url = `${BASE_URL}/call_apis_after_bot_configuration`;
            const params = new URLSearchParams({
                func:"WebIndex",
                user_id:data.UserId,
                repository_id:data.RepositoryId,
                root_url:data.URL
            });
            // const NewTeamMemberDetails = {
            //     UserId:""
            // };
            await fetch(`${url}?${params.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                // body: JSON.stringify(NewTeamMemberDetails)
            })
                .then(response => {
                    if (!response.ok) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed Link the weblink' });
                        throw new Error('Network response was not ok');

                    }
                    return response.json();
                })
                .then(data => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'weblink linked successfully' });
                    // Handle the API response if needed
                    console.log("data", data);
                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error:', error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed link weblink' });
                });
        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error });
        }
    };

    const handleUnLinkWeblink = async (data) => {
        try {

            //toast.current.show({ severity: 'warn', summary: 'Warning', detail: `ALl the feils are required` });

            const url = `${BASE_URL}/update_team_details`;
            const params = new URLSearchParams({
                member_id:"",
            });
            const NewTeamMemberDetails = {
                UserId:""
            };
            await fetch(`${url}?${params.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify(NewTeamMemberDetails)
            })
                .then(response => {
                    if (!response.ok) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed Link the document' });
                        throw new Error('Network response was not ok');

                    }
                    return response.json();
                })
                .then(data => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Docuent linked successfully' });
                    // Handle the API response if needed
                    console.log("data", data);
                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error:', error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Update Team member Details' });
                });
        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error });
        }
    };

    const confirmDelete = (id) => {
        confirmDialog({
            message: 'Are you sure you want to delete this web link?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteWebLink(id),
            reject: () => toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 })
        });
    };

    const handleUpdateWebLink = async () => {
        try {
            if (url.trim() === "") {
                console.error(`URL can't be empty`);
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: `URL can't be empty` });
                return;
            }

            const updatedWebLink = {
                webURL_id: currentLinkId,
                RepositoryId: userRepo.Id,
                URL: url,
                UserId: userDetails.UserId
            };

            const response = await fetch(`${BASE_URL}/update_webURL?webURL_id=${currentLinkId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify(updatedWebLink)
            });

            if (!response.ok) {
                throw new Error('Failed to update web link');
            }

            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Web link updated successfully' });
            fetchWebLinks();
            setvisibleUpdate(false);
            setCurrentLinkId('');
            setUrl('');

        } catch (error) {
            console.error('Error updating web link:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update web link' });
        }
    };

    const deleteWebLink = async (id) => {
        try {
            const response = await fetch(`${BASE_URL}/delete_webURL?webURL_id=${id}`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete web link');
            }

            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Web link deleted successfully' });
            fetchWebLinks();

        } catch (error) {
            console.error('Error deleting web link:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete web link' });
        }
    };

    return (
        <div>
            {userRepo && (
                <div className="app-container">
                    <Toast ref={toast} />
                    <SidebarMenu />
                    <div className="content">
                    <span>
                        <Button icon="pi pi-arrow-left" rounded outlined onClick={() => navigate('/repos')} severity="success" className="mb-2" />
                    </span>
                        <div className="flex justify-content-between align-items-center mb-4">
                            <h2>{userRepo.RepositoryName}</h2>
                            <div className="flex gap-3">
                                <Button icon="pi pi-refresh" onClick={fetchWebLinks} />
                            </div>
                        </div>
                        <DataTable value={webLinks} paginator responsiveLayout="scroll"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="{first} to {last} of {totalRecords} entries"
                            rows={rowsPerPage} totalRecords={webLinks.length}
                            onPageChange={(e) => setCurrentPage(e.page + 1)}
                            first={(currentPage - 1) * rowsPerPage}
                            globalFilter={globalFilter}
                            header={renderHeader()}>
                            <Column field="URL" header="URL" sortable ></Column>
                            <Column field="ProcessingStatus" header="Processing Status" sortable ></Column>
                            <Column header="Action" body={renderEditIcon}></Column>
                        </DataTable>
                    </div>

                    <Dialog header="Add Web Link" visible={visibleAdd} style={{ width: '50vw' }} onHide={() => setvisibleAdd(false)}>
                        <div style={{ padding: "20px" }}>
                            <span className="p-float-label mb-5" style={{ width: '100%' }}>
                                <InputText id="url" value={url} onChange={(e) => setUrl(e.target.value)} style={{ width: '100%' }}/>
                                <label htmlFor="url">URL</label>
                            </span>
                            <div className="flex justify-content-end mt-4">
                                <Button label="Cancel" className="p-button-text mr-2" onClick={() => setvisibleAdd(false)} />
                                <Button label="Submit" onClick={createWebLink} />
                            </div>
                        </div>
                    </Dialog>

                    <Dialog header="Update Web Link" visible={visibleUpdate} style={{ width: '50vw' }} onHide={() => {
                        setvisibleUpdate(false);
                        setCurrentLinkId('');
                    }}>
                        <div style={{ padding: "20px" }}>
                            <span className="p-float-label mb-5" style={{ width: '100%' }}>
                                <InputText id="url" value={url} onChange={(e) => setUrl(e.target.value)} />
                                <label htmlFor="url">URL</label>
                            </span>
                            <div className="flex justify-content-end mt-4">
                                <Button label="Cancel" className="p-button-text mr-2" onClick={() => {
                                    setvisibleUpdate(false);
                                    setCurrentLinkId('');
                                }} />
                                <Button label="Update" onClick={handleUpdateWebLink} />
                            </div>
                        </div>
                    </Dialog>
                </div>
            )}
        </div>
    );
}

export default WebLinks;
