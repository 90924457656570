import {
	AnimationClip,
	BooleanKeyframeTrack,
	ColorKeyframeTrack,
	NumberKeyframeTrack,
	Vector3,
	VectorKeyframeTrack
} from 'three';

var fps = 60


function createAnimation (recordedData, morphTargetDictionary, bodyPart) {
  //console.log(recordedData);
  let flag = (bodyPart === 'Teeth_Mesh');


  //console.log("----morphTargetDictionary","---------", bodyPart, morphTargetDictionary)

  if (recordedData.length != 0) {
    let animation = []
    for (let i = 0; i < Object.keys(morphTargetDictionary).length; i++) {
      animation.push([])
    }
    let time = []
    let finishedFrames = 0
    //console.log(recordedData);
    recordedData.forEach((d, i) => {
        Object.entries(d.blendshapes).forEach(([key, value]) => {
          if(flag && key === 'jawOpen') key = 'mouthOpen';
          if (! (key in morphTargetDictionary)) {return}

          // if (key == 'mouthShrugUpper') {
          //   value += 0.4;
          // }
          

          animation[morphTargetDictionary[key]].push(value)
        });
        time.push(finishedFrames / fps)
        finishedFrames++

    })

    //console.log("-----animation", animation);

    let tracks = []

    //create morph animation
    Object.entries(recordedData[0].blendshapes).forEach(([key, value]) => {
      if(flag && key === 'jawOpen') key = 'mouthOpen';

      if (! (key in morphTargetDictionary)) {return};

      let i = morphTargetDictionary[key]

      let track = new NumberKeyframeTrack(`${bodyPart}.morphTargetInfluences[${i}]`, time, animation[i])
      tracks.push(track)

    });

    const clip = new AnimationClip('animation', -1, tracks);
    return clip
  }
  return null
}

export default createAnimation;