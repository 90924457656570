import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Button } from 'primereact/button';
import { FileUpload } from "primereact/fileupload";
import { ProgressSpinner } from "primereact/progressspinner";
import SidebarMenu from "./sidebar";
import { useAuth } from "../auth/authContext";
import { BASE_URL } from "../utils/config";
import { useNavigate } from "react-router-dom";

export default function AdvanceDemo() {
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [bucketName, setBucketName] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const navigate = useNavigate();

    const { userDetails } = useAuth();
    //console.log(userDetails);

    const userRepo = JSON.parse(localStorage.getItem('userRepo'));

    useEffect(()=>{
        if(!userRepo){
            navigate("/repos");
        }
    },[]);

    const initialFunction = async () =>{
        if(!accessToken){
            const newToken = await getAccessToken();
            setAccessToken(newToken);   
        }   
    }

    const initialBucketCreation = async () => {
        try {
            const newBucketName = userDetails.FirstName.toLowerCase() + userDetails.UserId;
            const bucketExists = await checkBucketExists(newBucketName);
            if (!bucketExists) {
                const bucket = await createNewBucket();
                setBucketName(bucket);
            }else{
                setBucketName(newBucketName);
            }
        } catch (error) {
            console.error('Error during initial bucket creation:', error);
        }
    };

    useEffect(() => {
        initialFunction();
    }, []);

    useEffect(()=>{
        initialBucketCreation();
    },[accessToken]);
    

    const createNewBucket = async () => {
        if (!accessToken) {
            console.log('No access token');
            return;
        }
    
        const url = 'https://storage.googleapis.com/storage/v1/b';
        const params = new URLSearchParams({ 'project': 'max-prod-417007' });
        const headers = {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
        };
        const data = {
            "name": userDetails.FirstName.toLowerCase() + userDetails.UserId,
            "location": "ASIA-SOUTH1",
            "storageClass": "STANDARD",
            "iamConfiguration": {
                "uniformBucketLevelAccess": {
                    "enabled": true
                }
            }
        };
    
        try {
            const response = await fetch(`${url}?${params.toString()}`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error:', errorData);
            } else {
                const responseData = await response.json();
                console.log('Success:', responseData);
                return responseData.name;
            }
        } catch (error) {
            console.error('Error creating bucket:', error);
        }
    };
    

    const checkBucketExists = async (bucketName) => {
        const url = `https://storage.googleapis.com/storage/v1/b/${bucketName}`;
        const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };
    
        try {
            const response = await fetch(url, { method: 'GET', headers });
            if (response.ok) {
                console.log(`Bucket ${bucketName} exists`);
                return true;
            } else if (response.status === 404) {
                console.log(`Bucket ${bucketName} does not exist`);
                return false;
            } else {
                throw new Error(`Failed to check bucket: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error checking bucket existence:', error);
            return false;
        }
    };
    
    const onTemplateUpload = (e) => {
        e.files.forEach((file) => {
            console.log("file========================>", file);
            // Create a FileReader to read the file as a Data URL
            const reader = new FileReader();
            reader.onload = (event) => {
                // Get the data URL representing the image
                const imageData = event.target.result;

                // // Send the image data to the API
                // fetch('https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/bot_studio/create_document?UserID=1019', {
                //     method: 'POST',
                //     headers: {
                //         'Accept': 'application/json',
                //         'Content-Type': 'application/json'
                //     },
                //     body: JSON.stringify({ imageData: imageData })
                // })
                fetch(
                    "https://storage.googleapis.com/upload/storage/v1/b/dynapt-max-rag/o?uploadType=media&name=hello",
                    {
                        method: "POST",
                        headers: {
                            Authorization:
                                "Bearer ya29.a0AXooCgsQia12cZCMJNFuzT1E2mBYDRd4YJuTiGBKGTskBFXiF_ZVYk70jx4X8Se2iiC5QBBT4DfVkhkHV3nemeA5JdKbxKT-hnw1kEdQMa_evWnfvplxMex5QBiPiJwKvcsEQmkhVv0IeqnUltQigjokCIYb-f2KgeQC1cwKp-9haCgYKAYoSARISFQHGX2MiJ8Nvg4k3OG3o9iyN1kS1qw0179",
                            "Content-Type": file.type,
                        },
                        body: file,
                    }
                )
                    .then((response) => {
                        if (response.ok) {
                            toast.current.show({ severity: "info", summary: "Success", detail: "Image Uploaded", });
                        } else {
                            toast.current.show({ severity: "error", summary: "Error", detail: "Failed to upload image", });
                        }
                    })
                    .catch((error) => {
                        console.error("Error uploading image:", error);
                        toast.current.show({ severity: "error", summary: "Error", detail: "Failed to upload image", });
                    });
            };
            // Read the file as a Data URL
            reader.readAsDataURL(file);
        });
    };

    const onUpload = async (event) => {
        setIsLoading(true);
        try {

            event.files.forEach(async (file) => {
                const objectLocation = file; // The file object
                const objectContentType = file.type; // The file's MIME type
                const objectName = file.name; // The name of the file

                console.log("objectLocation================>", objectLocation);
                console.log("objectContentType================>", objectContentType);
                console.log("objectName================>", objectName);

                const url = `https://storage.googleapis.com/upload/storage/v1/b/${bucketName}/o?uploadType=media&name=${userRepo.RepositoryName}/to-be-uploaded/${objectName}`;


                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": objectContentType,
                    },
                    body: objectLocation,
                });

                if (response.ok) {
                    console.log("File uploaded successfully");
                    console.log("response============>", response);
                    toast.current.show({ severity: "success", summary: "Success", detail: `${file.name} File uploaded successfully`, });

                    const postResponse = await fetch(`${BASE_URL}/create_document`, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        FileName: file.name,
                        FileType: file.type,
                        FileURL: response.url,
                        RepositoryId: userRepo.Id,
                        UserId: userDetails.UserId
                    })
                    });

                    if(postResponse.ok){
                        navigate("/documents");
                    }

                } else {
                    console.error("File upload failed", response.statusText);
                    toast.current.show({ severity: "error", summary: "Error", detail: `${file.name}File upload failed`, });
                }
            });
        } catch (error) {
            console.error("Error uploading file", error);
            toast.current.show({ severity: "error", summary: "Error", detail: `File upload failed`, });
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const getAccessToken = async () => {
        try {
            const response = await fetch(
                "https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/get_google_auth_access_token",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.log("error while generating access token ");
        }
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i
                    className="pi pi-image mt-3 p-5"
                    style={{
                        fontSize: "5em",
                        borderRadius: "50%",
                        backgroundColor: "var(--surface-b)",
                        color: "var(--surface-d)",
                    }}
                ></i>
                <span
                    style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
                    className="my-5"
                >
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };

    return (
        <div>
            <div className="app-container">
                <SidebarMenu />
                <span style={{marginLeft: '15px'}}>
                        <Button icon="pi pi-arrow-left" rounded outlined onClick={() => navigate('/documents')} severity="success" className="mb-2" />
                </span>
                <div className="flex justify-content-between align-items-center mb-4">
                    
                    </div>
                <div className="content">
                    <Toast ref={toast}></Toast>
                    {isLoading && <ProgressSpinner />}
                    <FileUpload
                        name="file"
                        multiple
                        customUpload
                        uploadHandler={onUpload}
                        emptyTemplate={emptyTemplate}
                        chooseLabel="Choose"
                        uploadLabel="Upload"
                        cancelLabel="Cancel"
                    />
                </div>
            </div>
        </div>
    );
}
