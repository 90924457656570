import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { Tooltip } from 'primereact/tooltip';
import SidebarMenu from "./sidebar";
import { Toast } from 'primereact/toast';
import { Panel } from 'primereact/panel';
import { useAuth } from "../auth/authContext";
import { BASE_URL } from '../utils/config'
import { confirmDialog } from 'primereact/confirmdialog';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import Templates from './Template';
// import 'primereact/resources/primereact.min.css';
// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primeicons/primeicons.css';

const Chatbots = () => {
    const toast = React.useRef(null);
    const { userDetails, userPackage ,fetchBotDataById} = useAuth();
    const navigate = useNavigate();
    const [chatbots, setchatbots] = useState([]);
    const [template, settemplate] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showTemplateDialog, setshowTemplateDialog] = useState(false);
    const [newCardName, setNewCardName] = useState('');

    const initial_form = {
        Logo: '',
        themeColor: '53AA59',
        welcomeMessage: "Hi,I am your PDA.I can walk through your queries regarding IT related issues.",
        fallbackMessage: "I apologize, but I am currently unable to respond. Please try again later.",
        BotName: "",
        orgname: "",
        orginfo: "",
        contactinfo: "",
        repoId: 0,
        IntegrationId:[],
        avatarName: "Avaatar",
        chatTypeOptions: [
            { name: 'Chat Only', code: 'ChatOnly' },
            { name: 'Chat And Avatar', code: 'ChatAndAvatar' },
            { name: 'Avatar Only', code: 'AvatarOnly' },
            { name: 'Chat and Audio', code: 'ChatAndAudio' },
        ],
        llmModel: 'gpt3.5',
        languages: [{ name: 'English', code: 'eng' }],
        userId: userDetails.UserId,
        flow: ""
    }
    const [form, setForm] = useState(initial_form);
    const count = useRef(0);

    useEffect(() => {
        if (count.current === 0) {
            fetchconfigurations();
            fetchTemplate();
            count.current += 1;
        }
    }, []);

    const fetchconfigurations = async () => {
        try {

            if (!userDetails) {
                console.error('User details not available');
                return;
            }

            if (!BASE_URL) {
                console.error('BASE_URL not defined in the environment');
                return;
            }

            await fetch(`${BASE_URL}/team_details/get_bot_configuration_details_by_UserId?UserId=${userDetails.UserId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            })
                .then(response => {
                    if (!response.ok) {
                        setchatbots([])
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Network response was not ok' });
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log("configurations DATA===============>", data)
                    setchatbots(data)
                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error:', error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch configurations' });
                });

        } catch (error) {
            console.log("error", error)
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'error' });
        }

    };
    const fetchTemplate = async () => {
        try {

            if (!BASE_URL) {
                console.error('BASE_URL not defined in the environment');
                return;
            }

            await fetch(`${BASE_URL}/get_all_template`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            })
                .then(response => {
                    if (!response.ok) {
                        settemplate([])
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Network response was not ok' });
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log("Templates DATA===============>", data)
                    settemplate(data)
                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error:', error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch Templates' });
                });

        } catch (error) {
            console.log("error", error)
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'error' });
        }

    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(prev => ({ ...prev, [name]: value }));
    };

    // const addCard = () => {
    //     if (form.botName.trim()) {
    //         setchatbots([...cards, form.botName]);
    //         SaveBotConfig()
    //         setForm(prev => ({ ...prev, [form.botName]: '' }));
    //         setShowDialog(false);
    //     }
    // };

    const SaveBotConfig = async () => {
        try {

            if (!BASE_URL) {
                console.error('BASE_URL not defined in the environment');
                return;
            }
            if (form.BotName.trim() === "") {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter bot name' });
                return
            }

            await fetch(`${BASE_URL}/create_bot_configuration`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    imageData: form.Logo,
                    ThemeColor: form.themeColor,
                    WelcomeMessage: form.welcomeMessage,
                    FallbackMessage: form.fallbackMessage,
                    BotName: form.BotName,
                    OrganizationName: form.orgname,
                    OrganizationInfo: form.orginfo,
                    ContactInfo: form.contactinfo,
                    RepositoryId: form.repoId,
                    AvatarName: form.avatarName,
                    ChatTypeOptions: form.chatTypeOptions,
                    LLMModel: form.llmModel,
                    Languages: form.languages,
                    UserId: form.userId === "" ? userDetails.UserId : form.userId,
                    flow: form.flow
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Network response was not ok' });
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'bot created successfully' });
                    setShowDialog(false)
                    fetchconfigurations();
                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error:', error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch TeamMembers' });
                });
        } catch (error) {
            console.log("error while saving the Data", error)
        }
    };
    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const confirm = (id) => {
        confirmDialog({
            message: 'Are you sure you want to delete this chatbot ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => DeleteConfig(id),
            reject
        });
    };

    const DeleteConfig = async (id) => {
        try {

            const url = `${BASE_URL}/delete_bot_configuration?configuration_id=${id}`;
            await fetch(url, {
                method: 'POST',
                headers: {
                    'accept': 'application/json'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete chatbot' });
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log("============================>>>>>>>>>>>>>>>>>>>>>>", data);
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'chatbot deleted successfully' });
                    fetchconfigurations();
                    // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                })
                .catch(error => {
                    // Handle errors here
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete chatbot' });
                    console.error('Error:', error);
                });
        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error });
        }
    }


    const handleCopyEmbed = (url) => {
        const embedCode = `<iframe src="https://askdynachatbot.web.app${url}" width="600" height="400" allow="microphone"></iframe>`;
        navigator.clipboard.writeText(embedCode).then(() => {
            // alert('Embed code copied to clipboard');
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Embed code copied to clipboard' });
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const acceptTemplate = async (TemplatechatbotData) => {
        try {

            if (!BASE_URL) {
                console.error('BASE_URL not defined in the environment');
                return;
            }

            await fetch(`${BASE_URL}/create_bot_configuration`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    imageData:'',
                    ThemeColor:'53AA59',
                    WelcomeMessage:`Hi, I'm your Personal Digital Assistant. I'm here to assist you with any inquiries or issues you might have related to our products and services. How can I help you today?`,
                    FallbackMessage: `I apologize, but I am currently unable to respond. Please try again later.`,
                    BotName: TemplatechatbotData.Name,
                    OrganizationName:'',
                    OrganizationInfo:'',
                    ContactInfo:'',
                    RepositoryId: 0,
                    AvatarName:'Avaatar',
                    ChatTypeOptions:[
                        { name: 'Chat Only', code: 'ChatOnly' },
                        { name: 'Chat And Avatar', code: 'ChatAndAvatar' },
                        { name: 'Avatar Only', code: 'AvatarOnly' },
                        { name: 'Chat and Audio', code: 'ChatAndAudio' },
                    ],
                    LLMModel:'gpt3.5',
                    Languages: [{ name: 'English', code: 'eng' }],
                    UserId: userDetails.UserId,
                    TemplateId: TemplatechatbotData.Id
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Network response was not ok' });
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'bot created successfully' });
                    setShowDialog(false)
                    setshowTemplateDialog(false)
                    fetchconfigurations();
                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error:', error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch TeamMembers' });
                });
        } catch (error) {
            console.log("error while saving the Data", error)
        }
    }

    const rejectTemplate = () => {


    }

    const confirmChooseTemplate = (TemplatechatbotData) => {
        confirmDialog({
            message: 'Are you sure you want to use this Template ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => acceptTemplate(TemplatechatbotData),
            reject: rejectTemplate()
        });
    };

    const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

    const handleAddChatbot = () =>{
        const noOfBots = chatbots.length;
        console.log(noOfBots);

        if (userPackage === 'Free' && noOfBots>=1) { 
            setShowUpgradeDialog(true);
            return;
        } else if (userPackage === 'Basic' && noOfBots >= 3) {
            setShowUpgradeDialog(true);
            return;
        } else if (userPackage === 'Advanced') {
            // No restriction for Advanced package
        }

        setShowDialog(true);
    }

    const handleEditClick = async(chatbot)=>{
        localStorage.setItem('chatbotGuid',JSON.stringify(chatbot.Guid));
        await fetchBotDataById(chatbot.Guid).then(()=>{
            navigate('/botstudio',{ state: { chatbotID: chatbot.Guid} })
        })

    }


    return (
        <div className="app-container">
            <Toast ref={toast} />
            <SidebarMenu />
            <div className="content">
                <div className="p-m-5">
                    <Panel header="Chat Bots" className="mb-3">
                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', margin: '10px 10px 20px 0px' }}>
                            {/* <h2 className="p-text-center">Chat Bots</h2> */}
                            <Button label="Create Chatbot" icon="pi pi-plus" onClick={handleAddChatbot} />
                        </div>
                        <div className="grid">
                            {chatbots.map((chatbot, index) => (
                                <div className="col-12 md:col-6 lg:col-3">
                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-600 font-medium mb-3">{chatbot.BotName}</span>
                                                <div className="text-500 font-medium text-l">
                                                    <a href={`https://bot.dynaptmax.ai/${chatbot.Guid}`} target="_blank" rel="noopener noreferrer">
                                                        <i className="pi pi-link"></i> Link
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="flex gap-2">
                                                <Tooltip target=".flex" />
                                                <div
                                                    className="flex align-items-center justify-content-center bg-blue-100 border-round"
                                                    style={{ width: '2.5rem', height: '2.5rem', cursor: "pointer" }}
                                                    onClick={() =>handleEditClick(chatbot)}
                                                    data-pr-tooltip="Edit"
                                                    data-pr-position="left"
                                                >
                                                    <i className="pi pi-pencil text-blue-500 text-xl"></i>
                                                </div>
                                                <div
                                                    className="flex align-items-center justify-content-center bg-orange-100 border-round"
                                                    style={{ width: '2.5rem', height: '2.5rem', cursor: "pointer" }}
                                                    onClick={() => confirm(chatbot.Id)}
                                                    data-pr-tooltip="Delete"
                                                    data-pr-position="right"
                                                >
                                                    <i className="pi pi-trash text-orange-500 text-xl"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            label="Copy Embed"
                                            icon="pi pi-copy"
                                            onClick={() => handleCopyEmbed(`/${chatbot.Guid}`)}
                                            className="p-button-sm p-button-secondary"
                                            tooltip="Copy embed code" tooltipOptions={{ position: 'right' }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Panel>

                    <Dialog header="Choose Template" visible={showTemplateDialog} onHide={() => setshowTemplateDialog(false)}
                     style={{ width: '100vw' ,maxHeight:'100%'}}>
                    <Panel header="Start with a template" className="p-mb-3">
                        <div className="grid justify-content-center gap-4 pt-5">
                            {template.map((chatbot, index) => (
                                <div className="col-12 md:col-6 lg:col-3" key={index}>
                                    <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round ">
                                        <div className="flex flex-column justify-content-center">

                                            <span className="block text-800 font-medium mb-3">{chatbot.Name}</span>
                                            <div className="text-500 font-medium text-l">
                                                {chatbot.Description}
                                            </div>

                                        </div>
                                        <div className="flex justify-content-center pt-5 pb-5">
                                            <Button
                                                label="Try it Out !"
                                                severity="info"
                                                outlined
                                                onClick={() => confirmChooseTemplate(chatbot)}
                                                className="p-button-sm p-button-success"
                                                tooltip="Use this Template" tooltipOptions={{ position: 'bottom' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Panel>
                    </Dialog>




                    <Dialog header="Create a blank Chatbot" visible={showDialog} style={{ width: '30vw' }} onHide={() => setShowDialog(false)}>
                        <div className="p-fluid">
                            <div className="p-field mb-2">
                                <label htmlFor="BotName">Name</label>
                                <InputText id="BotName" value={form.BotName} name="BotName" onChange={handleChange} />
                            </div>
                            <div className="p-d-flex p-jc-end">
                                <Button label="Add" icon="pi pi-check" onClick={SaveBotConfig} />
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="divider">
                                <div className="line"></div>
                                <div className="or">OR</div>
                                <div className="line"></div>
                            </div>
                            <div className="p-d-flex p-jc-end">
                                <Button label="Choose from Template" icon="pi pi-arrow-right" severity="success" rounded 
                                onClick={()=>{
                                    setShowDialog(false)
                                    setshowTemplateDialog(true)
                                }} />
                            </div>
                        </div>
                    </Dialog>


                    <Dialog header="Upgrade Required" visible={showUpgradeDialog} style={{ width: '50vw' }} modal onHide={() => setShowUpgradeDialog(false)}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <p>Please upgrade your plan to add more team members.</p>
                        </div>
                        <div className="p-col-12">
                            <button onClick={()=>{ navigate('/subscription')}} className="p-button p-component">
                                Upgrade
                            </button>
                        </div>
                    </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Chatbots;











