import React from 'react';
import { useNavigate } from 'react-router-dom';
import { doSignOut } from "../auth/firebase/auth";

const VerifyEmailPage = () => {
    let navigate = useNavigate();

    return (
            <div className="p-2 bg-blue-100 text-blue-800 text-center rounded-md">
                    <p>Please check your email and click the verification link to continue.</p>
                    <button className="ml-auto underline hover:text-blue-600" onClick={() => { doSignOut().then(() => { navigate('/') }) }}>Use another email</button>
            </div>
    );
};

export default VerifyEmailPage;
