import React, { useEffect, useRef, useState } from 'react';
import { WHATSAPP_BASE_URL, BASE_URL } from '../utils/config';
import SidebarMenu from './sidebar';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useAuth } from '../auth/authContext';

function WhatsApp() {
    const toast = useRef(null);
    const [qrCode, setQrCode] = useState(null);
    const [Id, setId] = useState(null);
    const [deviceId, setDeviceId] = useState(null);
    const [isLegacy, setIsLegacy] = useState(false);
    const [connected, setConnected] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [userName, setUserName] = useState(null);
    const navigate = useNavigate();
    const { userDetails } = useAuth();
    
    const selectedIntegrationId = Number(localStorage.getItem('selectedIntegrationId'));

    useEffect(()=>{
        fetchConnectionDetails();
    },[]);

    const fetchConnectionDetails = async () =>{
        try{
            const response = await fetch(`${BASE_URL}/get_whatsapp_integration_by_IntegrationId?IntegrationId=${selectedIntegrationId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if(response.ok){
                const data = await response.json();
                console.log(data);
                setConnected(true);
                setId(data[0].Id);
                setDeviceId(data[0].DeviceId);
                setPhoneNumber(data[0].MobilePhone);
                setUserName(data[0].UserName); 
            }    
        } catch (error) {
            console.log(error);
        }
    }

    // const startCheckingMessages = async (id) => {
    //     const currentDeviceId = id || deviceId;
    //     try {
    //         console.log('Checking latest message', currentDeviceId);
    //         const response = await fetch(`${WHATSAPP_BASE_URL}/bot/startCheckingMessages/${currentDeviceId}`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });

    //         if (!response.ok) {
    //             console.error('Failed to get latest message');
    //             console.log('Connection removed');
    //             resetConnection();
    //         } else {
    //             const data = await response.json();
    //             console.log('latest message data', data);

    //             if (!data.success) {
    //                 console.error(data.message);
    //             } else {
    //                 //setStart(true);
    //                 //localStorage.setItem('start', true);
    //                 console.log(data.message);
    //             }
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const stopCheckingMessages = async (id) => {
        const currentDeviceId = id || deviceId;
        try {
            console.log('Checking latest message', currentDeviceId);
            const response = await fetch(`${WHATSAPP_BASE_URL}/bot/stopCheckingMessages/${currentDeviceId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                console.error('Failed to stop ');
                console.log('Connection removed');
                resetConnection();
            } else {
                const data = await response.json();
                console.log('data', data);

                if (!data.success) {
                    console.error(data.message);
                } else {
                    //setStart(false);
                    //localStorage.setItem('start', false);
                    console.log(data.message);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const resetConnection = () => {
        setQrCode(null);
        setUserName(null);
        setPhoneNumber(null);
        setDeviceId(null);
        setConnected(false);
    };

    const deviceIdAvailiable = async (deviceId) =>{
        try{
            const response = await fetch(`${BASE_URL}/get_whatsapp_integration_by_DeviceId?DeviceId=${deviceId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if(!response.ok){
                return true;
            }

        } catch (error) {
            console.log(error);
        }
        return false;
    }

    const generateQRCode = async () => {
        const newDeviceId = 'device_' + Math.floor(Math.random() * 10000000);
        try {
            if (newDeviceId && deviceIdAvailiable(newDeviceId)) {
                console.log('Connecting to WhatsApp newDeviceId', newDeviceId);
                const response = await fetch(`${WHATSAPP_BASE_URL}/sessions/add`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id: newDeviceId, isLegacy }),
                });

                const data = await response.json();

                if (!data.success) {
                    console.error('Failed to connect to WhatsApp');
                } else {
                    console.log('Connected to WhatsApp');
                    setQrCode(data.data.qr);
                    setTimeout(() => findSession(newDeviceId), 10000);
                }
            } else {
                console.log('Device Id is null');
            }
        } catch (error) {
            setQrCode(null);
            console.error(error);
        }
    };

    const findSession = async (id) => {
        try {
            const currentDeviceId = id || deviceId;
            console.log(`Finding session ${currentDeviceId}`);
            const response = await fetch(`${WHATSAPP_BASE_URL}/sessions/find/${currentDeviceId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            console.log('Response', data);

            if (!data.success) {
                console.log('Session not found');
            } else {
                setDeviceId(id);
                sessionStatus(id);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const sessionStatus = async (id) => {
        try {
            const currentDeviceId = id || deviceId;
            console.log("deviceId in sessionStatus", deviceId);
            console.log('Checking session status');
            const response = await fetch(`${WHATSAPP_BASE_URL}/sessions/status/${currentDeviceId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            console.log('Connection data', data);

            if (!data.success) {
                setDeviceId(null);
                console.error('Failed to check connection');
            } else {
                if (data.data.valid_session) {
                    setDeviceId(currentDeviceId);
                    const newPhoneNumber = data.data.userinfo.id.split(':')[0]
                    setPhoneNumber(newPhoneNumber);
                    setUserName(data.data.userinfo.name);
                    setConnected(true);
                    setQrCode(null); // Clear the QR code once connected
                    storeConnectionDetails(currentDeviceId, newPhoneNumber, data.data.userinfo.name);
                } else {
                    removeConnection(currentDeviceId);
                }
            }
        } catch (error) {
            console.error(error);
            setConnected(false);
            setPhoneNumber(null);
            setDeviceId(null);
            setUserName(null);
        }
    };

    const storeConnectionDetails = async (deviceId, phoneNumber, userName) => {
        try {
            const response = await fetch(`${BASE_URL}/create_whatsapp_integration`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ DeviceId: deviceId, IntegrationId: selectedIntegrationId , MobilePhone: phoneNumber, UserId: userDetails.UserId, UserName: userName}),
            });
            if (response.ok) {
                const data = await response.json();
                //await startCheckingMessages(deviceId);
                fetchConnectionDetails();
                console.log('Connection details stored successfully');
            } else {
                removeConnection(deviceId);
                console.error('Failed to store connection details');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const removeConnection = async (id) => {
        await stopCheckingMessages(id);
        try {
            const currentDeviceId = id;
            console.log('Removing connection', currentDeviceId);

            const response = await fetch(`${WHATSAPP_BASE_URL}/sessions/delete/${currentDeviceId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            if (!data.success) {
                console.error('Failed to remove connection');
            } else {
                console.log('Connection removed');       
            }
            removeConnectionDetails();
        } catch (error) {
            console.error(error);
        }
    };

    const removeConnectionDetails = async () => {
        console.log(Id);
        try {
            const response = await fetch(`${BASE_URL}/delete_whatsapp_integration?Id=${Id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                //console.log(data);
                console.log('Connection details removed successfully');
                resetConnection();
            } else {
                console.error('Failed to remove connection details');
            }
        } catch (error) {
            console.error(error);
        }
    };

    console.log("outside deviceId", deviceId);

    return (
        <div>
            <Toast ref={toast} />
            <SidebarMenu />
            <span style={{ marginLeft: '20px' }}>
                <Button icon="pi pi-arrow-left" rounded outlined onClick={() => navigate('/integrations')} severity="success" />
            </span>
            <div style={{ margin: 0, fontFamily: 'Arial, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <div className="App" style={{ background: '#fff', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', padding: '20px', maxWidth: '400px', width: '100%', textAlign: 'center' }}>
                    <div className="content" style={{ padding: '10px' }}>
                        {connected ? (
                            <>
                                <div className="connected">
                                    <h3 style={{ color: '#4CAF50' }}>Connected to WhatsApp</h3>
                                    <p>User Name: {userName}</p>
                                    <p>User Phone Number: {phoneNumber}</p>
                                    <p>Device ID: {deviceId}</p>
                                </div>
                                <button className="button" style={{ backgroundColor: '#007BFF', color: 'white', border: 'none', padding: '10px 20px', marginTop: '20px', borderRadius: '5px', cursor: 'pointer' }} onClick={() => removeConnection(deviceId)}>Disconnect</button>
                            </>
                        ) : (
                            <>
                                <h2 style={{ color: '#333', marginBottom: '20px' }}>Connect to WhatsApp</h2>
                                {qrCode ? <img className="qr-code" style={{ width: '200px', height: '200px', marginTop: '20px' }} src={qrCode} alt="QR Code" /> : <button className="button" style={{ backgroundColor: '#007BFF', color: 'white', border: 'none', padding: '10px 20px', marginTop: '20px', borderRadius: '5px', cursor: 'pointer' }} onClick={generateQRCode}>Connect</button>}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhatsApp;
