import { useState } from "react";
import Flow from "./flow";

const TextBox = () => {
    const [prompt, setPrompt] = useState({
        contextRole: "",
        promptText: "",
        responseType: "",
        otherRules: "",
        data: "",
    });

    const [file, setFile] = useState(null);
    const [showFlow, setShowFlow] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPrompt((prevPrompt) => ({
            ...prevPrompt,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { contextRole, promptText, responseType } = prompt;

        if (!contextRole || !promptText || !responseType) {
            alert("Please fill in all required fields *");
            return;
        }
        console.log(prompt);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleChangeToFlow = () => {
        const hasInput = Object.values(prompt).some((value) => value !== "");
        if (hasInput || file) {
            alert("Please clear the inputs before changing to Diagram Flow.");
        } else {
            setShowFlow(true);
        }
    };

    if (showFlow) {
        return <Flow />;
    }

    return (
        <div className="container">
            <div className="text-panel">
                <label>Context/Role: *</label>
                <input
                    type="text"
                    name="contextRole"
                    value={prompt.contextRole}
                    onChange={handleChange}
                    className="flowinput"
                />
                <label>Prompt: *</label>
                <textarea
                    name="promptText"
                    value={prompt.promptText}
                    onChange={handleChange}
                    className="flowtextarea"
                ></textarea>
                <label>Response Type: *</label>
                <input
                    type="text"
                    name="responseType"
                    value={prompt.responseType}
                    onChange={handleChange}
                    className="flowinput"
                />
                <label>Other Rules:</label>
                <textarea
                    name="otherRules"
                    value={prompt.otherRules}
                    onChange={handleChange}
                    className="flowtextarea"
                ></textarea>
                <label>Data:</label>
                <textarea
                    name="data"
                    value={prompt.data}
                    onChange={handleChange}
                    className="flowtextarea"
                ></textarea>
                <label>Attach a File:</label>
                <input
                    type="file"
                    onChange={handleFileChange}
                    className="flowinput"
                />
            </div>
            <div className="buttons-panel">
                <button onClick={handleChangeToFlow}>Change to Diagram Flow</button>
                <button
                    onClick={() => {
                        setPrompt({
                            contextRole: "",
                            promptText: "",
                            responseType: "",
                            otherRules: "",
                            data: "",
                        });
                        setFile(null);
                    }}
                >
                    Clear
                </button>
                <button onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};

export default TextBox;
