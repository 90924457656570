import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { useAuth } from "../auth/authContext";
import { useNavigate } from "react-router-dom";
import SidebarMenu from "./sidebar";
import { Dialog } from 'primereact/dialog';
import { BASE_URL } from '../utils/config';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { Tooltip } from 'primereact/tooltip';

const Documents = () => {
    let navigate = useNavigate();
    const toast = React.useRef(null);
    const { userDetails } = useAuth();
    const [globalFilter, setGlobalFilter] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleUpdate, setvisibleUpdate] = useState(false);
    const [documentName, setDocumentName] = useState('');
    const [currentDocID, setCurrentDocID] = useState();
    const [documents, setDocuments] = useState([]);
    const rowsPerPage = 8;

    const userRepo = JSON.parse(localStorage.getItem('userRepo'));

    useEffect(() => {
        if (!userRepo) {
            navigate("/repos");
        }
    },[])

    let count = 0;
    useEffect(() => {
        if (count === 0 && userRepo) {
            count = count + 1;
            fetchDocuments();
        }
    }, []);

    const fetchDocuments = async () => {
        try {
            if (!userDetails) {
                console.error('User details not available');
                setDocuments([]);
                return;
            }

            if (!BASE_URL) {
                console.error('BASE_URL not defined in the environment');
                setDocuments([]);
                return;
            }

            const response = await fetch(`${BASE_URL}/team_details/get_documents_details_by_UserId?UserId=${userDetails.UserId}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json'
                },
            });

            if (!response.ok) {
                setDocuments([]);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Network response was not ok' });
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            //console.log(data);
            const filteredDocuments = data.filter(document => document.RepositoryId === userRepo.Id);
            setDocuments(filteredDocuments);
        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch documents' });
            setDocuments([]);
        }
    };

    const onGlobalFilterChange = (e) => {
        setGlobalFilter(e.target.value);
        setCurrentPage(1);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end align-items-center">
                <Button label="Upload" severity="primary" onClick={() => navigate('/document/upload')} tooltip="Upload new document" tooltipOptions={{ position: 'left' }} />
                <IconField iconPosition="right">
                    <InputIcon className="pi pi-search" />
                    <InputText
                        value={globalFilter}
                        onChange={onGlobalFilterChange}
                        placeholder="Search..."
                    />
                </IconField>
            </div>
        );
    };

    const handleUpdateDocument = async () => {
        return;
        try {
            if (documentName.trim() === "") {
                console.error(`Document name can't be empty`);
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: `Document name can't be empty` });
                return;
            }

            const updatedDocument = {
                DocumentName: documentName,
                RepositoryId: userRepo.Id,
                UserId: userDetails.UserId
            };

            const response = await fetch(`${BASE_URL}/update_document?Doc_Id=${currentDocID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify(updatedDocument)
            });

            if (!response.ok) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update document' });
                throw new Error('Failed to update document');
            }

            const data = await response.json();
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Document updated successfully' });
            fetchDocuments();
            setvisibleUpdate(false);
        } catch (error) {
            console.error('Error updating document:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
        }
    };

    const DeleteDocument = async (id) => {
        try {
            const response = await fetch(`${BASE_URL}/delete_document?document_id=${id}`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json'
                }
            });

            if (!response.ok) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete document' });
                throw new Error('Failed to delete document');
            }

            const data = await response.json();
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Document deleted successfully' });
            fetchDocuments();
        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
        }
    };

    const handleEdit = (rowData) => {
        setDocumentName(rowData.DocumentName);
        setvisibleUpdate(true);
        setCurrentDocID(rowData.Id);
    };

    const handleDelete = (rowData) => {
        console.log(rowData);
        setCurrentDocID(rowData.Id);
        confirm(rowData.Id);
    };

    const handleLinkDoc = async (data) => {
        try {

            //     toast.current.show({ severity: 'warn', summary: 'Warning', detail: `ALl the feils are required` });

            const url = `${BASE_URL}/call_apis_after_bot_configuration`;
            const params = new URLSearchParams({
                func: "CreateDoc",
                user_id: data.UserId,
                repository_id: data.RepositoryId,
                file_name: data.FileName
            });
            // const NewTeamMemberDetails = {
            //     UserId:""
            // };
            await fetch(`${url}?${params.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                // body: JSON.stringify(NewTeamMemberDetails)
            })
                .then(response => {
                    if (!response.ok) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed Link the document' });
                        throw new Error('Network response was not ok');

                    }
                    return response.json();
                })
                .then(data => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Docuent linked successfully' });
                    // Handle the API response if needed
                    console.log("data", data);
                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error:', error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to linked' });
                });
        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error });
        }
    };

    const handleUnLinkDoc = async (data) => {
        try {

            //toast.current.show({ severity: 'warn', summary: 'Warning', detail: `ALl the feils are required` });

            const url = `${BASE_URL}/call_apis_after_bot_configuration`;
            const params = new URLSearchParams({
                func: "DeleteDoc",
                user_id: data.UserId,
                repository_id: data.RepositoryId,
                file_name: data.FileName
            });
            // const NewTeamMemberDetails = {
            //     UserId:""
            // };
            await fetch(`${url}?${params.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                // body: JSON.stringify(NewTeamMemberDetails)
            })
                .then(response => {
                    if (!response.ok) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed un-Link the document' });
                        throw new Error('Network response was not ok');

                    }
                    return response.json();
                })
                .then(data => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Docuent un-linked successfully' });
                    // Handle the API response if needed
                    console.log("data", data);
                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error:', error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to un-linked' });
                });
        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error });
        }
    };

    const renderEditIcon = (rowData) => {
        return (
            <div className='flex gap-3'>
                <Tooltip target=".custom-target-icon" />
                {/* <i className="pi pi-pencil" onClick={() => handleEdit(rowData)} style={{ cursor: 'pointer' }}></i> */}
                <i className={`pi pi-link custom-target-icon ${rowData.ProcessingStatus === 'Complete' ? 'disabled-icon' : ''}`}
                    onClick={rowData.ProcessingStatus !== 'Complete' ? () => handleLinkDoc(rowData) : null}
                    style={{ cursor: rowData.ProcessingStatus === 'Complete' ? 'not-allowed' : 'pointer' }}
                    data-pr-tooltip={rowData.ProcessingStatus === 'Complete' ? 'Action disabled' : 'Link the document'}
                    data-pr-position="left"></i>

                <i className="pi pi-minus-circle custom-target-icon" onClick={() => handleUnLinkDoc(rowData)} style={{ cursor: 'pointer' }}
                    data-pr-tooltip="un-link the document"
                    data-pr-position="bottom"></i>
                <i className="pi pi-trash custom-target-icon" onClick={() => handleDelete(rowData)} style={{ cursor: 'pointer' }}
                    data-pr-tooltip="Delete document"
                    data-pr-position="right"></i>
            </div>
        );
    };

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const confirm = (id) => {
        confirmDialog({
            message: 'Are you sure you want to delete this document?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => DeleteDocument(id),
            reject
        });
    };

    return (
        <div>
            {userRepo && (
                <div className="app-container">
                    <Toast ref={toast} />
                    <SidebarMenu />
                    <div className="content">
                        <span>
                            <Button icon="pi pi-arrow-left" rounded outlined onClick={() => navigate('/repos')} severity="success" className="mb-2" />
                        </span>
                        <div className="flex justify-content-between align-items-center mb-4">
                            <h2>{userRepo.RepositoryName}</h2>
                            <div className="flex gap-3">
                                <Button icon="pi pi-refresh" onClick={fetchDocuments} />
                            </div>
                        </div>
                        <DataTable value={documents} paginator responsiveLayout="scroll"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="{first} to {last} of {totalRecords} entries"
                            rows={rowsPerPage} totalRecords={documents.length}
                            onPageChange={(e) => setCurrentPage(e.page + 1)}
                            first={(currentPage - 1) * rowsPerPage}
                            globalFilter={globalFilter}
                            header={renderHeader()}>
                            <Column field="FileName" header="Document Name" sortable ></Column>
                            <Column field="ProcessingStatus" header="Processing Status" sortable ></Column>
                            <Column header="Action" body={renderEditIcon}></Column>
                        </DataTable>
                    </div>

                    <Dialog header="Update Document" visible={visibleUpdate} style={{ width: '50vw' }} onHide={() => {
                        setvisibleUpdate(false);
                        setCurrentDocID('');
                    }}>
                        <div style={{ padding: "20px" }}>
                            <span className="p-float-label mb-5" style={{ width: '100%' }}>
                                <InputText id="documentName" style={{ width: '100%' }} value={documentName} onChange={(e) => setDocumentName(e.target.value)} />
                                <label htmlFor="documentName">Document Name</label>
                            </span>
                            <div className="flex justify-content-end mt-4">
                                <Button label="Cancel" className="p-button-text mr-2" onClick={() => {
                                    setvisibleUpdate(false);
                                    setCurrentDocID('');
                                }} />
                                <Button label="Update" onClick={handleUpdateDocument} />
                            </div>
                        </div>
                    </Dialog>
                </div>
            )}
        </div>
    );
}

export default Documents;
