import React, { useContext, useState, useEffect ,useRef} from "react";
import { auth } from "../firebase/firebase";
// import { GoogleAuthProvider } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [chatbot, setchatbot] = useState({});
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isEmailUser, setIsEmailUser] = useState(false);
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null); // Added state for userDetails
  const [userPackage, setUserPackage] = useState('Advanced');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return unsubscribe;
  }, []);

  const count = useRef(0);

  useEffect(() => {
      if (count.current === 0) {
          const chatbotGuid = JSON.parse(localStorage.getItem('chatbotGuid'))
          fetchBotDataById(chatbotGuid);
          count.current += 1;
      }
  }, []);

  async function initializeUser(user) {
    if (user) {

      setCurrentUser({ ...user });

      // check if provider is email and password login
      const isEmail = user.providerData.some(
        (provider) => provider.providerId === "password"
      );
      setIsEmailUser(isEmail);

      //console.log(user);
      setIsEmailVerified(user.emailVerified);
      setUserLoggedIn(true);
       // Fetch user details using the user's email
      await fetchUserDetails(user.email);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
    }

    setLoading(false);
  }

  async function fetchUserDetails(email) {
    try {
      const response = await fetch(`https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/get_user_by_email?email=${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
      });
      const data = await response.json();
      console.log(data);
      setUserDetails(data);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }

  const fetchBotDataById = async (id) => {
    try {
        await fetch(`https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/bot_studio/get_bot_configuration_details_by_Guid?Guid=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
              setchatbot(data[0]);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    } catch (error) {
        console.error('Error:', error);
    }
};

  const value = {
    userLoggedIn,
    isEmailUser,
    isGoogleUser,
    currentUser,
    setCurrentUser,
    isEmailVerified,
    userDetails, 
    setUserDetails,
    userPackage,
    setUserPackage,
    fetchUserDetails,
    chatbot,
    fetchBotDataById
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}