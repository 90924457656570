import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

const NodeTypeModal = ({ visible, onHide, onSelect }) => {
    return (
        <Dialog header="Select Node Type" visible={visible} onHide={onHide}>
            <div className="card flex flex-wrap justify-content-center gap-2">
                <Button label="Text Node" onClick={() => onSelect('textNode')} className="p-button-success" />
                <Button label="Action Node" onClick={() => onSelect('actionNode')} className="p-button-info" />
            </div>
        </Dialog>
    );
};

const ActionTypeModal = ({ visible, onHide, onSelect }) => {
    return (
        <Dialog header="Select Action Type" visible={visible} onHide={onHide}>
            <div className="card flex flex-wrap justify-content-center gap-2">
                <Button label="Email" onClick={() => onSelect('Email')} severity="success" outlined />
                <Button label="WhatsApp" onClick={() => onSelect('WhatsApp')} severity="success" outlined />
                <Button label="Phone" onClick={() => onSelect('Phone')} severity="success" outlined />
                <Button label="API" onClick={() => onSelect('API')} severity="success" outlined />
                <Button label="ITSM" onClick={() => onSelect('ITSM')} severity="success" outlined />
                <Button label="WooCommerce" onClick={() => onSelect('WooCommerce')} severity="success" outlined />
            </div>
            {/* Add more action types as needed */}
        </Dialog>
    );
};

export { NodeTypeModal, ActionTypeModal };
