import React, { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import SidebarMenu from "./sidebar";
import { useAuth } from "../auth/authContext";
import { BASE_URL } from "../utils/config";
import { confirmDialog } from 'primereact/confirmdialog';
import { useNavigate } from "react-router-dom";


const Integrations = () => {
    const toast = useRef(null);
    const [integrations, setIntegrations] = useState([]);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [updateDialogVisible, setUpdateDialogVisible] = useState(false);
    const [configDialogVisible, setConfigDialogVisible] = useState(false);
    const [whatsappConfigDialogVisible, setWhatsappConfigDialogVisible] = useState(false);
    const [integrationName, setIntegrationName] = useState('');
    const [selectedIntegrationType, setSelectedIntegrationType] = useState(null);
    const [emailConfigDetails, setEmailConfigDetails] = useState({ emailServiceProvider: '', emailid: '', password: '' });
    const [zapierConfigDetails, setZapierConfigDetails] = useState({ userid: '', password: '' });
    const [teamsConfigDetails, setTeamsConfigDetails] = useState({ teamsBotName: '' });
    const [phoneConfigDetails, setPhoneConfigDetails] = useState({ phoneNumber: '' });
    const [itsmConfigDetails, setItsmConfigDetails] = useState({ hostUrl: '', email: '', password: '' });
    const [instagramConfigDetails, setInstagramConfigDetails] = useState({ username: '', password: '' });
    const [facebookConfigDetails, setFacebookConfigDetails] = useState({ username: '', password: '' });
    const [sqlConfigDetails, setSqlConfigDetails] = useState({ DatabaseName: '', DatabaseType: '', Host: '', Password: '', Port: null, Username: '' });
    //const [apiConfigDetails, setApiConfigDetails] = useState({});
    const [apiConfigDetails, setApiConfigDetails] = useState({ api: '', functionName: '', functionDescription: '', properties: [] });
    const [property, setProperty] = useState({ propertyName: '', description: '' });
    const [slackConfigDetails, setSlackConfigDetails] = useState({ username: '', password: '' });
    const { userDetails } = useAuth();
    const [configDilogHovered, setConfigDilogHovered] = useState(null);
    const [updateFlag, setUpdateFlag] = useState(null);
    const navigate = useNavigate();

    const availableIntegrations = [
        { label: 'Whatsapp', value: 'Whatsapp' },
        { label: 'Email', value: 'Email' },
        // { label: 'Teams', value: 'Teams' },
        // { label: 'Zapier', value: 'Zapier' },
        { label: 'Phone', value: 'Phone' },
        { label: 'API', value: 'API' },
        { label: 'ITSM', value: 'ITSM' },
        { label: 'WooCommerce', value: 'WooCommerce' },
        { label: 'SMS', value: 'SMS' },
        { label: 'Facebook', value: 'Facebook' },
        { label: 'Instagram', value: 'Instagram' },
        { label: 'Slack', value: 'Slack' },
        { label: 'LinkedIn', value: 'LinkedIn' },
        { label: 'SQL', value: 'SQL' }
    ];

    const SQLDatabaseType = [
        { label: 'MySQL', value: 'mysql' },
        { label: 'PostgreSQL', value: 'postgresql' },
        { label: 'Oracle', value: 'oracle' },
        { label: 'SQLite', value: 'sqlite' },
        { label: 'SQL Server', value: 'sqlserver'}
    ];

    useEffect(() => {
        localStorage.removeItem('selectedIntegrationId');
        fetchIntegrations();
    }, []);

    const fetchIntegrations = async () => {
        try {
            if (!userDetails) {
                console.error('User details not available');
                setIntegrations([]);
                return;
            }

            if (!BASE_URL) {
                console.error('BASE_URL not defined in the environment');
                setIntegrations([]);
                return;
            }

            const response = await fetch(`${BASE_URL}/team_details/get_integrations_by_UserId?UserId=${userDetails.UserId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            });

            if (!response.ok) {
                setIntegrations([]);
                // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Network response was not ok' });
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setIntegrations(data);
        } catch (error) {
            console.error('Error:', error);
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch Integrations' });
        }
    };

    const addIntegration = async () => {
        if (integrationName && selectedIntegrationType) {

            const integrationNameExists = integrations.some(integration => integration.Name.toLowerCase() === integrationName.toLowerCase());
            if (integrationNameExists) {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Integration name already exists' });
                return;
            }


            try {
                const createResponse = await fetch(`${BASE_URL}/create_integrations`, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Name: integrationName,
                        Type: selectedIntegrationType,
                        UserId: userDetails.UserId
                    })
                });

                if (!createResponse.ok) {
                    throw new Error('Failed to create integration');
                }

                setIntegrationName('');
                setSelectedIntegrationType(null);
                setDialogVisible(false);
                fetchIntegrations();
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Integration saved successfully' });
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter a name and select an integration type' });
        }
    };

    const editIntegration = async (integration) => {
        setSelectedIntegration(integration);
        setIntegrationName(integration.Name);
        setSelectedIntegrationType(integration.Type);
        setUpdateDialogVisible(true);
    };

    const addUpdatedIntegration = async () => {
        if (selectedIntegration && integrationName && selectedIntegrationType) {
            try {
                const updateResponse = await fetch(`${BASE_URL}/update_integrations?integration_id=${selectedIntegration.Id}`, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Name: integrationName,
                        Type: selectedIntegrationType,
                        UserId: userDetails.UserId
                    })
                });

                if (!updateResponse.ok) {
                    throw new Error('Failed to update integration');
                }

                setSelectedIntegration(null);
                setIntegrationName('');
                setSelectedIntegrationType(null);
                setUpdateDialogVisible(false);
                fetchIntegrations();
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Integration updated successfully' });
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter a name and select an integration type' });
        }
    }

    const confirm = (integration) => {
        confirmDialog({
            message: 'Are you sure you want to delete this Integration?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => deleteIntegration(integration)
        });
    };

    const deleteIntegration = async (integration) => {
        try {
            const response = await fetch(`${BASE_URL}/delete_integrations?integration_id=${integration.Id}`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete integration');
            }

            fetchIntegrations();
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Integration deleted successfully' });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
        }
    };

    const fetchConfigDetails = async (integration) => {
        if (integration.Type == "Email") {
            //console.log(integration.Id);
            const response = await fetch(`${BASE_URL}/get_email_integration_details_by_integration_id?integration_id=${integration.Id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            });
            if (response.ok) {
                const responseData = await response.json();
                const data = responseData[0];
                if (data) {
                    setEmailConfigDetails({ emailServiceProvider: data.EmailServiceProvider, emailid: data.Email, password: data.EncryptedPassword });
                    setUpdateFlag(data.Id);
                }
            }
            return;
        } else if (integration.Type == "API") {
            //console.log(integration.Id);
            try {
                const response = await fetch(`${BASE_URL}/get_api_integration_by_integration_id?integration_id=${integration.Id}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch integration details');
                }

                const responseData = await response.json();

                const selectedData = responseData[0];

                if (selectedData) {

                    const data = selectedData.JsonData;

                    const { tools, api } = data;
                    const { function: { name, description, parameters } } = tools;
                    const { properties } = parameters;

                    const propertiesArray = Object.keys(properties).map(key => ({
                        propertyName: key,
                        description: properties[key].description
                    }));

                    setApiConfigDetails({
                        api,
                        functionName: name,
                        functionDescription: description,
                        properties: propertiesArray
                    });
                    setUpdateFlag(selectedData.Id);
                }
            } catch (error) {
                // toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
            }
            return;
        } else if (integration.Type == "ITSM") {
            //console.log(integration.Id);
            const response = await fetch(`${BASE_URL}/get_Itsm_integration_by_IntegrationId?IntegrationId=${integration.Id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            });
            if (response.ok) {
                const data = await response.json();
                setItsmConfigDetails({ hostUrl: data[0].HostURL, email: data[0].Email, password: data[0].EncryptedPassword });
                setUpdateFlag(data[0].Id);
            }
            return;
        } else if (integration.Type == 'Facebook' || integration.Type == 'Instagram' ) {
            const response = await fetch(`${BASE_URL}/get_${integration.Type}_integration_by_IntegrationId?IntegrationId=${integration.Id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            });
            if (response.ok) {
                const data = await response.json();
                if (integration.Type == 'Facebook') setFacebookConfigDetails({ username: data[0].UserName, password: data[0].EncryptedPassword });
                else if(integration.Type == 'Instagram') setInstagramConfigDetails({ username: data[0].UserName, password: data[0].EncryptedPassword });
                //else if(integration.Type == 'Slack') setSlackConfigDetails({ username: data[0].UserName, password: data[0].EncryptedPassword });
                setUpdateFlag(data[0].Id);
            }
            return;
        } else if (integration.Type == 'SQL'){
            const response = await fetch(`${BASE_URL}/get_database_credentials_by_IntegrationId?IntegrationId=${integration.Id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            });
            if (response.ok) {
                const data = await response.json();
                setSqlConfigDetails({ DatabaseName: data[0].DatabaseName, DatabaseType: data[0].DatabaseType, Host: data[0].Host, Password: data[0].Password, Port: data[0].Port, Username: data[0].Username });
                setUpdateFlag(data[0].Id);
            }
            return;
        }
    }

    const saveEmailIntegration = async () => {
        if (selectedIntegration && emailConfigDetails.emailServiceProvider && emailConfigDetails.emailid && emailConfigDetails.password) {
            try {
                const url = updateFlag
                ? `${BASE_URL}/update_email_integration?integration_id=${updateFlag}` 
                : `${BASE_URL}/create_email_integration`;

                const createResponse = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        Email: emailConfigDetails.emailid,
                        EmailServiceProvider: emailConfigDetails.emailServiceProvider,
                        EncryptedPassword: emailConfigDetails.password,
                        IntegrationId: selectedIntegration.Id,
                        UserId: userDetails.UserId
                    })
                });

                if (!createResponse.ok) {
                    throw new Error('Failed to create integration');
                }

                setEmailConfigDetails({ emailServiceProvider: '', emailid: '', password: '' })
                setSelectedIntegration(null);
                setDialogVisible(false);
                setUpdateFlag(null);
                fetchIntegrations();
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Email Configuration saved successfully' });
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter the required details.' });
        }
    }

    const constructJSON = (apiConfigDetails) => {
        const { api, functionName, functionDescription, properties } = apiConfigDetails;

        const propertiesObject = {};
        properties.forEach(prop => {
            propertiesObject[prop.propertyName] = {
                type: 'string',
                description: prop.description
            };
        });

        const requiredProperties = properties.map(prop => prop.propertyName);

        const result = {
            tools: {
                type: 'function',
                function: {
                    name: functionName,
                    description: functionDescription,
                    parameters: {
                        type: 'object',
                        properties: propertiesObject,
                        required: requiredProperties
                    }
                }
            },
            api
        };

        return result;
    };

    const saveApiIntegration = async () => {
        if (selectedIntegration && apiConfigDetails) {
            const jsonData = constructJSON(apiConfigDetails);
            console.log(jsonData);

            const url = updateFlag
                ? `${BASE_URL}/update_api_integration?api_integration_id=${updateFlag}` 
                : `${BASE_URL}/create_api_integration`;


            try {
                const createResponse = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        UserId: userDetails.UserId,
                        IntegrationId: selectedIntegration.Id,
                        JsonData: jsonData,
                    })
                });

                if (!createResponse.ok) {
                    throw new Error('Failed to create integration');
                }

                setApiConfigDetails({ api: '', funstionName: '', functionDescription: '', properties: [] })
                setSelectedIntegration(null);
                setDialogVisible(false);
                setUpdateFlag(null);
                fetchIntegrations();
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Api Configuration saved successfully' });
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter the required fields' });
        }

    }

    const saveItsmIntegration = async () => {
        if (selectedIntegration && itsmConfigDetails.hostUrl && itsmConfigDetails.email && itsmConfigDetails.password) {
            try {

                const url = updateFlag
                ? `${BASE_URL}/update_Itsm_integration?itsm_integration_id=${updateFlag}` 
                : `${BASE_URL}/create_Itsm_integration`;

            
                const createResponse = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        IntegrationId: selectedIntegration.Id,
                        UserId: userDetails.UserId,
                        Email: itsmConfigDetails.email,
                        EncryptedPassword: itsmConfigDetails.password,
                        HostURL: itsmConfigDetails.hostUrl,
                    })
                });

                if (!createResponse.ok) {
                    throw new Error('Failed to create integration');
                }

                setItsmConfigDetails({ hostUrl: '', email: '', password: '' })
                setSelectedIntegration(null);
                setDialogVisible(false);
                setUpdateFlag(null);
                fetchIntegrations();
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'ITSM Configuration saved successfully' });
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter the required details.' });
        }
    }

    const saveFacebookIntegration = async () => {
        if (selectedIntegration && facebookConfigDetails.username && facebookConfigDetails.password) {
            try {

                const url = updateFlag
                ? `${BASE_URL}/update_Facebook_integration?facebook_integration_id=${updateFlag}` 
                : `${BASE_URL}/create_Facebook_integration`;

                const createResponse = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        IntegrationId: selectedIntegration.Id,
                        UserId: userDetails.UserId,
                        UserName: facebookConfigDetails.username,
                        EncryptedPassword: facebookConfigDetails.password,
                    })
                });

                if (!createResponse.ok) {
                    throw new Error('Failed to create integration');
                }

                setFacebookConfigDetails({ username: '', password: '' })
                setSelectedIntegration(null);
                setDialogVisible(false);
                setUpdateFlag(null);
                fetchIntegrations();
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Facebook Configuration saved successfully' });
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter the required details.' });
        }
    }

    const saveInstagramIntegration = async () => {
        if (selectedIntegration && instagramConfigDetails.username && instagramConfigDetails.password) {
            try {

                const url = updateFlag
                ? `${BASE_URL}/update_Instagram_integration?instagram_integration_id=${updateFlag}` 
                : `${BASE_URL}/create_Instagram_integration`;

                const createResponse = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        IntegrationId: selectedIntegration.Id,
                        UserId: userDetails.UserId,
                        UserName: instagramConfigDetails.username,
                        EncryptedPassword: instagramConfigDetails.password,
                    })
                });

                if (!createResponse.ok) {
                    throw new Error('Failed to create integration');
                }

                setInstagramConfigDetails({ username: '', password: '' })
                setSelectedIntegration(null);
                setDialogVisible(false);
                setUpdateFlag(null);
                fetchIntegrations();
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Instagram Configuration saved successfully' });
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter the required details.' });
        }
    }

    const saveSQLIntegration = async () => {
        if (selectedIntegration && sqlConfigDetails.DatabaseName && sqlConfigDetails.DatabaseType) {
            try {

                const url = updateFlag
                ? `${BASE_URL}/update_database_credentials?database_credentials_id=${updateFlag}` 
                : `${BASE_URL}/create_database_credentials`;


                const createResponse = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        IntegrationId: selectedIntegration.Id,
                        UserId: userDetails.UserId,
                        DatabaseName: sqlConfigDetails.DatabaseName,
                        DatabaseType: sqlConfigDetails.DatabaseType,
                        Host: sqlConfigDetails.Host,
                        Password: sqlConfigDetails.Password,
                        Port: sqlConfigDetails.Port,
                        Username: sqlConfigDetails.Username
                    })
                });

                if (!createResponse.ok) {
                    throw new Error('Failed to create integration');
                }

                setSqlConfigDetails({ DatabaseName: '', DatabaseType: '', Host: '', Password: '', Port: null, Username: '' })
                setSelectedIntegration(null);
                setDialogVisible(false);
                setUpdateFlag(null);
                fetchIntegrations();
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'SQL Configuration saved successfully' });
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter the required details.' });
        }

    }

    const saveSlackIntegration = async () =>{
        if (selectedIntegration && slackConfigDetails.username && slackConfigDetails.password) {
            try {

                const url = updateFlag
                ? `${BASE_URL}/update_Slack_integration?slack_integration_id=${updateFlag}` 
                : `${BASE_URL}/create_Slack_integration`;

                const createResponse = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        IntegrationId: selectedIntegration.Id,
                        UserId: userDetails.UserId,
                        UserName: slackConfigDetails.username,
                        EncryptedPassword: slackConfigDetails.password,
                    })
                });

                if (!createResponse.ok) {
                    throw new Error('Failed to create integration');
                }

                setSlackConfigDetails({ username: '', password: '' })
                setSelectedIntegration(null);
                setDialogVisible(false);
                setUpdateFlag(null);
                fetchIntegrations();
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Slack Configuration saved successfully' });
            } catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please enter the required details.' });
        }
    }

    const saveConfig = () => {
        if (selectedIntegration && selectedIntegration.Type == 'Email') {
            saveEmailIntegration();
        } else if (selectedIntegration && selectedIntegration.Type == 'API') {
            saveApiIntegration();
        } else if (selectedIntegration && selectedIntegration.Type == 'ITSM') {
            saveItsmIntegration();
        } else if (selectedIntegration && selectedIntegration.Type == 'Instagram') {
            saveInstagramIntegration();
        } else if (selectedIntegration && selectedIntegration.Type == 'Facebook') {
            saveFacebookIntegration();
        } else if (selectedIntegration && selectedIntegration.Type == 'SQL') {
            saveSQLIntegration();
        } 
        // else if(selectedIntegration && selectedIntegration.Type == 'Slack'){
        //     saveSlackIntegration();
        // }

        setSelectedIntegration(null);
        setConfigDialogVisible(false);
        setWhatsappConfigDialogVisible(false);
    };

    const openConfigDialog = async (integration) => {
        // console.log(integration);
        setSelectedIntegration(integration);
        await fetchConfigDetails(integration);

        if (integration.Type === 'Whatsapp') {
            localStorage.setItem('selectedIntegrationId', integration.Id);
            navigate('/integration/whatsapp');
            //setWhatsappConfigDialogVisible(true);
        } else {
            setConfigDialogVisible(true);
        }
    };

    const addProperty = () => {
        setApiConfigDetails({
            ...apiConfigDetails,
            properties: [...apiConfigDetails.properties, property]
        });
        setProperty({ propertyName: '', description: '' });
    };

    const resetConfigDetails = () => {
        setEmailConfigDetails({ emailServiceProvider: '', emailid: '', password: '' });
        setZapierConfigDetails({ userid: '', password: '' });
        setTeamsConfigDetails({ teamsBotName: '' });
        setPhoneConfigDetails({ phoneNumber: '' });
        setItsmConfigDetails({ hostUrl: '', email: '', password: '' });
        setApiConfigDetails({ api: '', functionName: '', functionDescription: '', properties: [] });
        setFacebookConfigDetails({ username: '', password: '' });
        setInstagramConfigDetails({ username: '', password: '' });
        setSqlConfigDetails({ DatabaseName: '', DatabaseType: '', Host: '', Password: '', Port: null, Username: '' });
        setSlackConfigDetails({ username: '', password: '' });
        setUpdateFlag(null);
    }

    return (
        <div className="app-container" style={{ fontFamily: 'Arial, sans-serif', color: '#333' }}>
            <Toast ref={toast} />
            <SidebarMenu />
            <div className="content" style={{ padding: '20px', backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
                <Button
                    label="Add Integration"
                    icon="pi pi-plus"
                    onClick={() => setDialogVisible(true)}
                    style={{ marginBottom: '20px', backgroundColor: '#007bff', borderColor: '#007bff', color: '#fff' }}
                />
                <Dialog
                    header="Add Integration"
                    visible={dialogVisible}
                    style={{ width: '50vw' }}
                    footer={
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button label="Cancel" icon="pi pi-times" onClick={() => { setDialogVisible(false); setIntegrationName(''); setSelectedIntegrationType(null); }} className="p-button-text" />
                            <Button label="Add" icon="pi pi-check" onClick={addIntegration} autoFocus />
                        </div>
                    }
                    onHide={() => { setDialogVisible(false); setIntegrationName(''); setSelectedIntegrationType(null); }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <label>Enter Integration Name</label>
                        <InputText
                            value={integrationName}
                            onChange={(e) => setIntegrationName(e.target.value)}
                            style={{ width: '100%' }}
                        />
                        <label>Select Integration Type</label>
                        <Dropdown
                            value={selectedIntegrationType}
                            options={availableIntegrations}
                            onChange={(e) => setSelectedIntegrationType(e.value)}
                            style={{ width: '100%' }}
                        />
                    </div>
                </Dialog>
                <Dialog
                    header="Update Integration"
                    visible={updateDialogVisible}
                    style={{ width: '50vw' }}
                    footer={
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button label="Cancel" icon="pi pi-times" onClick={() => { setUpdateDialogVisible(false); setIntegrationName(''); setSelectedIntegrationType(null); }} className="p-button-text" />
                            <Button label="Update" icon="pi pi-check" onClick={addUpdatedIntegration} autoFocus />
                        </div>
                    }
                    onHide={() => { setUpdateDialogVisible(false); setIntegrationName(''); setSelectedIntegrationType(null); }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <label>Enter Integration Name</label>
                        <InputText
                            value={integrationName}
                            onChange={(e) => setIntegrationName(e.target.value)}
                            style={{ width: '100%' }}
                        />
                        <label>Select Integration Type</label>
                        <Dropdown
                            value={selectedIntegrationType}
                            options={availableIntegrations}
                            onChange={(e) => setSelectedIntegrationType(e.value)}
                            style={{ width: '100%' }}
                        />
                    </div>
                </Dialog>
                <Dialog
                    header={`Configure ${selectedIntegration && selectedIntegration.Type} Integration`}
                    visible={configDialogVisible}
                    style={{ width: '50vw' }}
                    footer={
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button label="Cancel" icon="pi pi-times" onClick={() => { setSelectedIntegration(null); setConfigDialogVisible(false); resetConfigDetails(); }} className="p-button-text" />
                            <Button label={updateFlag ? 'Update' : 'Save'} icon="pi pi-check" onClick={saveConfig} autoFocus />
                        </div>
                    } vc
                    onHide={() => { setSelectedIntegration(null); setConfigDialogVisible(false); resetConfigDetails(); }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        {selectedIntegration && selectedIntegration.Type === 'Teams' && (
                            <>
                                <label>Teams Bot Name</label>
                                <InputText
                                    value={teamsConfigDetails.teamsBotName}
                                    onChange={(e) => setTeamsConfigDetails({ ...teamsConfigDetails, teamsBotName: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                            </>
                        )}

                        {selectedIntegration && selectedIntegration.Type === 'Email' && (
                            <>
                                <label>Email Service Provider</label>
                                <InputText
                                    value={emailConfigDetails.emailServiceProvider}
                                    onChange={(e) => setEmailConfigDetails({ ...emailConfigDetails, emailServiceProvider: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                                <label>Email ID</label>
                                <InputText
                                    value={emailConfigDetails.emailid}
                                    onChange={(e) => setEmailConfigDetails({ ...emailConfigDetails, emailid: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                                <label>Email Password</label>
                                <InputText
                                    value={emailConfigDetails.password}
                                    onChange={(e) => setEmailConfigDetails({ ...emailConfigDetails, password: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                            </>
                        )}

                        {selectedIntegration && selectedIntegration.Type === 'Zapier' && (
                            <>
                                <label>Zapier User ID</label>
                                <InputText
                                    value={zapierConfigDetails.userid}
                                    onChange={(e) => setZapierConfigDetails({ ...zapierConfigDetails, userid: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                                <label>Zapier Password</label>
                                <InputText
                                    value={zapierConfigDetails.password}
                                    onChange={(e) => setZapierConfigDetails({ ...zapierConfigDetails, password: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                            </>
                        )}

                        {selectedIntegration && selectedIntegration.Type === 'Phone' && (
                            <>
                                <label>Phone Number</label>
                                <InputText
                                    value={phoneConfigDetails.phoneNumber}
                                    onChange={(e) => setPhoneConfigDetails({ ...phoneConfigDetails, phoneNumber: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                            </>
                        )}

                        {selectedIntegration && selectedIntegration.Type === 'API' && (
                            <>
                                <label>API</label>
                                <InputText
                                    value={apiConfigDetails.api}
                                    onChange={(e) => setApiConfigDetails({
                                        ...apiConfigDetails,
                                        api: e.target.value
                                    })}
                                    style={{ width: '100%' }}
                                />
                                <label>Function Name</label>
                                <InputText
                                    value={apiConfigDetails.functionName}
                                    onChange={(e) => setApiConfigDetails({
                                        ...apiConfigDetails,
                                        functionName: e.target.value
                                    })}
                                    style={{ width: '100%' }}
                                />
                                <label>Function Description</label>
                                <InputText
                                    value={apiConfigDetails.functionDescription}
                                    onChange={(e) => setApiConfigDetails({
                                        ...apiConfigDetails,
                                        functionDescription: e.target.value
                                    })}
                                    style={{ width: '100%' }}
                                />
                                <label>Schema</label>
                                <div>
                                {apiConfigDetails.properties.map((prop, index) => (
                                    <div key={index} style={{ display: 'flex', gap: '1rem' }}>
                                        <InputText
                                            value={prop.propertyName}
                                            onChange={(e) => {
                                                const newProperties = [...apiConfigDetails.properties];
                                                newProperties[index].propertyName = e.target.value;
                                                setApiConfigDetails({
                                                    ...apiConfigDetails,
                                                    properties: newProperties
                                                });
                                            }}
                                            placeholder="Property Name"
                                            style={{ width: '100%' }}
                                        />
                                        <InputText
                                            value={prop.description}
                                            onChange={(e) => {
                                                const newProperties = [...apiConfigDetails.properties];
                                                newProperties[index].description = e.target.value;
                                                setApiConfigDetails({
                                                    ...apiConfigDetails,
                                                    properties: newProperties
                                                });
                                            }}
                                            placeholder="Property Description"
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                ))}
                                </div>
                                <div style={{ display: 'flex', gap: '1rem' }}>
                                    <InputText
                                        value={property.propertyName}
                                        onChange={(e) => setProperty({ ...property, propertyName: e.target.value })}
                                        placeholder="Property Name"
                                        style={{ width: '50%' }}
                                    />
                                    <InputText
                                        value={property.description}
                                        onChange={(e) => setProperty({ ...property, description: e.target.value })}
                                        placeholder="Property Description"
                                        style={{ width: '50%' }}
                                    />
                                    <Button label="Add Property" icon="pi pi-plus" onClick={addProperty} />
                                </div>
                            </>
                        )}

                        {selectedIntegration && selectedIntegration.Type === 'ITSM' && (
                            <>
                                <label>Host Url</label>
                                <InputText
                                    value={itsmConfigDetails.hostUrl}
                                    onChange={(e) => setItsmConfigDetails({ ...itsmConfigDetails, hostUrl: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                                <label>Email</label>
                                <InputText
                                    value={itsmConfigDetails.email}
                                    onChange={(e) => setItsmConfigDetails({ ...itsmConfigDetails, email: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                                <label>Password</label>
                                <InputText
                                    value={itsmConfigDetails.password}
                                    onChange={(e) => setItsmConfigDetails({ ...itsmConfigDetails, password: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                            </>
                        )}

                        {selectedIntegration && selectedIntegration.Type === 'Instagram' && (
                            <>
                                <label>Username</label>
                                <InputText
                                    value={instagramConfigDetails.username}
                                    onChange={(e) => setInstagramConfigDetails({ ...instagramConfigDetails, username: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                                <label>Password</label>
                                <InputText
                                    value={instagramConfigDetails.password}
                                    onChange={(e) => setInstagramConfigDetails({ ...instagramConfigDetails, password: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                            </>
                        )}

                        {selectedIntegration && selectedIntegration.Type === 'Facebook' && (
                            <>
                                <label>Username</label>
                                <InputText
                                    value={facebookConfigDetails.username}
                                    onChange={(e) => setFacebookConfigDetails({ ...facebookConfigDetails, username: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                                <label>Password</label>
                                <InputText
                                    value={facebookConfigDetails.password}
                                    onChange={(e) => setFacebookConfigDetails({ ...facebookConfigDetails, password: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                            </>
                        )}

                        {selectedIntegration && selectedIntegration.Type === 'SQL' && (
                            <>
                                <label>Database Name</label>
                                <InputText
                                    value={sqlConfigDetails.DatabaseName}
                                    onChange={(e) => setSqlConfigDetails({ ...sqlConfigDetails, DatabaseName: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                                <label>Database Type</label>
                                {/* <InputText
                                    value={sqlConfigDetails.DatabaseType}
                                    onChange={(e) => setSqlConfigDetails({ ...sqlConfigDetails, DatabaseType: e.target.value })}
                                    style={{ width: '100%' }}
                                /> */}
                                <Dropdown
                                    value={sqlConfigDetails.DatabaseType}
                                    options={SQLDatabaseType}
                                    onChange={(e) => setSqlConfigDetails({ ...sqlConfigDetails, DatabaseType: e.value })}
                                    style={{ width: '100%' }}
                                />

                                <label>Host</label>
                                <InputText
                                    value={sqlConfigDetails.Host}
                                    onChange={(e) => setSqlConfigDetails({ ...sqlConfigDetails, Host: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                                <label>Port</label>
                                <InputText
                                    value={sqlConfigDetails.Port}
                                    onChange={(e) => setSqlConfigDetails({ ...sqlConfigDetails, Port: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                                <label>Username</label>
                                <InputText
                                    value={sqlConfigDetails.Username}
                                    onChange={(e) => setSqlConfigDetails({ ...sqlConfigDetails, Username: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                                <label>Password</label>
                                <InputText
                                    value={sqlConfigDetails.Password}
                                    onChange={(e) => setSqlConfigDetails({ ...sqlConfigDetails, Password: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                            </>
                        )}

                        {/* {selectedIntegration && selectedIntegration.Type === 'Slack' && (
                            <>
                                <label>Username</label>
                                <InputText
                                    value={slackConfigDetails.username}
                                    onChange={(e) => setSlackConfigDetails({ ...slackConfigDetails, username: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                                <label>Password</label>
                                <InputText
                                    value={slackConfigDetails.password}
                                    onChange={(e) => setSlackConfigDetails({ ...slackConfigDetails, password: e.target.value })}
                                    style={{ width: '100%' }}
                                />
                            </>
                        )} */}
                    </div>
                </Dialog>
                <Dialog
                    header="Configure Whatsapp Integration"
                    visible={whatsappConfigDialogVisible}
                    style={{ width: '50vw' }}
                    footer={
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button label="Cancel" icon="pi pi-times" onClick={() => setWhatsappConfigDialogVisible(false)} className="p-button-text" />
                            <Button label="Save" icon="pi pi-check" onClick={saveConfig} autoFocus />
                        </div>
                    }
                    onHide={() => setWhatsappConfigDialogVisible(false)}
                >
                    {/* <p>WhatsApp configuration details go here.</p> */}
                </Dialog>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                        gap: '16px',
                        width: '80%',
                        margin: 'auto'
                    }}>
                        {integrations.map((integration, index) => (
                            <Card
                                key={index}
                                title={`${integration.Name}`}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '16px',
                                    cursor: 'pointer',
                                    border: '1px solid #ccc',
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    position: 'relative',
                                    backgroundColor: '#fff',
                                    transition: 'transform 0.2s ease-in-out'
                                }}
                                onMouseEnter={(e) => { e.currentTarget.style.transform = 'scale(1.05)'; }}
                                onMouseLeave={(e) => { e.currentTarget.style.transform = 'scale(1)'; }}
                            >
                                <Button
                                    icon="pi pi-pencil"
                                    style={{
                                        position: 'absolute',
                                        top: '4px',
                                        right: '32px',
                                        backgroundColor: 'transparent',
                                        borderColor: 'transparent',
                                        color: '#000',
                                        outline: 'none',
                                        boxShadow: 'none'
                                    }}
                                    onClick={() => editIntegration(integration)}
                                />
                                <Button
                                    icon="pi pi-trash"
                                    style={{
                                        backgroundColor: 'transparent',
                                        borderColor: 'transparent',
                                        position: 'absolute',
                                        top: '4px',
                                        right: '4px',
                                        color: '#000',
                                        outline: 'none',
                                        boxShadow: 'none'
                                    }}
                                    onClick={() => confirm(integration)}
                                />
                                <p onClick={() => openConfigDialog(integration)}
                                    onMouseEnter={() => setConfigDilogHovered(index)}
                                    onMouseLeave={() => setConfigDilogHovered(null)}
                                    style={{ color: (configDilogHovered === index) ? '#007bff' : 'inherit', cursor: 'pointer' }}
                                >Configure your {integration.Type} integration</p>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Integrations;
