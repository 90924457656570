import React, { useState, useEffect } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import { useAuth } from "../auth/authContext";
import { doSignOut } from "../auth/firebase/auth";
import "../Style/pagenotfound.css";
// import Spinner from "../pages/Spinner";


const NotFound = () => {
  const [loginStatus, setloginStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { userLoggedIn } = useAuth();
  console.log("userLoggedIn=============",userLoggedIn)

  useEffect(() => {
    if (userLoggedIn) {
      setloginStatus(true);
    }
    setLoading(false);
  },[userLoggedIn, navigate]);


  if (loading) {
    // You can render a loading spinner or message while checking the login status.
    return null;
  }

  if (!loginStatus) {
    return (
      <div className="not-found-container">
        <div className="not-found-content">
          <h1 className="heading">404</h1>
          <p className="paragrapgh">Oops! Page not found</p>
          <FaExclamationTriangle className="icon" />
          <br />
          <Link to="/">
            <button className="attractive-button">BACK TO LOGIN</button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="heading">404</h1>
        <p className="paragrapgh">Oops! Page not found</p>
        <FaExclamationTriangle className="icon" />
        <br />
        <Link to="/home" replace>
          <button className="attractive-button">BACK TO HOMEPAGE </button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
