import './App.css';
import { BrowserRouter, Route, Routes, Navigate, useHistory } from "react-router-dom";
import Protected from "./Pages/protected";
import NotFound from "./Pages/pagenotfound";
import Login from './Pages/login';
import Signup from './Pages/signup';
import FAQS from './Pages/FAQS';
import Home from './Pages/Home';
import DocumentUpload from './Pages/documentUpload';
import Document from './Pages/document';
import Team from './Pages/team';
import ChatBot from "./Pages/avatar";
import BotStudio from "./Pages/chatbotStudio";
import Repos from './Pages/repos';
import WebLinks from './Pages/webLinks';
import { useAuth } from "./auth/authContext";
import PhoneVerificationPage from "./Pages/phoneNumberVerification";
import VerifyEmailPage from "./Pages/verifyEmail";
import Chatbots from "./Pages/chatbots";
import Reports from "./Pages/report";
import Flow from "./Pages/flow";
import Subscriptions from "./Pages/subscription";
import Integrations from './Pages/integrations';
import WhatsApp from './Pages/whatsapp';


function App() {
  const { userLoggedIn, isEmailVerified, isEmailUser  } = useAuth();
  // console.log("================",userLoggedIn, isEmailVerified, isEmailUser )
  return (
    <>
    <BrowserRouter>
      <Routes>
      <Route path="/home" element={
          userLoggedIn ? (
            isEmailUser ? (
              isEmailVerified ? <Protected Component={Home} /> : <VerifyEmailPage />
            ) : <PhoneVerificationPage />
          ) : <Home />
        } />
        <Route path="/login" element={<Login />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/" element={<Home/>} />
        <Route path="/faqs" element={<Protected Component={FAQS} />} />
        <Route path="/documents" element={<Protected Component={Document} />} />
        <Route path="/document/upload" element={<Protected Component={DocumentUpload} />}/>
        <Route path="/team" element={<Protected Component={Team} />} />
        <Route path="/chatbot" element={<Protected Component={ChatBot} />} />
        <Route path="/botstudio" element={<Protected Component={BotStudio} />} />
        <Route path="/chatbots" element={<Protected Component={Chatbots} />} />
        <Route path="/repos" element={<Protected Component={Repos} />}/>
        <Route path="/weblinks" element={<Protected Component={WebLinks} />}/>
        <Route path="/reports" element={<Protected Component={Reports} />}/>
        <Route path="/subscription" element={<Subscriptions/>}/>
        <Route path="/flow" element={<Protected Component={Flow} />}/>
        <Route path="/integrations" element={<Protected Component={Integrations} />}/>
        <Route path="/integration/whatsapp" element={<Protected Component={WhatsApp} />}/>
        {/* <Route path="/home" element={<Protected Component={Home} />} />
        <Route path="/faqs" element={<Protected Component={FAQS} />} />
        <Route path="/documents" element={<Protected Component={Document} />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </>
  );
}

export default App;
