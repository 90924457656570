import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { useAuth } from "../auth/authContext";
import { FloatLabel } from "primereact/floatlabel";
import { InputTextarea } from 'primereact/inputtextarea';
import { doSignOut } from "../auth/firebase/auth";
import { useNavigate } from "react-router-dom";
import SidebarMenu from "./sidebar";
import { Dialog } from 'primereact/dialog';
import { BASE_URL } from '../utils/config'
import { Toast } from 'primereact/toast';

import { confirmDialog } from 'primereact/confirmdialog';

const Team = () => {
    let navigate = useNavigate();
    const toast = React.useRef(null);
    const { currentUser, userDetails, userPackage } = useAuth();
    const [globalFilter, setGlobalFilter] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleAdd, setvisibleAdd] = useState(false);
    const [visibleUpdate, setvisibleUpdate] = useState(false);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });
    const [errors, setErrors] = useState({});
    const [CurrentTeamMemberID, setCurrentTeamMemberID] = useState();
    const [TeamMembers, setTeamMembers] = useState([]);
    const rowsPerPage = 8;

    let count = 0;
    useEffect(() => {
        // Fetch TeamMembers from the API
        if (count === 0) {
            count = count + 1
            fetchTeamMembers();
        }
    }, []); // Empty dependency array ensures this effect runs only once after initial render

    const fetchTeamMembers = async () => {
        try {

            if (!userDetails) {
                console.error('User details not available');
                return;
            }

            if (!BASE_URL) {
                console.error('BASE_URL not defined in the environment');
                return;
            }

            await fetch(`${BASE_URL}/get_team_details_by_UserId?UserId=${userDetails.UserId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            })
                .then(response => {
                    if (!response.ok) {
                        setTeamMembers([])
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Network response was not ok' });
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log("Team member DATA===============>", data)
                    setTeamMembers(data)
                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error:', error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch TeamMembers' });
                });

        } catch (error) {
            console.log("error", error)
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'error' });
            setTeamMembers([])
        }

    };

    const onGlobalFilterChange = (e) => {
        setGlobalFilter(e.target.value);
        setCurrentPage(1);  // Reset to first page when filter changes
    };

    const handleAddClick = () =>{
         
        const teamSize = TeamMembers.length;
        console.log(teamSize);

        if (userPackage === 'Free') { 
            setShowUpgradeDialog(true);
            return;
        } else if (userPackage === 'Basic' && teamSize >= 5) {
            setShowUpgradeDialog(true);
            return;
        } else if (userPackage === 'Advanced') {
            // No restriction for Advanced package
        }

        setvisibleAdd(true)
        setForm({
            firstName: '',
            lastName: '',
            email: '',
        })


    }
    
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end align-items-center ">
                <Button label="Add" outlined severity="success" style={{ marginRight: "5px" }} onClick={handleAddClick} 
                tooltip="Add new team member" tooltipOptions={{ position: 'top' }}
                />
                <IconField iconPosition="right">
                    <InputIcon className="pi pi-search" />
                    <InputText
                        value={globalFilter}
                        onChange={onGlobalFilterChange}
                        placeholder="Search..."
                    />
                </IconField>
            </div>
        );
    };

    const validateForm = () => {
        let hasErrors = false;
        let newErrors = {};

        // Simple validation rules
        if (!form.firstName || form.firstName.trim() == "") {
            hasErrors = true;
            newErrors.firstName = 'First name is required.';
        }
        // Simple validation rules
        if (!form.lastName || form.lastName.trim() == "") {
            hasErrors = true;
            newErrors.lastName = 'Last name is required.';
        }
        if (!form.email || form.email.trim() == "") {
            hasErrors = true;
            newErrors.email = 'Email is required.';
        }
        setErrors(newErrors);
        return !hasErrors;
    };

    const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

    const CreateTeamMember = async () => {

        try {
            if (validateForm()) {
                const newFAQ = {
                    Email: form.email,
                    FirstName:form.firstName ,
                    LastName: form.lastName,
                    UserId: userDetails.UserId
                };
                // Send POST request to create a new FAQ
                await fetch(`${BASE_URL}/create_team_details`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': 'application/json'
                    },
                    body: JSON.stringify(newFAQ)
                })
                    .then(response => {
                        if (!response.ok) {
                            console.error('Failed to create Team Detail');
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to create Team Detail' });
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        console.log(data);
                        if(data.detail){
                            const errorDetail = data.detail;
                            if (errorDetail.includes("User is not registered")) {
                                toast.current.show({ severity: 'error', summary: 'Registration Error', detail: 'The user is not registered. Please ensure the email address is correct and try again.' });
                            } else if (errorDetail.includes("Can Not add yourself in the team")) {
                                toast.current.show({ severity: 'error', summary: 'Add Error', detail: 'You cannot add yourself to the team. Please select a different user.' });
                            } else if (errorDetail.includes("already exist in your team")) {
                                toast.current.show({ severity: 'error', summary: 'Duplicate User', detail: `The user is already a member of the team. Please add a different user.` });
                            } 
                        }else{
                            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Team created successfully' });
                        }
                        
                        // FAQ created successfully, fetch TeamMembers again to update the list
                        fetchTeamMembers();
                        setvisibleAdd(false); // Close the dialog after creating the FAQ
                    })
                    .catch(error => {
                        // Handle errors here
                        console.error('Error:', error);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to create Team Detail' });
                    });
            } else {
                console.error(`Question and Answer Can't be empty`);
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: `All the feilds are required` });
                return;
            }

        } catch (error) {
            console.error('Error creating team:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error });
        }
    };

    const handleUpdateTeamMember = async () => {
        try {

            if (!validateForm()) {
                console.error(`ALl the feils are required.`);
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: `ALl the feils are required` });
                return;
            }

            const url = `${BASE_URL}/update_team_details`;
            const params = new URLSearchParams({
                team_details_id:CurrentTeamMemberID,
            });
            const NewTeamMemberDetails = {
                //Email: form.email,
                FirstName: form.firstName,
                LastName: form.lastName,
                //UserId:userDetails.UserId
            };
            await fetch(`${url}?${params.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify(NewTeamMemberDetails)
            })
                .then(response => {
                    if (!response.ok) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Update Team member Details' });
                        throw new Error('Network response was not ok');

                    }
                    return response.json();
                })
                .then(data => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Team member details updated successfully' });
                    // Handle the API response if needed
                    console.log("data", data);
                    // Reset the input fields
                    setQuestion('');
                    setAnswer('');
                    // Close the dialog
                    fetchTeamMembers();
                    setvisibleUpdate(false);

                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error:', error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Update Team member Details' });
                });
        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error });
        }
    };

    const DeleteTeamMemeber = async (id) => {
        try {

            const url = `${BASE_URL}/delete_team_details?team_details_id=${id}`;
            await fetch(url, {
                method: 'POST',
                headers: {
                    'accept': 'application/json'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete team member' });
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    // Handle the API response if needed
                    console.log("============================>>>>>>>>>>>>>>>>>>>>>>", data);
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Team member deleted successfully' });
                    fetchTeamMembers();
                    // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                })
                .catch(error => {
                    // Handle errors here
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete team member' });
                    console.error('Error:', error);
                });
        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error });
        }
    }

    const handleEdit = (rowData) => {
        console.log("========rowData", rowData)
        setForm({
            firstName: rowData.FirstName,
            lastName: rowData.LastName,
            email: rowData.Email,
        });
        setvisibleUpdate(true);
        setCurrentTeamMemberID(rowData.Id)
    }

    const handleDelete = (rowData) => {
        console.log("========rowData", rowData)
        setCurrentTeamMemberID(rowData.Id)
        confirm(rowData.Id);
    }

    const renderEditIcon = (rowData) => {
        return <div className='flex gap-3'>
            <i className="pi pi-pencil" onClick={() => handleEdit(rowData)} style={{ cursor: 'pointer' }}></i>
            <i className="pi pi-trash" onClick={() => handleDelete(rowData)} style={{ cursor: 'pointer' }}></i>
        </div>;
    };

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const confirm = (id) => {
        confirmDialog({
            message: 'Are you sure you want to delete this team member ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => DeleteTeamMemeber(id),
            reject
        });            
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(prev => ({ ...prev, [name]: value }));
    };


    return (
        <div className="app-container">
            <Toast ref={toast} />
            <SidebarMenu />
            <div className="content">
                <DataTable value={TeamMembers} paginator responsiveLayout="scroll"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords} entries"
                    rows={rowsPerPage} totalRecords={TeamMembers.length}
                    onPageChange={(e) => setCurrentPage(e.page + 1)}
                    first={(currentPage - 1) * rowsPerPage}
                    globalFilter={globalFilter}
                    header={renderHeader()}>
                    <Column field="FirstName" header="First Name" sortable ></Column>
                    <Column field="LastName" header="Last Name" sortable ></Column>
                    <Column field="Email" header="Email" sortable ></Column>
                    <Column header="Action" body={renderEditIcon}></Column>

                </DataTable>
            </div>

            <Dialog header="Upgrade Required" visible={showUpgradeDialog} style={{ width: '50vw' }} modal onHide={() => setShowUpgradeDialog(false)}>
                <div className="p-grid">
                    <div className="p-col-12">
                        <p>Please upgrade your plan to add more team members.</p>
                    </div>
                    <div className="p-col-12">
                        <button onClick={()=>{ navigate('/subscription')}} className="p-button p-component">
                            Upgrade
                        </button>
                    </div>
                </div>
            </Dialog>

            <Dialog header="Add team member" visible={visibleAdd}
                onHide={() => {
                    setvisibleAdd(false)
                    setErrors({})
                }}
                style={{ width: '50vw' }}>
                <div style={{ padding: "20px", display: 'flex', flexDirection: 'column', gap: '2rem' }}>

                    <span><FloatLabel >
                        <InputText invalid={errors.firstName ? true : false} name="firstName" value={form.firstName} onChange={handleChange} style={{ width: "100%" }} />
                        <label for="firstname">FirstName</label>
                    </FloatLabel>
                    {errors.firstName && <small className="p-error">{errors.firstName}</small>}
                    </span>
                    <span>
                    <FloatLabel>
                        <InputText invalid={errors.lastName ? true : false} name="lastName" value={form.lastName} onChange={handleChange} style={{ width: "100%" }} />
                        <label for="lastname">LastName</label>
                    </FloatLabel>
                    {errors.lastName && <small className="p-error">{errors.lastName}</small>}
                    </span>
                    <span>
                    <FloatLabel>
                        <InputText invalid={errors.email ? true : false} name="email" value={form.email} onChange={handleChange} style={{ width: "100%" }} />
                        <label for="email">Email</label>
                    </FloatLabel>
                    {errors.email && <small className="p-error">{errors.email}</small>}
                    </span>

                    <div className="flex justify-content-end mt-4">
                        <Button label="Cancel" className="p-button-text mr-2" onClick={() => {
                            setvisibleAdd(false)
                            setErrors({})
                        }} />
                        <Button label="Submit" onClick={CreateTeamMember} />
                    </div>
                </div>
            </Dialog>


            <Dialog header="Update team member" visible={visibleUpdate} style={{ width: '50vw' }} onHide={() => {
                setvisibleUpdate(false)
                setCurrentTeamMemberID('')
                setErrors({})
            }}>
                <div style={{ padding: "20px", display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    <FloatLabel>
                        <InputText invalid={errors.firstName ? true : false} name="firstName" value={form.firstName} onChange={handleChange} style={{ width: "100%" }} />
                        <label for="firstname">FirstName</label>
                    </FloatLabel>
                    

                    <FloatLabel>
                        <InputText invalid={errors.lastName ? true : false} name="lastName" value={form.lastName} onChange={handleChange} style={{ width: "100%" }} />
                        <label for="lastname">LastName</label>
                    </FloatLabel>
                    {errors.lastName && <small className="p-error">{errors.lastName}</small>}
{/* 
                    <FloatLabel>
                        <InputText invalid={errors.email ? true : false} name="email" value={form.email} onChange={handleChange} style={{ width: "100%" }} />
                        <label for="email">Email</label>
                    </FloatLabel>
                    {errors.email && <small className="p-error">{errors.email}</small>} */}

                    <div className="flex justify-content-end mt-4">
                        <Button label="Cancel" className="p-button-text mr-2" onClick={() => {
                            setvisibleUpdate(false)
                            setCurrentTeamMemberID('')
                            setErrors({})
                        }} />
                        <Button label="Update" onClick={handleUpdateTeamMember} />
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default Team;
