import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyARkPrXwxbjPlAu5FVWQ1OeC-PlhDPuw5c",
  authDomain: "dynaptmax-986a1.firebaseapp.com",
  projectId: "dynaptmax",
  storageBucket: "dynaptmax.appspot.com",
  messagingSenderId: "725962846830",
  appId: "1:725962846830:web:a5a4669acf71734245ac7f",
  measurementId: "G-9GYEXPJCKS"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);



export { app, auth, db };