import React, { useState, useRef, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Ripple } from 'primereact/ripple';
import { Menubar } from 'primereact/menubar';
import { Menu } from 'primereact/menu';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import { StyleClass } from 'primereact/styleclass';
import Logo from "../assests/dynapt-solutions-logo.png"

import { useAuth } from "../auth/authContext";
import { doSignOut } from "../auth/firebase/auth";
import { useNavigate } from "react-router-dom";

export default function SidebarMenu() {
    const toast = useRef(null);
    const { userLoggedIn, currentUser, userDetails } = useAuth();
    let userName = '';
    if(userLoggedIn && userDetails){
        userName = (userDetails.FirstName + ' ' + ((userDetails.LastName==='-')? '':userDetails.LastName));
    }
     

    // console.log(currentUser);
    //  console.log("userDetails===============>>>>>", userDetails);

    let navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [AavataarLabel, setAavataarLabel] = useState('');
    const [AavataarBackgroundColor, setAavaatarBackgroundColor] = useState('');
    const btnRef1 = useRef(null);
    const btnRef2 = useRef(null);
    const btnRef3 = useRef(null);
    const btnRef4 = useRef(null);

    let count = 0;
    useEffect(() => {
        // Fetch FAQs from the API
        if (count === 0 && userLoggedIn && userDetails) {
            count = count + 1
            stringAvatar(`${userDetails.FirstName} ${userDetails.LastName}`)
            stringToColor(`${userDetails.FirstName} ${userDetails.LastName}`)

        }
    }, []); // Empty dependency array ensures this effect runs only once after initial render

    const accept = () => {
        // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        doSignOut().then(() => { navigate('/home') })
    }

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const confirm = () => {
        confirmDialog({
            message: 'Are you sure you want to Log out ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept,
            reject
        });
    };

    function stringToColor(name) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < name.length; i += 1) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = "#";

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        setAavaatarBackgroundColor(color);
    }

    function stringAvatar(name) {
        setAavataarLabel(`${name.split(" ")[0][0]}${name.split(" ")[1][0]}`)
    }

    const productMenu = useRef(null);
    const pricingMenu = useRef(null);
    const resourcesMenu = useRef(null);
    const solutionsMenu = useRef(null);

    const productItems = [
        { label: 'Product 1', icon: 'pi pi-fw pi-tag', command: () => {  } },
        { label: 'Product 2', icon: 'pi pi-fw pi-tag', command: () => {  } }
    ];

    const resourcesItems = [
        { label: 'Blog', icon: 'pi pi-fw pi-book', command: () => {  } },
        { label: 'Documentation', icon: 'pi pi-fw pi-file', command: () => {  } }
    ];

    const solutionsItems = [
         { label: 'Solution 1', icon: 'pi pi-fw pi-briefcase', command: () => { } },
         { label: 'Solution 2', icon: 'pi pi-fw pi-briefcase', command: () => {  } }
    ];
    
    const userMenu = useRef(null);

    const handleGenerateBotClick = () => {
        navigate("/chatbots");
    };

    const handleLogin = () =>{
        navigate("/login");
    };




    return (
        <>
            <div className="NavBar flex justify-content-start align-items-center" style={{ width: '100%', padding: '20px', boxSizing: 'border-box' }}>
                {userLoggedIn && (
                    <span className="mr-5">
                        <Button icon="pi pi-bars" severity="success" outlined onClick={(e) => setVisible(true)} aria-controls={visible ? 'sbar' : null} aria-expanded={visible ? true : false} />
                    </span>
                )}

                <img src={Logo} alt="logo" height={45} className="mr-3" onClick={()=>{navigate("/home")}}/>

                <nav className="flex" style={{ flexGrow: 1, justifyContent: 'space-between', alignItems: 'center', marginLeft: 'auto' }}>
                    <div className="flex" style={{ gap: '30px', marginLeft: 'auto' }}>
                        {['Products', 'Resources', 'Solutions'].map((item, index) => (
                            <div
                                key={item}
                                style={{ position: 'relative', cursor: 'pointer', padding: '10px' }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = '#f0f0f0';
                                    [productMenu, resourcesMenu, solutionsMenu][index].current.toggle(e);
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = '';
                                    [productMenu, resourcesMenu, solutionsMenu][index].current.hide(e);
                                }}
                            >
                                {item} <i className="pi pi-angle-down" style={{ marginLeft: '5px' }}></i>
                                <Menu model={[productItems, resourcesItems, solutionsItems][index]} popup ref={[productMenu, resourcesMenu, solutionsMenu][index]} />
                            </div>
                        ))}
                    </div>
                    <div className="flex" style={{ gap: '20px', marginLeft: '30px' }}>
                        <Button label="Plans & Pricing" onClick={() => navigate('/subscription')} style={{ backgroundColor: '#ffffff', borderColor: '#ffffff', color: '#000000' }} />
                        {/* <Button label="" icon="pi pi-cog" onClick={() => navigate('/settings')} style={{ backgroundColor: '#ffffff', borderColor: '#ffffff', color: '#000000' }} /> */}
                    </div>
                    {userLoggedIn? (
                    <div className="flex align-items-center" style={{ gap: '30px', marginLeft:'30px', marginRight:'30px' }}>
                        <Button label="Generate AI Agent" onClick={handleGenerateBotClick} style={{ backgroundColor: 'green', borderColor: '#006400', color: '#ffffff' }} />
                        <div className="cursor-pointer" onClick={(e) => userMenu.current.toggle(e)}>
                            <Avatar label={AavataarLabel} style={{ backgroundColor: AavataarBackgroundColor, color: '#ffffff' }} shape="circle" />
                            <Menu model={[{ label: userName, items: [{ label: 'Log Out', command: confirm }] }]} popup ref={userMenu} />
                        </div>
                    </div>):(
                    <div style={{marginLeft:'30px', marginRight:'30px'}}>
                       <Button label="Login" onClick={handleLogin} style={{ backgroundColor: 'green', borderColor: '#006400', color: '#ffffff' }} />
                    </div>
                    )}
                </nav>
            </div> 


            <Sidebar
                visible={visible}
                onHide={() => setVisible(false)}
                content={({ closeIconRef, hide }) => (
                    <div className="min-h-screen flex relative lg:static surface-ground">
                        <div id="app-sidebar-2" className="surface-section h-screen lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none" style={{ width: "auto" }}>
                            <div className="flex flex-column h-full">
                                <div className="flex align-items-center justify-content-center px-4 pt-3 flex-shrink-0">
                                    <span className="inline-flex align-items-center justify-content-start gap-2 w-full mr-5">
                                        <img src={Logo} alt="logo" height={40} />
                                    </span>

                                    <span>
                                        <Button type="button" ref={closeIconRef} onClick={(e) => hide(e)} icon="pi pi-times" rounded outlined className="h-2rem w-2rem"></Button>
                                    </span>
                                </div>
                                <div className="overflow-y-auto">
                                    <ul className="list-none p-3 m-0 overflow-hidden">
                                        <li>
                                            <span onClick={() => { navigate('/home') }}>
                                                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                    <i className="pi pi-home mr-2"></i>
                                                    <span className="font-medium">Home</span>
                                                    <Ripple />
                                                </a>
                                            </span>
                                        </li>
                                        {/* <li>
                                            <span onClick={() => { navigate('/faqs') }}>
                                                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                    <i className="pi pi-question-circle mr-2"></i>
                                                    <span className="font-medium">FAQs</span>
                                                    <Ripple />
                                                </a>
                                            </span>
                                        </li>
                                        <li>
                                            <span onClick={() => { navigate('/documents') }}>
                                                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                    <i className="pi pi-file mr-2"></i>
                                                    <span className="font-medium">Documents</span>
                                                    <Ripple />
                                                </a>
                                            </span>
                                        </li> */}

                                        <li>
                                            <span onClick={() => { navigate('/team') }}>
                                                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                    <i className="pi pi-users mr-2"></i>
                                                    <span className="font-medium">Team</span>
                                                    <Ripple />
                                                </a>
                                            </span>
                                        </li>
                                        
                                        
                                        <li>
                                            <span onClick={() => { navigate('/repos') }}>
                                                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                    <i className="pi pi-folder mr-2"></i>
                                                    <span className="font-medium">Repository</span>
                                                    <Ripple />
                                                </a>
                                            </span>
                                        </li>

                                        <li>
                                            <span onClick={() => { navigate('/integrations') }}>
                                                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                    <i className="pi pi-link mr-2"></i>
                                                    <span className="font-medium">Integrations</span>
                                                    <Ripple />
                                                </a>
                                            </span>
                                        </li>

                                        <li>
                                            {/* <span onClick={() => { navigate('/botstudio') }}> */}
                                            <span onClick={() => { navigate('/chatbots') }}>
                                                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                    <i className="pi pi-comments mr-2"></i>
                                                    <span className="font-medium">Bot Studio</span>
                                                    {/* <span className="inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle" style={{ minWidth: '1.5rem', height: '1.5rem' }}>
                                                    3
                                                </span> */}
                                                    <Ripple />
                                                </a>
                                            </span>
                                        </li>

                                        <li>
                                            <span onClick={() => { navigate('/reports') }}>
                                                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                    <i className="pi pi-chart-line mr-2"></i>
                                                    <span className="font-medium">Reports</span>
                                                    <Ripple />
                                                </a>
                                            </span>
                                        </li>

                                        
                                        {/* <li>
                                            <StyleClass nodeRef={btnRef2} selector="@next" enterClassName="hidden" enterActiveClassName="slidedown" leaveToClassName="hidden" leaveActiveClassName="slideup">
                                                <a ref={btnRef2} className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                    <i className="pi pi-chart-line mr-2"></i>
                                                    <span className="font-medium">Report</span>
                                                    <i className="pi pi-chevron-down ml-auto mr-1"></i>
                                                    <Ripple />
                                                </a>
                                            </StyleClass>
                                            <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                <li>
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                        <i className="pi pi-chart-line mr-2"></i>
                                                        <span className="font-medium"> Revenue</span>
                                                        <Ripple />
                                                    </a>
                                                </li>

                                                <li>
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                        <i className="pi pi-chart-line mr-2"></i>
                                                        <span className="font-medium">Expenses</span>
                                                        <Ripple />
                                                    </a>
                                                </li>
                                            </ul>
                                        </li> */}

                                        <li>
                                            <a 
                                            href="https://l2support.dynaptmax.ai/" 
                                                target="_blank" 
                                                rel="noopener noreferrer" 
                                                className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <i className="pi pi-info-circle mr-2"></i>
                                                <span className="font-medium">L2Support</span>
                                                <Ripple />
                                            </a>
                                        </li>



                                        {/* <li>
                                            <span onClick={() => { navigate('/chatbot') }}>
                                                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                    <i className="pi pi-comments mr-2"></i>
                                                    <span className="font-medium">Chat Bot</span> */}
                                                    {/* <span className="inline-flex align-items-center justify-content-center ml-auto bg-blue-500 text-0 border-circle" style={{ minWidth: '1.5rem', height: '1.5rem' }}>
                                                    3
                                                </span> */}
                                                    {/* <Ripple />
                                                </a>
                                            </span>
                                        </li> */}

                                        

                                        <li>    
                                        <span onClick={() => { navigate('/subscription') }}>
                                            <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                <i className="pi pi-cog mr-2"></i>
                                                <span className="font-medium">Settings</span>
                                                <Ripple />
                                            </a>
                                            </span>
                                        </li>
                                        <li>
                                            {/* <span onClick={() => { doSignOut().then(() => { navigate('/') }) }}> */}
                                            {/* <span onClick={confirm}>
                                                <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                                                    <i className="pi pi-sign-out mr-2"></i>
                                                    <span className="font-medium">Log Out</span>
                                                    <Ripple />
                                                </a>
                                            </span> */}
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="mt-auto">
                                    <hr className="mb-3 mx-3 border-top-1 border-none surface-border" />
                                    <a v-ripple className="m-3 flex align-items-center cursor-pointer p-3 gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                                        <Avatar
                                            // image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
                                            label={AavataarLabel}
                                            style={{ backgroundColor: `${AavataarBackgroundColor}`, color: '#ffffff' }}
                                            shape="circle"
                                        // size="large"
                                        />
                                        <span className="font-bold">{userEmail}</span>
                                    </a>
                                </div> */}

                            </div>
                        </div>
                    </div>
                )}
                className="lg:static lg:w-auto w-80" // Adjust width for large screens
                breakpoint="lg" // This will apply 'static' layout for screens wider than 'lg'
                baseZIndex={1000} >
            </Sidebar>
            {/* <ConfirmDialog /> */}
        </>
    )
}