// import React, { useCallback, useState, useMemo } from 'react';
// import ReactFlow, { useNodesState, Controls, useEdgesState, addEdge, updateEdge, Background, BackgroundVariant, MarkerType, MiniMap } from 'reactflow';
// import TextNode from './TextNode';
// import TextBox from './TextBox';
// import 'reactflow/dist/style.css';
// import "../Style/flow.css";
// import SidebarMenu from "./sidebar";
// import { Toast } from 'primereact/toast';
// import { useNavigate } from "react-router-dom";
// import { Button } from 'primereact/button';
// import DownloadButton from './DownloadButton';
// import { useLocation } from 'react-router-dom';
// import { ProgressSpinner } from 'primereact/progressspinner';
// import { useAuth } from "../auth/authContext";


// // const initialNodes = [
// //   { id: '1', position: { x: 100, y: 20 }, data: { value: '123' }, type : TextNode },
// // ];

// // const initialEdges = [
// //     { id: 'e1-2', source: '1', target: '2' , type : 'smoothstep',label: 'Edge 1'}
// //   ];

// const Flow = () => {
//   const toast = React.useRef(null);
//   let navigate = useNavigate();

//   const location = useLocation();
//   const { currentUser, userDetails,chatbot,fetchBotDataById} = useAuth();
//   // const { chatbot } = location.state || {};
//   // console.log("chatbot:==============>", chatbot)

//   const flowdata = {
//     nodes: chatbot.FlowData?.nodes && chatbot.FlowData?.nodes !== '' ? chatbot.FlowData.nodes : [],
//     edges: chatbot.FlowData?.edges && chatbot.FlowData?.edges !== '' ? chatbot.FlowData.edges : []
//   };

//   const [nodes, setNodes, onNodesChange] = useNodesState(flowdata.nodes);
//   const [edges, setEdges, onEdgesChange] = useEdgesState(flowdata.edges);
//   const [selectedEdgeOption, setSelectedEdgeOption] = useState('default');
//   const [selectedEdgeId, setSelectedEdgeId] = useState(null);
//   const [selectNodeid, setSelectedNodeId] = useState(null);
//   const [showTextFlow, setShowTextFlow] = useState(false);
//   // const nodeTypes = useMemo(() => ({ textUpdater: TextNode }), []);
//   const nodeTypes = useMemo(() => ({ textUpdater: (props) => <TextNode {...props} setNodes={setNodes} /> }), [setNodes]);
//   const [loading, setLoading] = useState(false);


//   const onEdgeUpdate = useCallback(
//     (oldEdge, newConnection) => setEdges((els) => updateEdge(oldEdge, newConnection, els)),
//     []
//   );

//   // const onConnect = useCallback(
//   //   (params) => setEdges((eds) => addEdge({
//   //     ...params, type: selectedEdgeOption, markerEnd: {
//   //       type: MarkerType.ArrowClosed,
//   //       width: 20,
//   //       height: 20,
//   //     }
//   //   }, eds)),
//   //   [setEdges, selectedEdgeOption],
//   // );

//   const onConnect = useCallback(
//     (params) => setEdges((eds) => addEdge({
//       ...params,
//       type: selectedEdgeOption,
//       markerEnd: {
//         type: MarkerType.ArrowClosed,
//         width: 20,
//         height: 20,
//       },
//       label: 'New Edge Label' // Add a default label for new edges
//     }, eds)),
//     [setEdges, selectedEdgeOption],
//   );

//   const handleDeleteEdge = () => {
//     if (selectedEdgeId) {
//       setEdges((eds) => eds.filter((edge) => edge.id !== selectedEdgeId));
//       setSelectedEdgeId(null);
//     } else {
//       // alert("Please select an edge to delete")
//       toast.current.show({ severity: 'warn', summary: 'Warning', detail: `Please select an edge to delete` });
//     }
//   };

//   const handleDeleteNode = () => {
//     if (selectNodeid) {
//       setNodes((nds) => nds.filter((node) => node.id !== selectNodeid));
//       setEdges((eds) => eds.filter((edge) => edge.source !== selectNodeid && edge.target !== selectNodeid));
//       setSelectedNodeId(null);
//     } else {
//       // alert("Please select a node to delete")
//       toast.current.show({ severity: 'warn', summary: 'Warning', detail: `Please select a node to delete` });
//     }
//   };

//   const handleEdgeOptionChange = (event) => {
//     setSelectedEdgeOption(event.target.value);
//   };

//   const onEdgeClick = (event, edge) => {
//     setSelectedEdgeId(edge.id);
//   };

//   const onNodeClick = (event, node) => {
//     setSelectedNodeId(node.id);
//   }

//   const handleAddNode = () => {
//     setNodes((n) => n.concat({ id: (n.length + 1).toString(), position: { x: 50, y: (n.length * 80) % 650 }, type: 'textUpdater', zIndex: (n.length + 1), data: { value: '' } }));
//   };

//   const handleSwitchBacktoTextPrompt = () => {
//         navigate('/botstudio', { state: { chatbot:chatbot,activeTab: 7 } });
//     };

//   const handleClear = () => {
//     setNodes([]);
//     setEdges([]);
//   }


//   const handleChangeToTextFlow = () => {
//     const hasInput = nodes.length > 0 || edges.length > 0;
//     if (hasInput) {
//       // alert("Please clear the all nodes before changing to Text Flow.");
//       toast.current.show({ severity: 'warn', summary: 'Warning', detail: `Please clear the all nodes before changing to Text Flow.` });
//     } else {
//       setShowTextFlow(true);
//     }
//   };

//   function nodeColor(node) {
//     switch (node.type) {
//       case 'input':
//         return '#6ede87';
//       case 'output':
//         return '#6865A5';
//       default:
//         return '#ff0072';
//     }
//   }


//   if (showTextFlow) {
//     return <TextBox />;
//   } else {
//     return (
//       <div className="app-container">
//         <Toast ref={toast} />
//         <SidebarMenu />
//         <div className="content">
//           {/* <span>
//             <Button icon="pi pi-arrow-left" rounded outlined onClick={() => navigate(-1)} severity="success" className="mb-2" />
//           </span> */}

//           <div className='flowcontainer'>
//             <div className='flow-panel' style={{ width: '100%', height: '82vh' }}>
//               <ReactFlow
//                 nodes={nodes}
//                 edges={edges}
//                 nodeTypes={nodeTypes}
//                 onNodesChange={onNodesChange}
//                 onEdgesChange={onEdgesChange}
//                 onConnect={onConnect}
//                 onEdgeClick={onEdgeClick}
//                 selectNodesOnDrag={false}
//                 onNodeClick={onNodeClick}
//                 onEdgeUpdate={onEdgeUpdate}
//               >
//                 <Controls></Controls>
//                 <DownloadButton
//                   chatbotconfig={chatbot}
//                   handleAddNode={handleAddNode}
//                   handleDeleteNode={handleDeleteNode}
//                   selectedEdgeOption={selectedEdgeOption}
//                   handleEdgeOptionChange={handleEdgeOptionChange}
//                   handleDeleteEdge={handleDeleteEdge}
//                   handleClear={handleClear}
//                   handleSwitchBacktoTextPrompt={handleSwitchBacktoTextPrompt}
//                   setLoading={setLoading}
//                   fetchBotDataById={fetchBotDataById}
//                 />
//                 <MiniMap pannable zoomable nodeColor={nodeColor} />

//                 <Background color="#ccc" variant={BackgroundVariant.Dots} />
//                 {loading && (
//                   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100wh' }}>
//                     <ProgressSpinner />
//                   </div>
//                 )}
//               </ReactFlow>
//             </div>

//             {/* <div className='buttons-panel'>

//               <div className='addNode'>
//                 <button onClick={handleAddNode} className='flowbutton'>
//                   Add node
//                 </button>
//                 <button onClick={handleDeleteNode} className='flowbutton'>
//                   Delete node
//                 </button>
//               </div>

//               <div className='addNode'>
//                 <select value={selectedEdgeOption} onChange={handleEdgeOptionChange} className='flowselect'>
//                   <option value="default">Default</option>
//                   <option value="straight">Straight</option>
//                   <option value="step">Step</option>
//                   <option value="smoothstep">Smooth Step</option>
//                   <option value="simplebezier">Simple Bezier</option>
//                 </select>
//                 <button onClick={handleDeleteEdge} className='flowbutton'>
//                   Delete edge
//                 </button >
//               </div>
//               <div className='addNode'>
//                 <button onClick={handleSwitchBacktoTextPrompt} className='flowbutton'>Swith to Text Prompt</button>
//                 <button onClick={handleClear} className='flowbutton'>
//                   Clear
//                 </button>
//                 <button className='flowbutton'>Save</button>
//               </div>
//             </div> */}

//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default Flow;

import React, { useCallback, useState, useMemo } from 'react';
import ReactFlow, { useNodesState, Controls, useEdgesState, addEdge, updateEdge, Background, BackgroundVariant, MarkerType, MiniMap } from 'reactflow';
import TextNode from './TextNode';
import TextBox from './TextBox';
import 'reactflow/dist/style.css';
import "../Style/flow.css";
import SidebarMenu from "./sidebar";
import { Toast } from 'primereact/toast';
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';
import DownloadButton from './DownloadButton';
import { useLocation } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useAuth } from "../auth/authContext";
import LabelInputModal from './LabelInputModal';
import { NodeTypeModal, ActionTypeModal } from './Modals';

const Flow = () => {
  const toast = React.useRef(null);
  let navigate = useNavigate();
  const location = useLocation();
  const { currentUser, userDetails, chatbot, fetchBotDataById } = useAuth();

  const flowdata = {
    nodes: chatbot.FlowData?.nodes && chatbot.FlowData?.nodes !== '' ? chatbot.FlowData.nodes : [],
    edges: chatbot.FlowData?.edges && chatbot.FlowData?.edges !== '' ? chatbot.FlowData.edges : []
  };

  const [nodes, setNodes, onNodesChange] = useNodesState(flowdata.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(flowdata.edges);
  const [selectedEdgeOption, setSelectedEdgeOption] = useState('default');
  const [selectedEdgeId, setSelectedEdgeId] = useState(null);
  const [selectNodeid, setSelectedNodeId] = useState(null);
  const [showTextFlow, setShowTextFlow] = useState(false);
  const nodeTypes = useMemo(() => ({ textUpdater: (props) => <TextNode {...props} setNodes={setNodes} /> }), [setNodes]);
  const [loading, setLoading] = useState(false);
  const [labelModalVisible, setLabelModalVisible] = useState(false);
  const [newEdgeParams, setNewEdgeParams] = useState(null);


  const [nodeTypeModalVisible, setNodeTypeModalVisible] = useState(false);

  const [actionTypeModalVisible, setActionTypeModalVisible] = useState(false);

  const [newNodeType, setNewNodeType] = useState(null);

  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) => setEdges((els) => updateEdge(oldEdge, newConnection, els)),
    []
  );

  const onConnect = useCallback((params) => {
    setNewEdgeParams(params);
    setLabelModalVisible(true);
  }, []);

  const handleSaveLabel = (label) => {
    if (newEdgeParams) {
      setEdges((eds) => addEdge({
        ...newEdgeParams,
        type: selectedEdgeOption,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
        },
        label: label || undefined// Use the provided label or a default if none is provided
      }, eds));
      setNewEdgeParams(null);
    }
  };

  const handleDeleteEdge = () => {
    if (selectedEdgeId) {
      setEdges((eds) => eds.filter((edge) => edge.id !== selectedEdgeId));
      setSelectedEdgeId(null);
    } else {
      toast.current.show({ severity: 'warn', summary: 'Warning', detail: `Please select an edge to delete` });
    }
  };

  const handleDeleteNode = () => {
    if (selectNodeid) {
      setNodes((nds) => nds.filter((node) => node.id !== selectNodeid));
      setEdges((eds) => eds.filter((edge) => edge.source !== selectNodeid && edge.target !== selectNodeid));
      setSelectedNodeId(null);
    } else {
      toast.current.show({ severity: 'warn', summary: 'Warning', detail: `Please select a node to delete` });
    }
  };

  const handleEdgeOptionChange = (event) => {
    setSelectedEdgeOption(event.target.value);
  };

  const onEdgeClick = (event, edge) => {
    setSelectedEdgeId(edge.id);
  };

  const onNodeClick = (event, node) => {
    setSelectedNodeId(node.id);
  }

  // const handleAddNode = () => {
  //   setNodes((n) => n.concat({ id: (n.length + 1).toString(), position: { x: 50, y: (n.length * 80) % 650 }, type: 'textUpdater', zIndex: (n.length + 1), data: { value: '' } }));
  // };

  const handleAddNode = () => {
    setNodeTypeModalVisible(true);
  };

  const handleNodeTypeSelect = (type) => {
    setNewNodeType(type);
    setNodeTypeModalVisible(false);
    if (type === 'actionNode') {
      setActionTypeModalVisible(true);
    } else {
      createNode(type);
    }
  };

  const handleActionTypeSelect = (actionType) => {
    setActionTypeModalVisible(false);
    createNode(newNodeType, actionType);
  };

  const createNode = (type, actionType) => {
    const newNode = {
      id: (nodes.length + 1).toString(),
      position: { x: 50, y: (nodes.length * 80) % 650 },
      type: 'textUpdater',
      zIndex: (nodes.length + 1),
      data: { value: '' },
    };
    if (type === 'actionNode') {
      newNode.data = { value: actionType };
    }
    setNodes((n) => n.concat(newNode));
  };

  const handleSwitchBacktoTextPrompt = () => {
    navigate('/botstudio', { state: { chatbot: chatbot, activeTab: 8 } });
  };

  const handleClear = () => {
    setNodes([]);
    setEdges([]);
  }

  const handleChangeToTextFlow = () => {
    const hasInput = nodes.length > 0 || edges.length > 0;
    if (hasInput) {
      toast.current.show({ severity: 'warn', summary: 'Warning', detail: `Please clear the all nodes before changing to Text Flow.` });
    } else {
      setShowTextFlow(true);
    }
  };

  function nodeColor(node) {
    switch (node.type) {
      case 'input':
        return '#6ede87';
      case 'output':
        return '#6865A5';
      default:
        return '#ff0072';
    }
  }

  if (showTextFlow) {
    return <TextBox />;
  } else {
    return (
      <div className="app-container">
        <Toast ref={toast} />
        <SidebarMenu />
        <div className="content">
          <div className='flowcontainer'>
            <div className='flow-panel' style={{ width: '100%', height: '82vh' }}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onEdgeClick={onEdgeClick}
                selectNodesOnDrag={false}
                onNodeClick={onNodeClick}
                onEdgeUpdate={onEdgeUpdate}
              >
                <Controls />
                <DownloadButton
                  chatbotconfig={chatbot}
                  handleAddNode={handleAddNode}
                  handleDeleteNode={handleDeleteNode}
                  selectedEdgeOption={selectedEdgeOption}
                  handleEdgeOptionChange={handleEdgeOptionChange}
                  handleDeleteEdge={handleDeleteEdge}
                  handleClear={handleClear}
                  handleSwitchBacktoTextPrompt={handleSwitchBacktoTextPrompt}
                  setLoading={setLoading}
                  fetchBotDataById={fetchBotDataById}
                />
                <MiniMap pannable zoomable nodeColor={nodeColor} />
                <Background color="#ccc" variant={BackgroundVariant.Dots} />
                {loading && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100wh' }}>
                    <ProgressSpinner />
                  </div>
                )}
              </ReactFlow>
            </div>
          </div>
        </div>
        <NodeTypeModal
          visible={nodeTypeModalVisible}
          onHide={() => setNodeTypeModalVisible(false)}
          onSelect={handleNodeTypeSelect}
        />

        <ActionTypeModal
          visible={actionTypeModalVisible}
          onHide={() => setActionTypeModalVisible(false)}
          onSelect={handleActionTypeSelect}
        />
        <LabelInputModal
          visible={labelModalVisible}
          onHide={() => setLabelModalVisible(false)}
          onSave={handleSaveLabel}
        />
      </div>
    );
  }
}

export default Flow;
