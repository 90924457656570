import React, { useState } from 'react';
import { Panel, useReactFlow, getRectOfNodes, getTransformForBounds } from 'reactflow';
import { toPng } from 'html-to-image';
import { BASE_URL } from '../utils/config'
import { Toast } from 'primereact/toast';


const imageWidth = 1024;
const imageHeight = 768;

const DownloadButton = ({ chatbotconfig, handleAddNode, handleDeleteNode, selectedEdgeOption, handleEdgeOptionChange, handleDeleteEdge, handleClear, handleSwitchBacktoTextPrompt,setLoading,fetchBotDataById}) => {

  const toast = React.useRef(null);
  const { getNodes, getEdges } = useReactFlow();



  const onClick = () => {

    const nodesBounds = getRectOfNodes(getNodes());
    const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

    toPng(document.querySelector('.react-flow__viewport'), {
      backgroundColor: '#1a365d',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then(extractTextFromImage);
  };

  // function downloadImage(dataUrl) {
  //   // console.log('dataUrl==========>',dataUrl)
  //   const a = document.createElement('a');

  //   a.setAttribute('download', 'reactflow.png');
  //   a.setAttribute('href', dataUrl);
  //   a.click();
  // }

  const extractTextFromImage = async (dataUrl) => {
    // console.log('dataUrl==========>', dataUrl)
    setLoading(true);
    try {
      const response = await fetch(`https://us-central1-max-prod-417007.cloudfunctions.net/get_prompt_flow`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // prompt: "You are a plan executer an user give you a flow diagram an you need to convert into text prompt flow",
          image_data: dataUrl
        }),
      });

      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! Status: ${response.status}`);
        
      }

      const data = await response.json();
      console.log("text prompt data=======>", data.response);
      SaveFlowTextInbotConfiguration(data.response,dataUrl)

    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  }

  const SaveFlowTextInbotConfiguration = async (flowtext,dataUrl) => {
    try {
      const nodes = getNodes();
      const edges = getEdges();
      const flowData = { nodes, edges };

      const url = `${BASE_URL}/update_prompt_diagram_flow`;
      const params = new URLSearchParams({
        configuration_id: chatbotconfig.Id,
        // updated_prompt_diagram_flow: flowtext,
      });

      await fetch(`${url}?${params.toString()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({
          FlowData: flowData,
          PromptDiagramFlow: flowtext,
          FlowImageURL:dataUrl
        })
      })
        .then(response => {
          if (!response.ok) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Save flow' });
            setLoading(false);
            throw new Error('Network response was not ok');

          }
          return response.json();
        })
        .then(data => {
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Flow Save successfully' });
          setLoading(false);
          // Handle the API response if needed
          console.log("data", data);
          fetchBotDataById(chatbotconfig.Guid)
        })
        .catch(error => {
          // Handle errors here
          setLoading(false);
          console.error('Error:', error);
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Save flow.' });
        });
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
      toast.current.show({ severity: 'error', summary: 'Error', detail: error });
    }
  };

  return (

    <Panel position="top-right">
      <Toast ref={toast} />
      <div>
        <button className="download-btn" onClick={onClick} style={{ marginRight: '5px', cursor: 'pointer' }}>
          Save Flow
        </button>
        <button className="download-btn" onClick={handleAddNode} style={{ marginRight: '5px', cursor: 'pointer' }}>
          Add node
        </button>
        <button className="download-btn" onClick={handleDeleteNode} style={{ marginRight: '5px', cursor: 'pointer' }}>
          Delete node
        </button>
        <button className="download-btn" onClick={handleDeleteEdge} style={{ marginRight: '5px', cursor: 'pointer' }}>
          Delete edge
        </button>
        <button className="download-btn" onClick={handleClear} style={{ marginRight: '5px', cursor: 'pointer' }}>
          Clear
        </button>
        <button className="download-btn" onClick={handleSwitchBacktoTextPrompt} style={{ marginRight: '5px', cursor: 'pointer' }}>
          Text Prompt
        </button>
        <select className="download-btn" value={selectedEdgeOption} onChange={handleEdgeOptionChange} style={{ cursor: 'pointer', padding:'1px'}}>
          <option value="default" style={{color:'black'}}>Default</option>
          <option value="straight" style={{color:'black'}}>Straight</option>
          <option value="step" style={{color:'black'}}>Step</option>
          <option value="smoothstep" style={{color:'black'}}>Smooth Step</option>
          <option value="simplebezier" style={{color:'black'}}>Simple Bezier</option>
        </select>
      </div>

      {/* handleAddNode={handleAddNode} 
                handleDeleteNode={handleDeleteNode}
                selectedEdgeOption={selectedEdgeOption}
                handleEdgeOptionChange={handleEdgeOptionChange}
                handleDeleteEdge={handleDeleteEdge} */}
    </Panel>
  );
}

export default DownloadButton;
