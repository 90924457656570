const Templates = [
    {
        Logo: '',
        themeColor: '53AA59',
        welcomeMessage: "Hi, I'm Dyna, your Personal Digital Assistant. I'm here to help you with any IT-related issues or queries you may have. How can I assist you today?",
        fallbackMessage: "I apologize, but I am currently unable to respond. Please try again later.",
        BotName: "AI Chat for IT HelpDesk",
        orgname: "Dynaptmax",
        orginfo: "DynaptMAX is a Generative-AI based help desk system designed to revolutionize customer support",
        contactinfo: "organization contact info........",
        repoId: 0,
        avatarName: "avatar1",
        chatTypeOptions: [
            { name: 'Chat Only', code: 'ChatOnly' },
            { name: 'Chat And Avatar', code: 'ChatAndAvatar' },
            { name: 'Avatar Only', code: 'AvatarOnly' },
            { name: 'Chat and Audio', code: 'ChatAndAudio' }
        ],
        llmModel: 'gpt3.5',
        languages: [{ name: 'English', code: 'eng' }],
        userId:"",
        intro:"An IT Helpdesk Chatbot is an AI-powered virtual assistant that streamlines technical support within an organization. Utilizing artificial intelligence and natural language processing, it provides immediate assistance, automating routine tasks and enhancing efficiency, allowing IT staff to focus on more complex issues.",
        flow:""

    },
    {
        Logo: '',
        themeColor: '53AA59',
        welcomeMessage: "Hi, I'm Dyna, your Personal Digital Assistant. I'm here to assist you with any HR-related questions or concerns you might have. How can I help you today?",
        fallbackMessage: "I apologize, but I am currently unable to respond. Please try again later.",
        BotName: "AI Chat for HR",
        orgname: "Dynaptmax",
        orginfo: "DynaptMAX is a Generative-AI based help desk system designed to revolutionize customer support",
        contactinfo: "organization contact info........",
        repoId: 0,
        avatarName: "avatar1",
        chatTypeOptions: [
            { name: 'Chat Only', code: 'ChatOnly' },
            { name: 'Chat And Avatar', code: 'ChatAndAvatar' },
            { name: 'Avatar Only', code: 'AvatarOnly' },
            { name: 'Chat and Audio', code: 'ChatAndAudio' },
        ],
        llmModel: 'gpt3.5',
        languages: [{ name: 'English', code: 'eng' }],
        userId: "",
        intro:"An AI Chat for HR is a cutting-edge tool that provides employees with instant access to HR information, automates routine tasks, and facilitates seamless communication between staff and HR teams. It enhances efficiency, streamlines processes, and improves overall employee engagement.",
        flow:""
    },
    {
        Logo: '',
        themeColor: '53AA59',
        welcomeMessage: "Hi, I'm Dyna, your Personal Digital Assistant. I'm here to assist you with any inquiries or issues you might have related to our products and services. How can I help you today?",
        fallbackMessage: "I apologize, but I am currently unable to respond. Please try again later.",
        BotName: "AI Chat for Business",
        orgname: "Dynaptmax",
        orginfo: "DynaptMAX is a Generative-AI based help desk system designed to revolutionize customer support",
        contactinfo: "organization contact info........",
        repoId: 0,
        avatarName: "avatar1",
        chatTypeOptions: [
            { name: 'Chat Only', code: 'ChatOnly' },
            { name: 'Chat And Avatar', code: 'ChatAndAvatar' },
            { name: 'Avatar Only', code: 'AvatarOnly' },
            { name: 'Chat and Audio', code: 'ChatAndAudio' },
        ],
        llmModel: 'gpt3.5',
        languages: [{ name: 'English', code: 'eng' }],
        userId: "",
        intro:"A business chatbot is an AI-driven tool designed to enhance customer engagement, streamline operations, and provide instant support. It automates routine tasks, answers queries, and offers personalized experiences, improving efficiency and customer satisfaction while allowing businesses to focus on core activities.",
        flow:""
    },
    {
        Logo: '',
        themeColor: '53AA59',
        welcomeMessage: "Hi, I'm Dyna, your Personal Digital Assistant. I'm here to assist you with any inquiries or issues you might have related to our products and services. How can I help you today?",
        fallbackMessage: "I apologize, but I am currently unable to respond. Please try again later.",
        BotName: "Ask Anything AI Chat",
        orgname: "Dynaptmax",
        orginfo: "DynaptMAX is a Generative-AI based help desk system designed to revolutionize customer support",
        contactinfo: "organization contact info........",
        repoId: 0,
        avatarName: "avatar1",
        chatTypeOptions: [
            { name: 'Chat Only', code: 'ChatOnly' },
            { name: 'Chat And Avatar', code: 'ChatAndAvatar' },
            { name: 'Avatar Only', code: 'AvatarOnly' },
            { name: 'Chat and Audio', code: 'ChatAndAudio' },
        ],
        llmModel: 'gpt3.5',
        languages: [{ name: 'English', code: 'eng' }],
        userId: "",
        intro:"A business chatbot is an AI-driven tool designed to enhance customer engagement, streamline operations, and provide instant support. It automates routine tasks, answers queries, and offers personalized experiences, improving efficiency and customer satisfaction while allowing businesses to focus on core activities.",
        flow:""
    },
    {
        Logo: '',
        themeColor: '53AA59',
        welcomeMessage: "Hi, I'm Dyna, your Personal Digital Assistant. I'm here to assist you with any inquiries or issues you might have related to our products and services. How can I help you today?",
        fallbackMessage: "I apologize, but I am currently unable to respond. Please try again later.",
        BotName: "Ask Dyna",
        orgname: "Dynaptmax",
        orginfo: "DynaptMAX is a Generative-AI based help desk system designed to revolutionize customer support",
        contactinfo: "organization contact info........",
        repoId: 0,
        avatarName: "avatar1",
        chatTypeOptions: [
            { name: 'Chat Only', code: 'ChatOnly' },
            { name: 'Chat And Avatar', code: 'ChatAndAvatar' },
            { name: 'Avatar Only', code: 'AvatarOnly' },
            { name: 'Chat and Audio', code: 'ChatAndAudio' },
        ],
        llmModel: 'gpt3.5',
        languages: [{ name: 'English', code: 'eng' }],
        userId: "",
        intro:"A business chatbot is an AI-driven tool designed to enhance customer engagement, streamline operations, and provide instant support. It automates routine tasks, answers queries, and offers personalized experiences, improving efficiency and customer satisfaction while allowing businesses to focus on core activities.",
        flow:""
    },
    {
        Logo: '',
        themeColor: '53AA59',
        welcomeMessage: "Hi, I'm Dyna, your Personal Digital Assistant. I'm here to assist you with any inquiries or issues you might have related to our products and services. How can I help you today?",
        fallbackMessage: "I apologize, but I am currently unable to respond. Please try again later.",
        BotName: "Homework Assistant Chatbot",
        orgname: "Dynaptmax",
        orginfo: "DynaptMAX is a Generative-AI based help desk system designed to revolutionize customer support",
        contactinfo: "organization contact info........",
        repoId: 0,
        avatarName: "avatar1",
        chatTypeOptions: [
            { name: 'Chat Only', code: 'ChatOnly' },
            { name: 'Chat And Avatar', code: 'ChatAndAvatar' },
            { name: 'Avatar Only', code: 'AvatarOnly' },
            { name: 'Chat and Audio', code: 'ChatAndAudio' },
        ],
        llmModel: 'gpt3.5',
        languages: [{ name: 'English', code: 'eng' }],
        userId: "",
        intro:"A Homework Assistant Chatbot is an AI-driven tool designed to help students with their assignments. It provides instant answers, step-by-step solutions, and personalized study tips, making learning more efficient and engaging. This chatbot enhances academic support, helping students understand concepts better and complete their homework successfully.",
        flow:""
    },

]

export default Templates