import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const LabelInputModal = ({ visible, onHide, onSave }) => {
  const [label, setLabel] = useState('');

  const handleSave = () => {
    onSave(label);
    setLabel('');
    onHide();
  };

  return (
    <Dialog header="Enter Edge Label" visible={visible} onHide={onHide} footer={<Button label="Save" onClick={handleSave} />}>
      <InputText value={label} onChange={(e) => setLabel(e.target.value)} style={{marginTop:'5px'}} />
    </Dialog>
  );
};

export default LabelInputModal;
