import React, { useState } from 'react';
import Logo from "../assests/dynaptlogo.png"
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { doSignInWithEmailAndPassword, doSignInWithGoogle, doSignInWithMicrosoft } from '../auth/firebase/auth'; // Import the function for Microsoft sign-in
import { useAuth } from '../auth/authContext';  
import { Navigate, useNavigate } from 'react-router-dom'; 

function Login() {
    let navigate = useNavigate();
    const { userLoggedIn } = useAuth()
    const [credentials, setCredentials] = useState({
        username: '',
        password: ''
    });
    const [errors, setErrors] = useState({});
    const toast = React.useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validate = () => {
        let tempErrors = {};
        let isValid = true;
        if (!credentials.username.trim()) {
            isValid = false;
            tempErrors.username = "Username is required";
        }
        if (!credentials.password) {
            isValid = false;
            tempErrors.password = "Password is required";
        }
        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            await doSignInWithEmailAndPassword(credentials.username, credentials.password)
            .then(()=>{
                 toast.current.show({ severity: 'success', summary: 'Login Success', detail: 'You are now logged in!' });
                //console.log("Form data", credentials);
            })
            .catch(err => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: '! Invalid Credentials.' });
            })
           
            // You can proceed with API calls or further logic here
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Check the input fields.' });
        }
    };

    const onGoogleSignIn = (e) => {
        e.preventDefault()
            doSignInWithGoogle().catch(err => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: '!Google Login Failed.' });
            })
    }

    const onMicrosoftSignIn = (e) => {
        e.preventDefault()
            doSignInWithMicrosoft().catch(err => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: '!Google Login Failed.' });
            })
    }



    return (
        <div className="login-container">
            {userLoggedIn && (<Navigate to={'/home'} replace={true} />)}
            <Toast ref={toast} />
            <div className="login-card">
                <img src={Logo} alt="logo" height={50} className="mb-3" />
                <h1>Welcome Back</h1>
                <p>Do not have an account? <span className="link"onClick={() => navigate('/Signup')}>Sign up !</span></p>
                <form onSubmit={handleSubmit}>
                    <div className="p-inputgroup flex-1 mb-5" style={{ marginBottom: "10px" }}>
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                        </span>
                        <InputText name="username" value={credentials.username} onChange={handleChange} placeholder="Username" />
                    </div>
                    {errors.username && <small className="p-error">{errors.username}</small>}

                    <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-key"></i>
                        </span>
                        <InputText name="password" type='password' value={credentials.password} onChange={handleChange} placeholder="Password" />
                    </div>
                    {errors.password && <small className="p-error">{errors.password}</small>}

                    <div className="options">
                        <a className="forgot-link">Forgot your password?</a>
                    </div>

                    <Button type="submit" className="loginButton" label="Sign In" severity="success" style={{ background: "#32B749" }} />
                </form>
                <div className="divider">
                    <div className="line"></div>
                    <div className="or">OR</div>
                    <div className="line"></div>
                </div>


                <Button
                    className="loginButton"
                    onClick={(e) => { onGoogleSignIn(e) }}
                    severity="secondary"
                    outlined
                    style={{ marginBottom: "10px" }}>
                    {/* <img src="google-icon.svg" alt="Google" className="icon" />  */}
                    <svg className="icon" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_17_40)">
                            <path d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z" fill="#4285F4" />
                            <path d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z" fill="#34A853" />
                            <path d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z" fill="#FBBC04" />
                            <path d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z" fill="#EA4335" />
                        </g>
                        <defs>
                            <clipPath id="clip0_17_40">
                                <rect width="48" height="48" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    Continue with Google
                </Button>

                <Button
                    className="loginButton"
                    onClick={(e) => { onMicrosoftSignIn(e) }}
                    severity="secondary"
                    outlined >
                    {/* <img src="microsoft-icon.svg" alt="Microsoft" className="icon" />  */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256" className='icon'>
                        <path fill="#f1511b" d="M121.666 121.666H0V0h121.666z" />
                        <path fill="#80cc28" d="M256 121.666H134.335V0H256z" />
                        <path fill="#00adef" d="M121.663 256.002H0V134.336h121.663z" />
                        <path fill="#fbbc09" d="M256 256.002H134.335V134.336H256z" />
                    </svg>
                    Continue with Microsoft
                </Button>


            </div>
        </div>
    );
}

export default Login;
