
import React, { useRef, useState, useEffect } from "react";
import defaultLogo from "../assests/chatlogo.png"
import AvaatarImage from "../assests/Aavaatar.png"
import Avaatar1Image from "../assests/avatar1.png"
import Avaatar2Image from "../assests/Avataar2.png"
import Avaatar3Image from "../assests/avatar3.png"
import SidebarMenu from "./sidebar";
import { BASE_URL } from '../utils/config'
import { Form, useNavigate } from "react-router-dom";
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import { TabView, TabPanel } from 'primereact/tabview';
import { ColorPicker } from 'primereact/colorpicker';
import { MultiSelect } from 'primereact/multiselect';
import { useAuth } from "../auth/authContext";
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { WHATSAPP_BASE_URL } from "../utils/config";
import Integrations from "./integrations";
const showdown = require('showdown');
const converter = new showdown.Converter();



const BotStudio = () => {
    let navigate = useNavigate();
    const toast = React.useRef(null);
    // const stepperRef = useRef(null);
    const fileInputRef = useRef(null);
    const FlowfileInputRef = useRef(null);
    const { currentUser, userDetails, chatbot, fetchBotDataById } = useAuth();
    const location = useLocation();
    const { chatbotID } = location.state || {};
    const { activeTab } = location.state || {};
    const [activeIndex, setActiveIndex] = useState(activeTab ? activeTab : 0); // Default to the first tab
    const [visibleFlowPopUp, setvisibleFlowPopUp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [integrations, setIntegrations] = useState([]);
    const initialIntegrations = useRef(chatbot.IntegrationId);

    const AvatarImages = [
        { AvatarImageURL: AvaatarImage, AvatarName: "Avaatar" },
        { AvatarImageURL: Avaatar1Image, AvatarName: "Avaatar1" },
        { AvatarImageURL: Avaatar2Image, AvatarName: "Avaatar2" },
        { AvatarImageURL: Avaatar3Image, AvatarName: "Avaatar3" },
    ]
    const LLMModel = ["gpt4o", "gpt4", "gpt3.5", "gemini"]
    const [image, setImage] = useState(null);
    // const [welcomeMessage, setWelcomeMessage] = useState('Hi, I am Dyna.I am your PDA.I can walk through your queries regarding IT related issues');
    // const [FallbackMessageage, setFallbackMessage] = useState('Hi, I am Dyna.I am your PDA.I can walk through your queries regarding IT related issues');
    // const [BotName, setBotName] = useState('Dyna');
    // const [colorHEX, setColorHEX] = useState('53AA59');

    const [selectedAvatar, setselectedAvatar] = useState(() => {
        const avatarIndex = AvatarImages.findIndex(item => item.AvatarName === chatbot.AvatarName);
        return avatarIndex !== -1 ? avatarIndex : 0;
    });

    const [selectedLLMM, setselectedLLMM] = useState(() => {
        const LLMIndex = LLMModel.findIndex(item => item === chatbot.LLMModel);
        return LLMIndex !== -1 ? LLMIndex : 0;
    });

    const [CurrentAvaataar, setCurrentAvaataar] = useState(() => {
        switch (chatbot.AvatarName) {
            case "Avaatar1":
                return Avaatar1Image;
            case "Avaatar2":
                return Avaatar2Image;
            case "Avaatar3":
                return Avaatar3Image;
            default:
                return AvaatarImage;
        }
    });
    const [CurrentLLM, setCurrentLLM] = useState('');
    // const [selectedLanguage, setselectedLanguage] = useState(null);
    // const [selectedChatType, setselectedChatType] = useState(null);

    const languages = [
        { sub_name: "हिन्दी", name: 'Hindi', code: 'hi' },
        { sub_name: "English", name: 'English', code: 'eng' },
        { sub_name: "বেঙ্গল", name: 'Bengali', code: 'bn' },
        { sub_name: "తెలుగు", name: 'Telugu', code: 'te' },
        { sub_name: "मराठी", name: 'Marathi', code: 'mr' },
        { sub_name: "தமிழ்", name: 'Tamil', code: 'ta' },
        { sub_name: "ગુજરાતી", name: 'Gujarati', code: 'gu' },
        { sub_name: "ಕನ್ನಡ", name: 'Kannada', code: 'kn' },
        { sub_name: "ଓଡ଼ିଆ", name: 'Odia', code: 'or' },
        { sub_name: "മലയാളം", name: 'Malayalam', code: 'ml' },
        { sub_name: "ਪੰਜਾਬੀ", name: 'Punjabi', code: 'pa' },
        { sub_name: "অসমীয়া", name: 'Assamese', code: 'as' },
        { sub_name: "اردو", name: 'Urdu', code: 'ur' },
        { sub_name: "मैथिली", name: 'Maithili', code: 'mai' },
        { sub_name: "ᱥᱟᱱᱛᱟᱲᱤ", name: 'Santali', code: 'sat' },
        { sub_name: "कॉशुर", name: 'Kashmiri', code: 'ks' },
        { sub_name: "कोंकणी", name: 'Konkani', code: 'kok' },
        { sub_name: "سنڌي", name: 'Sindhi', code: 'sd' },
        { sub_name: "डोगरी", name: 'Dogri', code: 'doi' },
        { sub_name: "মণিপুরী (মেইতেই)", name: 'Manipuri (Meitei)', code: 'mni' },
        { sub_name: "बड़ो", name: 'Bodo', code: 'brx' }
    ];
    const ChatType = [
        { name: 'Chat Only', code: 'ChatOnly' },
        { name: 'Chat And Avatar', code: 'ChatAndAvatar' },
        { name: 'Avatar Only', code: 'AvatarOnly' },
        { name: 'Chat and Audio', code: 'ChatAndAudio' },
    ];

    const initialMessages = [
        {
            text: converter.makeHtml(
                chatbot?.WelcomeMessage || "Hi, I am **Dyna**. I am your PDA. I can walk through your queries regarding IT related issues"
            ),
            sender: "bot"
        },
        { text: "<p>Hey hi, how are you </p>", sender: "user" }
    ];

    const [messages, setMessages] = useState(initialMessages);
    const [input, setInput] = useState("");
    // const [micInput, setMicInput] = useState(""); 
    const messagesEndRef = useRef(null);
    // const [showchat, setshowchat] = useState('none');
    // const [showIcon, setshowIcon] = useState('block');
    const [isListening, setIsListening] = useState(false);
    // const [selectedCards, setSelectedCards] = useState([]);
    const [Repos, setRepos] = useState([]);

    const [selectedRepo, setselectedRepo] = useState(useState(() => {
        const RepoIndex = Repos.findIndex(item => item == chatbot.RepositoryId);
        return RepoIndex !== -1 ? RepoIndex : 0;
    }));

    const [form, setForm] = useState({
        Id: chatbot.Id,
        Logo: chatbot.LogoURL === null || chatbot.LogoURL === undefined ? defaultLogo : chatbot.LogoURL,
        themeColor: chatbot.ThemeColor,
        welcomeMessage: chatbot.WelcomeMessage,
        fallbackMessage: chatbot.FallbackMessage,
        botName: chatbot.BotName,
        orgname: chatbot.OrganizationName,
        orginfo: chatbot.OrganizationInfo,
        orgcontactinfo: chatbot.ContactInfo,
        repoId: chatbot.RepositoryId,
        avatarName: chatbot.AvatarName,
        chatTypeOptions: chatbot.ChatTypeOptions,
        IntegrationId: chatbot.IntegrationId,
        llmModel: chatbot.LLMModel,
        languages: chatbot.Languages,
        userId: chatbot.UserId,
        // TemplateId: chatbot.TemplateId,
        // Flow: chatbot.SystemPrompt,
        Template: chatbot.Template,
        PromptDiagramFlow: chatbot.PromptDiagramFlow
    });


    const count = useRef(0);

    useEffect(() => {
        if (count.current === 0) {
            fetchRepos();
            fetchIntegrations();
            count.current += 1;
        }
    }, []);

    // useEffect(() => {
    //     const state = location.state || {};
    //     console.log("state==============>",state)
    //     if (state.activeTab) {
    //         console.log("state.activeTab==============>",state.activeTab)
    //         setActiveIndex(state.activeTab);
    //     }
    // }, [location.state]);

    const fetchRepos = async () => {
        try {
            if (!userDetails) {
                console.error('User details not available');
                return;
            }

            if (!BASE_URL) {
                console.error('BASE_URL not defined in the environment');
                return;
            }

            const response = await fetch(`${BASE_URL}/team_details/get_repository_details_by_UserId?UserId=${userDetails.UserId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            });

            if (!response.ok) {
                setRepos([]);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Network response was not ok' });
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setRepos(data);

            // Update selectedRepo if repoId matches
            const initialRepoIndex = data.findIndex(item => item.Id === form.repoId);
            if (initialRepoIndex !== -1) {
                setselectedRepo(initialRepoIndex);
            }

        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch Repos' });
        }
    };

    const fetchIntegrations = async () => {
        try {
            if (!userDetails) {
                console.error('User details not available');
                setIntegrations([]);
                return;
            }

            if (!BASE_URL) {
                console.error('BASE_URL not defined in the environment');
                setIntegrations([]);
                return;
            }

            const response = await fetch(`${BASE_URL}/team_details/get_integrations_by_UserId?UserId=${userDetails.UserId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            });

            if (!response.ok) {
                setIntegrations([]);
                // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Network response was not ok' });
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setIntegrations(data);
        } catch (error) {
            console.error('Error:', error);
            // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch Integrations' });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm(prev => ({ ...prev, [name]: value }));
    };

    const handleAvatarCardClick = (index, item) => {
        setForm(prev => ({ ...prev, ["avatarName"]: item.AvatarName }));
        setCurrentAvaataar(item.AvatarImageURL)
        setselectedAvatar(index);
    };

    const handleCardClickForLLM = (index, name) => {
        setForm(prev => ({ ...prev, ["llmModel"]: name }));
        setCurrentLLM(name)
        setselectedLLMM(index);
    };

    const handleCardClickForRepo = (index, item) => {
        setForm(prev => ({ ...prev, repoId: item.Id }));
        setselectedRepo(index);
    };

    const handleCardClickChattype = (index, item) => {
        setForm((prevForm) => {
            const isSelected = prevForm.chatTypeOptions.some(option => option.name === item.name);

            let updatedChatTypeOptions;
            if (isSelected) {
                // If the item is already selected, remove it from the chatTypeOptions
                updatedChatTypeOptions = prevForm.chatTypeOptions.filter(option => option.name !== item.name);
            } else {
                // If the item is not selected, add it to the chatTypeOptions
                updatedChatTypeOptions = [...prevForm.chatTypeOptions, item];
            }

            return {
                ...prevForm,
                chatTypeOptions: updatedChatTypeOptions
            };
        });
    };

    const handleIntegrationsClick = (index, item) => {
        setForm((prevForm) => {
            const isSelected = prevForm.IntegrationId.some(option => option === item.Id);

            let updatedIntegrations;
            if (isSelected) {
                // If the item is already selected, remove it from the Integrations
                updatedIntegrations = prevForm.IntegrationId.filter(option => option !== item.Id);
            } else {
                // If the item is not selected, add it to the Integrations
                updatedIntegrations = [...prevForm.IntegrationId, item.Id];
            }

            return {
                ...prevForm,
                IntegrationId: updatedIntegrations
            };
        });
    };

    const handleCardClickLanguage = (index, item) => {
        setForm((prevForm) => {
            const isSelected = prevForm.languages.some(option => option.name === item.name);

            let updatedChatTypeOptions;
            if (isSelected) {
                // If the item is already selected, remove it from the chatTypeOptions
                updatedChatTypeOptions = prevForm.languages.filter(option => option.name !== item.name);
            } else {
                // If the item is not selected, add it to the chatTypeOptions
                updatedChatTypeOptions = [...prevForm.languages, item];
            }

            return {
                ...prevForm,
                languages: updatedChatTypeOptions
            };
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result); // Update the preview state
                setForm(prev => ({ ...prev, [name]: e.target.result }));
            };
            reader.readAsDataURL(file);
        }
    };
    
    const handleflowFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                extractTextFromImage(e.target.result); // Update the preview state
            };
            reader.readAsDataURL(file);
        }
    };

    const extractTextFromImage = async (dataUrl) => {
        // console.log('dataUrl==========>', dataUrl)
        setLoading(true);
        try {
            const response = await fetch(`https://us-central1-max-prod-417007.cloudfunctions.net/get_prompt_flow`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    // prompt: "You are a plan executer an user give you a flow diagram an you need to convert into text prompt flow",
                    image_data: dataUrl
                }),
            });

            if (!response.ok) {
                setLoading(false);
                throw new Error(`HTTP error! Status: ${response.status}`);

            }

            const data = await response.json();
            console.log("text prompt data=======>", data.response);
            SaveFlowTextInbotConfiguration(data.response, dataUrl)

        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    }

    const SaveFlowTextInbotConfiguration = async (flowtext, dataUrl) => {
        try {

            const url = `${BASE_URL}/update_prompt_diagram_flow`;
            const params = new URLSearchParams({
                configuration_id: chatbot.Id,
            });

            await fetch(`${url}?${params.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify({
                    FlowData: [],
                    PromptDiagramFlow: flowtext,
                    FlowImageURL: dataUrl
                })
            })
                .then(response => {
                    if (!response.ok) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Save flow' });
                        setLoading(false);
                        throw new Error('Network response was not ok');

                    }
                    return response.json();
                })
                .then(data => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Flow Save successfully' });
                    setLoading(false);
                    // Handle the API response if needed
                    console.log("data", data);
                    fetchBotDataById(chatbot.Guid)
                    setvisibleFlowPopUp(false)
                })
                .catch(error => {
                    // Handle errors here
                    setLoading(false);
                    console.error('Error:', error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Save flow.' });
                });
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error });
        }
    };

    const toggleListen = () => {
        // // If currently speaking, stop the speech synthesis
        // if (speechSynthesis.speaking) {
        //     speechSynthesis.cancel();
        // }
        // if (isListening) {
        //     recognition.stop();
        // } else {
        //     setIsListening(true);
        //     recognition.start();
        //     // Event handler for when recognition ends
        //     recognition.onend = () => {
        //         // Automatically set the microphone (listening state) to false when recognition completes
        //         setIsListening(false);
        //     };
        // }
        // // if (isListening) {
        // //   setIsListening(!isListening);
        // // }
    };

    // const handleChange = (e) => {
    //     const updatedMessages = messages.map((message, index) => {
    //         if (message.sender === 'bot') {
    //             return { ...message, text: `<p>${e.target.value}</p>` };
    //         }
    //         return message;
    //     });
    //     setMessages(updatedMessages);
    // };


    async function fetchWhatsappIntegrationDetails(integrationId) {
        const response = await fetch(`${BASE_URL}/get_whatsapp_integration_by_IntegrationId?IntegrationId=${integrationId}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    
        if (!response.ok) {
            throw new Error('Failed to fetch WhatsApp integration details');
        }
    
        const data = await response.json();
        console.log(data);
        return data[0].DeviceId;
    }
    
    async function handleIntegration(integrationId, action) {
        // console.log(integrationId, action);
        try {
            const deviceId = await fetchWhatsappIntegrationDetails(integrationId);
            const url = `${WHATSAPP_BASE_URL}/bot/${action}CheckingMessages/${deviceId}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                throw new Error(`Failed to ${action} checking messages`);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const whatsappIntegrationCheckMessage = async (updatedIntegrations) => {
        // console.log(updatedIntegrations);
        // console.log(initialIntegrations.current);

        const addedIntegrations = updatedIntegrations.filter(id => !initialIntegrations.current.includes(id));
        const removedIntegrations = initialIntegrations.current.filter(id => !updatedIntegrations.includes(id));
    
        // console.log('added---', addedIntegrations);
        // console.log('removed--', removedIntegrations);

        for (const integrationId of addedIntegrations) {
            const integration = integrations.find(item => item.Id === integrationId);
            
            if (integration && integration.Type === 'Whatsapp') {
                console.log(integration);
                await handleIntegration(integrationId,'start');
            }
        }
    
        for (const integrationId of removedIntegrations) {
            const integration = integrations.find(item => item.Id === integrationId);
            
            if (integration && integration.Type === 'Whatsapp') {
                console.log(integration);
                await handleIntegration(integrationId,'stop');
            }
        }
        initialIntegrations.current = updatedIntegrations;
    };
    
    const UpdateBotConfig = async () => {
        try {
            if (!BASE_URL) {
                console.error('BASE_URL not defined in the environment');
                return;
            }
            if (form.repoId === null || form.repoId === "") {
                console.error('repo is required ');
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please Select Repository' });
                return;
            }
            const isUrl = (string) => {
                // Check if the string contains "/static/media/"
                if (string.includes("/static/media/")) {
                    console.log("===========")
                    return true;
                }
                const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                    '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
                    '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
                    '(\\#[-a-zA-Z\\d_]*)?$', 'i'); // fragment locator
                return !!urlPattern.test(string);
            };

            const imageData = isUrl(form.Logo) ? '' : form.Logo;

            await fetch(`${BASE_URL}/update_bot_configuration?configuration_id=${form.Id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    imageData: imageData,
                    ThemeColor: form.themeColor,
                    WelcomeMessage: form.welcomeMessage,
                    FallbackMessage: form.fallbackMessage,
                    BotName: form.botName,
                    OrganizationName: form.orgname,
                    OrganizationInfo: form.orginfo,
                    ContactInfo: form.orgcontactinfo,
                    RepositoryId: form.repoId,
                    AvatarName: form.avatarName,
                    ChatTypeOptions: form.chatTypeOptions,
                    LLMModel: form.llmModel,
                    Languages: form.languages,
                    UserId: form.userId,
                    //TemplateId: form.TemplateId,
                    IntegrationId: form.IntegrationId,
                    //SystemPrompt: form.Flow,
                    Template: form.Template
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Network response was not ok' });
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'save successfully' });
                    UpdateFlowdiagramPrompt(form.PromptDiagramFlow);
                    whatsappIntegrationCheckMessage(form.IntegrationId);
                })
                .catch(error => {
                    // Handle errors here
                    console.error('Error:', error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to save' });
                });
        } catch (error) {
            console.log("error while saving the Data", error)
        }
    };

    const UpdateFlowdiagramPrompt = async (flowtext) => {
        // try {
        //     // console.log("chatbotconfig:",chatbotconfig);
        //     const url = `${BASE_URL}/update_prompt_diagram_flow`;
        //     const params = new URLSearchParams({
        //         configuration_id: form.Id,
        //         // updated_prompt_diagram_flow: flowtext,
        //     });

        //     await fetch(`${url}?${params.toString()}`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'accept': 'application/json'
        //         },
        //         body: JSON.stringify({
        //             FlowData: chatbot.FlowData,
        //             PromptDiagramFlow:chatbot.PromptDiagramFlow,
        //             FlowImageURL:chatbot.FlowImageURL
        //         })
        //     })
        //         .then(response => {
        //             if (!response.ok) {
        //                 // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Update Team member Details' });
        //                 throw new Error('Network response was not ok');

        //             }
        //             return response.json();
        //         })
        //         .then(data => {
        //             // toast.current.show({ severity: 'success', summary: 'Success', detail: 'Team member details updated successfully' });
        //             // Handle the API response if needed
        //             console.log("data", data);
        //             fetchBotDataById(chatbot.Guid);
        //         })
        //         .catch(error => {
        //             // Handle errors here
        //             console.error('Error:', error);
        //             // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Update Team member Details' });
        //         });
        // } catch (error) {
        //     console.error('Error:', error);
        //     // toast.current.show({ severity: 'error', summary: 'Error', detail: error });
        // }
    };


    return (
        <div className="app-container">
            <Toast ref={toast} />
            <SidebarMenu />
            <div className="content">
                <div className="flex">
                    {/* //configurations=============== */}
                    <div className="card flex flex-column justify-content-start" style={{ flexBasis: '70%' }}>
                        <span>
                            <Button icon="pi pi-arrow-left" rounded outlined onClick={() => navigate('/chatbots')} severity="success" className="mb-2" />
                        </span>
                        <div className="card" style={{ width: '100%' }}>
                            <TabView activeIndex={activeIndex}>
                                <TabPanel header="Theme" leftIcon="pi pi-palette mr-2">
                                    {/* <div className="flex flex-column">
                                        <div className="border-2 border-dashed surface-border border-round flex-auto flex justify-content-center align-items-center font-medium">
                                            <div className="card flex justify-content-center">
                                                <Image src={image} zoomSrc={image} alt="Image" width="80" height="60" preview />
                                            </div>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }} // Hide the input
                                                ref={fileInputRef} // React ref to trigger click
                                                onChange={handleFileChange} // Function to handle file selection
                                                id="fileInput" // Optional: for label association
                                                name="Logo"
                                            />
                                            <Button
                                                label="Upload Logo"
                                                onClick={() => fileInputRef.current.click()} // Trigger the file input 
                                            />
                                            <ColorPicker inputId="cp-hex" format="hex" value={colorHEX} name="themeColor" onChange={(e) => setColorHEX(e.value)} className="mb-3" />
                                            <ColorPicker inputId="cp-hex" format="hex" value={form.themeColor} name="themeColor" onChange={handleChange} className="mb-3" />
                                        </div>

                                    </div> */}
                                    <div className="grid h-30rem">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }} // Hide the input
                                            ref={fileInputRef} // React ref to trigger click
                                            onChange={handleFileChange} // Function to handle file selection
                                            id="fileInput" // Optional: for label association
                                            name="Logo"
                                        />
                                        <div className="col-12 md:col-6 lg:col-3">

                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between align-items-center mb-3">
                                                    <div>
                                                        <span className="block text-700 font-medium mb-3">Upload Logo</span>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem', cursor: 'pointer' }} onClick={() => fileInputRef.current.click()}>

                                                        <i className="pi pi-upload text-blue-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Image src={image} zoomSrc={image} alt="Image" width="80" height="60" preview />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-12 md:col-6 lg:col-3">

                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between align-items-center mb-3">
                                                    <div>
                                                        <span className="block text-700 font-medium mb-3">Upload Favicon</span>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem', cursor: 'pointer' }} onClick={() => fileInputRef.current.click()}>

                                                        <i className="pi pi-upload text-blue-500 text-xl"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Image src={image} zoomSrc={image} alt="Image" width="80" height="60" preview />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-12 md:col-6 lg:col-3">
                                            <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                                                <div className="flex justify-content-between mb-3">
                                                    <div>
                                                        <span className="block text-700 font-medium mb-3">Choose theme color</span>
                                                        {/* <div className="text-900 font-medium text-xl">$2.100</div> */}
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center">
                                                        <ColorPicker inputId="cp-hex" format="hex" value={form.themeColor} name="themeColor" onChange={handleChange} className="mb-3" />
                                                    </div>
                                                </div>
                                                <span className="text-500"></span>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel header="Setup" leftIcon="pi pi-user ml-2">
                                    <div className="h-30rem">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="welcomeMessage">Welcome Message</label>
                                            <InputText
                                                id="welcomeMessage"
                                                aria-describedby="username-help"
                                                onChange={handleChange}
                                                name="welcomeMessage"
                                                placeholder="Enter your welcome message"
                                                value={form.welcomeMessage}
                                            // className="p-inputtext-lg p-d-block p-mb-3"
                                            />
                                        </div>
                                        <div className="flex flex-column gap-2" style={{ marginTop: '10px' }}>
                                            <label htmlFor="welcomeMessage">Fallback Message</label>
                                            <InputText
                                                id="welcomeMessage"
                                                aria-describedby="username-help"
                                                onChange={handleChange}
                                                name="fallbackMessage"
                                                placeholder="Enter your Fallback message"
                                                value={form.fallbackMessage}
                                            // className="p-inputtext-lg p-d-block p-mb-3"
                                            />
                                        </div>
                                        <div className="flex flex-column gap-2" style={{ marginTop: '10px' }}>
                                            <label htmlFor="botName">Name</label>
                                            <InputText
                                                id="botName"
                                                aria-describedby="username-help"
                                                onChange={handleChange}
                                                name="botName"
                                                placeholder="Enter your Bot Name"
                                                value={form.botName}
                                            // className="p-inputtext-lg p-d-block p-mb-3"
                                            />
                                        </div>
                                        <div className="flex flex-column gap-2" style={{ marginTop: '10px' }}>
                                            <label htmlFor="botName">Organization Name</label>
                                            <InputText
                                                id="orgname"
                                                aria-describedby="username-help"
                                                onChange={handleChange}
                                                name="orgname"
                                                placeholder="Enter your organization name"
                                                value={form.orgname}
                                            // className="p-inputtext-lg p-d-block p-mb-3"
                                            />
                                        </div>
                                        <div className="flex flex-column gap-2" style={{ marginTop: '10px' }}>
                                            <label htmlFor="botName">Organization Info</label>
                                            <InputText
                                                id="orginfo"
                                                aria-describedby="username-help"
                                                onChange={handleChange}
                                                name="orginfo"
                                                placeholder="Enter your Organization info"
                                                value={form.orginfo}
                                            // className="p-inputtext-lg p-d-block p-mb-3"
                                            />
                                        </div>
                                        <div className="flex flex-column gap-2" style={{ marginTop: '10px' }}>
                                            <label htmlFor="botName">Organization contact Info</label>
                                            <InputText
                                                id="orgcontactinfo"
                                                aria-describedby="username-help"
                                                onChange={handleChange}
                                                name="orgcontactinfo"
                                                placeholder="Enter your Organization contact Info"
                                                value={form.orgcontactinfo}
                                            // className="p-inputtext-lg p-d-block p-mb-3"
                                            />
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel header="Avatar" leftIcon="pi pi-user ml-2">
                                    <div className="h-30rem">
                                        <div className="grid pt-4">
                                            {AvatarImages.map((item, index) => (
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <Card
                                                        onClick={() => handleAvatarCardClick(index, item)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            border: selectedAvatar === index ? '2px solid #007ad9' : '1px solid #ddd',
                                                            borderRadius: '10px',
                                                            overflow: 'hidden',
                                                            boxShadow: selectedAvatar === index ? '0 0 10px rgba(0,0,0,0.2)' : '0 0 5px rgba(0,0,0,0.1)'
                                                        }}
                                                        className={selectedAvatar === index ? 'p-shadow-4' : ''}
                                                    >
                                                        <img src={item.AvatarImageURL} alt={`Card ${index + 1}`} style={{ width: '100%', height: '200px', objectFit: 'contain' }} />
                                                    </Card>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel header="Language" leftIcon="pi pi-language mr-2">
                                    <div>
                                        {/* <div className="card flex justify-content-center">
                                            <MultiSelect
                                                value={form.languages}
                                                // onChange={(e) => setselectedLanguage(e.value)} 
                                                onChange={handleChange}
                                                options={languages} optionLabel="name"
                                                placeholder="Select Languages"
                                                maxSelectedLabels={3}
                                                className="w-full md:w-20rem"
                                                name="languages"
                                            />
                                        </div> */}

                                        <div className="grid pt-4">
                                            {languages.map((item, index) => (
                                                <div className="col-12 md:col-6 lg:col-3" key={index}>
                                                    <Card
                                                        onClick={() => handleCardClickLanguage(index, item)}
                                                        style={{
                                                            position: 'relative',
                                                            cursor: 'pointer',
                                                            border: form?.languages?.some(option => option.name === item.name) ? '2px solid #007ad9' : '1px solid #ddd',
                                                            borderRadius: '10px',
                                                            overflow: 'hidden',
                                                            boxShadow: form?.languages?.some(option => option.name === item.name) ? '0 0 5px rgba(0,0,0,0.2)' : '0 0 5px rgba(0,0,0,0.1)',
                                                            textAlign: 'center'
                                                        }}
                                                        className={form?.languages?.some(option => option.name === item.name) ? 'p-shadow-4' : ''}
                                                    >
                                                        <h3>{item.name}</h3>
                                                        <h3>{item.sub_name}</h3>
                                                        {/* {form.languages.some(option => option.name === item.name) && (
                                                            <Badge
                                                                value={<i class="pi pi-check"></i>}
                                                                severity="success"
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '10px',
                                                                    right: '10px',
                                                                    width: '30px',
                                                                    height: '30px',
                                                                    borderRadius: '50%',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    fontSize: '1em'
                                                                }}
                                                            />
                                                        )} */}
                                                    </Card>
                                                </div>
                                            ))}
                                        </div>

                                        {/* <div className="grid pt-4">
                                            {languages.map((item, index) => (
                                                <div className="col-12 md:col-6 lg:col-3" key={index}>
                                                    <Card
                                                        onClick={() => handleCardClickLanguage(index, item)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            position: 'relative',
                                                            border: '1px solid #ddd',
                                                            borderRadius: '10px',
                                                            overflow: 'hidden',
                                                            boxShadow: '0 0 5px rgba(0,0,0,0.1)',
                                                            textAlign: 'center'
                                                        }}
                                                        className={form.languages.some(option => option.name === item.name) ? 'p-shadow-4' : ''}
                                                    >
                                                        <h3>{item.name}</h3>
                                                        {form.languages.some(option => option.name === item.name) && (
                                                            <Badge
                                                                value={<i class="pi pi-check"></i>}
                                                                severity="success"
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '10px',
                                                                    right: '10px',
                                                                    width: '30px',
                                                                    height: '30px',
                                                                    borderRadius: '50%',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    fontSize: '1em'
                                                                }}
                                                            />
                                                        )}
                                                    </Card>
                                                </div>
                                            ))}
                                        </div> */}

                                    </div>
                                </TabPanel>

                                <TabPanel header="Chat type" leftIcon="pi pi-comment mr-2">
                                    <div className="h-30rem">
                                        {/* <div className="card flex justify-content-center">
                                            <MultiSelect
                                                value={form.chatTypeOptions}
                                                // onChange={(e) => setselectedChatType(e.value)} 
                                                onChange={handleChange}
                                                options={ChatType}
                                                optionLabel="name"
                                                placeholder="Select Chat Type"
                                                maxSelectedLabels={3}
                                                className="w-full md:w-20rem"
                                                name="chatTypeOptions"
                                            />
                                        </div> */}

                                        <div className="grid pt-4">
                                            {ChatType.map((item, index) => (
                                                <div className="col-12 md:col-6 lg:col-3" key={index}>
                                                    <Card
                                                        onClick={() => handleCardClickChattype(index, item)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            border: form?.chatTypeOptions?.some(option => option.name === item.name) ? '2px solid #007ad9' : '1px solid #ddd',
                                                            borderRadius: '10px',
                                                            overflow: 'hidden',
                                                            boxShadow: form?.chatTypeOptions?.some(option => option.name === item.name) ? '0 0 5px rgba(0,0,0,0.2)' : '0 0 5px rgba(0,0,0,0.1)',
                                                            textAlign: 'center'
                                                        }}
                                                        className={form?.chatTypeOptions?.some(option => option.name === item.name) ? 'p-shadow-4' : ''}
                                                    >
                                                        <h3>{item.name}</h3>
                                                    </Card>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel header="LLM" leftIcon="pi pi-cog mr-2">
                                    <div className="h-30rem">
                                        <div className="grid pt-4">
                                            {LLMModel.map((name, index) => (
                                                <div className="col-12 md:col-6 lg:col-3">
                                                    <Card
                                                        onClick={() => handleCardClickForLLM(index, name)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            border: selectedLLMM === index ? '2px solid #007ad9' : '1px solid #ddd',
                                                            borderRadius: '10px',
                                                            overflow: 'hidden',
                                                            boxShadow: selectedLLMM === index ? '0 0 5px rgba(0,0,0,0.2)' : '0 0 5px rgba(0,0,0,0.1)',
                                                            textAlign: 'center'
                                                        }}
                                                        className={selectedLLMM === index ? 'p-shadow-4' : ''}
                                                    >
                                                        <h3>{name}</h3>
                                                    </Card>


                                                </div>
                                            ))}

                                            {/* {LLMModel.map((name, index) => (
                                                <div className="col-12 md:col-6 lg:col-3" key={index}>
                                                    <Card
                                                        onClick={() => handleCardClickForLLM(index, name)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            border: form.llmModel.includes(name) ? '2px solid #007ad9' : '1px solid #ddd',
                                                            borderRadius: '10px',
                                                            overflow: 'hidden',
                                                            boxShadow: form.llmModel.includes(name) ? '0 0 10px rgba(0,0,0,0.2)' : '0 0 5px rgba(0,0,0,0.1)',
                                                            textAlign: 'center'
                                                        }}
                                                        className={form.llmModel.includes(name) ? 'p-shadow-4' : ''}
                                                    >
                                                        <h3>{name}</h3>
                                                    </Card>
                                                </div>
                                            ))} */}
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel header="Repository" leftIcon="pi pi-cog mr-2">
                                    <div className="h-30rem">
                                        <div className="grid pt-4">
                                            {Repos.length === 0 ? (
                                                <div className="col-12">
                                                    <Card
                                                        style={{
                                                            textAlign: 'center',
                                                            padding: '20px',
                                                            border: '1px solid #ddd',
                                                            borderRadius: '10px',
                                                            boxShadow: '0 0 5px rgba(0,0,0,0.1)'
                                                        }}
                                                    >
                                                        <h3>Repo not found</h3>
                                                        <Link to={`/repos`}>
                                                            create repo
                                                        </Link>
                                                    </Card>
                                                </div>
                                            ) : (
                                                Repos.map((item, index) => (
                                                    <div className="col-12 md:col-6 lg:col-3" key={index}>
                                                        <Card
                                                            onClick={() => handleCardClickForRepo(index, item)}
                                                            style={{
                                                                cursor: 'pointer',
                                                                border: selectedRepo === index ? '2px solid #007ad9' : '1px solid #ddd',
                                                                borderRadius: '10px',
                                                                overflow: 'hidden',
                                                                boxShadow: selectedRepo === index ? '0 0 10px rgba(0,0,0,0.2)' : '0 0 5px rgba(0,0,0,0.1)',
                                                                textAlign: 'center'
                                                            }}
                                                            className={selectedRepo === index ? 'p-shadow-4' : ''}
                                                        >
                                                            <h3>{item.RepositoryName}</h3>
                                                        </Card>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel header="Integrations" leftIcon="pi pi-mobile mr-2">
                                    <div className="h-30rem">
                                        <div className="grid pt-4">
                                            {integrations.length === 0 ? (
                                                <div className="col-12">
                                                    <Card
                                                        style={{
                                                            textAlign: 'center',
                                                            padding: '20px',
                                                            border: '1px solid #ddd',
                                                            borderRadius: '10px',
                                                            boxShadow: '0 0 5px rgba(0,0,0,0.1)'
                                                        }}
                                                    >
                                                        <h3>Integrations not found</h3>
                                                        <Link to={`/integrations`}>
                                                            create Integrations
                                                        </Link>
                                                    </Card>
                                                </div>
                                            ) : (

                                                integrations.map((item, index) => (
                                                    <div className="col-12 md:col-6 lg:col-3" key={index}>
                                                        <Card
                                                            onClick={() => handleIntegrationsClick(index, item)}
                                                            style={{
                                                                cursor: 'pointer',
                                                                border: form?.IntegrationId?.some(option => option === item.Id) ? '2px solid #007ad9' : '1px solid #ddd',
                                                                borderRadius: '10px',
                                                                overflow: 'hidden',
                                                                boxShadow: form?.IntegrationId?.some(option => option === item.Id) ? '0 0 5px rgba(0,0,0,0.2)' : '0 0 5px rgba(0,0,0,0.1)',
                                                                textAlign: 'center'
                                                            }}
                                                            className={form?.IntegrationId?.some(option => option === item.Id) ? 'p-shadow-4' : ''}
                                                        >
                                                            <h3>{item.Name}</h3>
                                                        </Card>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </TabPanel>

                                <TabPanel header="flow" leftIcon="pi pi-cog mr-2">
                                    {/* <div className="h-30rem"> */}
                                    <div className="grid pt-1 gap-2">
                                        <label htmlFor="flow"><h3>Provide instructions for how the chatbot should respond to users. Include key information like role, objective, and style to customize the chatbot.</h3></label>
                                        <InputTextarea id="question" rows={5} autoResize style={{ width: '100%' }} name="Template" value={form.Template} onChange={handleChange} />
                                    </div>

                                    <div className="">
                                        <label htmlFor="PromptDiagramFlow"><h3>Flow Diagram.</h3></label>
                                        {/* <InputTextarea id="question" rows={5} autoResize style={{ width: '100%' }} name="PromptDiagramFlow" value={form.PromptDiagramFlow} onChange={handleChange} /> */}
                                        <Image src={chatbot.FlowImageURL} zoomSrc={image} alt="Image" width="80" height="60" preview />

                                    </div>
                                    {/* </div> */}


                                    <div style={{ width: '100%', display: 'flex', justifyContent: "end", textAlign: 'end', marginTop: '10px' }} >
                                        <Button style={{ cursor: 'pointer' }} onClick={() => setvisibleFlowPopUp(true)} >Flow Diagram</Button>
                                    </div>
                                </TabPanel>
                            </TabView>
                        </div>
                        {/* <div style={{ width: '100%', display: 'flex', justifyContent: "end", textAlign: 'end' }} className="text-align-end"> */}
                        <Button style={{
                            display: 'block',
                            textAlign: 'center'
                        }} onClick={UpdateBotConfig}>Save changes</Button>
                        {/* </div> */}
                    </div>

                    {/* //Preview ====== */}
                    <div className="card flex justify-content-start" style={{ flexBasis: '30%' }}>

                        <div className="flex flex-column h-full" style={{ flexBasis: '100%' }}>
                            {/* <div className="border-2 border-dashed surface-border border-round surface-ground"> */}
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                <div style={{
                                    // display: showchat, 
                                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.3)', borderRadius: '10px'
                                }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: "center",
                                            justifyContent: 'space-between',
                                            padding: '10px',
                                            borderBottom: '1px solid #ccc',
                                            background: `#${form.themeColor}`,
                                            borderTopLeftRadius: '10px',
                                            borderTopRightRadius: '10px'
                                        }}
                                    >
                                        <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', maxWidth: '380px', }}>
                                            <img src={form.Logo === null || form.Logo === "" ? defaultLogo : form.Logo} alt="chat logo"
                                                style={{
                                                    width: '30px',
                                                    // borderRadius: '50%', 
                                                    // boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', 
                                                    marginRight: "10px"
                                                }} />
                                            <div>
                                                <h2 style={{ margin: 0, color: "white" }}>{form.botName}</h2>
                                                <p style={{ margin: 0, color: "white" }}>Personal Digital Assistant</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            height: "440px",
                                            maxWidth: '380px',
                                            // overflowY: "scroll",
                                            // scrollbarColor: "#ccc #f1f1f1",
                                            // scrollbarWidth: "none",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '94%',
                                            height: '200px', // Add height to define the container size
                                            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
                                            borderRadius: '10px',
                                            overflow: 'hidden', // Ensure that the image does not overflow the container
                                            margin: '10px'
                                        }}>
                                            <img src={CurrentAvaataar} alt="chat logo" style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain'
                                            }} />
                                        </div>

                                        {messages.map((message, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    marginTop: '10px',
                                                    padding: "5px",
                                                    textAlign: message.sender === "bot" ? "left" : "right",
                                                    width: "360px"
                                                }}
                                            >
                                                <div style={message.sender === "bot" ? { display: "flex", alignItems: "end" } : {}}>
                                                    <span>{message.sender === "bot" ? <img src={form.Logo === null || form.Logo === "" ? defaultLogo : form.Logo} alt="chat logo" style={{ width: '20px', marginRight: "10px" }} /> : null}</span>
                                                    <div>
                                                        <div style={{ textAlign: message.sender === "bot" ? "left" : "right", marginBottom: '5px', color: '#888', fontSize: '0.8em' }}>
                                                            {message.sender === "bot" ? `${form.botName}` : new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                        </div>
                                                        <span
                                                            style={{
                                                                backgroundColor: message.sender === "bot" ? "white" : "#E0E0E0",
                                                                color: message.sender === "bot" ? "#000" : "#000",
                                                                padding: "0px 2px 0px 2px",
                                                                borderTopLeftRadius: message.sender === "bot" ? 0 : "15px",
                                                                borderTopRightRadius: message.sender === "bot" ? "15px" : 0,
                                                                borderBottomRightRadius: "15px",
                                                                borderBottomLeftRadius: "15px",
                                                                border: message.sender === "bot" ? `2px solid #${form.themeColor}` : "none",
                                                                boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
                                                                wordWrap: "break-word",
                                                                maxWidth: "80%",
                                                                display: "inline-block",
                                                                overflowWrap: "break-word",
                                                                textAlign: message.sender === "bot" ? "left" : "left",
                                                            }}
                                                        >
                                                            {<div dangerouslySetInnerHTML={{ __html: message.text }} />}
                                                            {message.sender === "bot" && message.source && (
                                                                <p style={{ color: "blue", cursor: "pointer" }}
                                                                    onClick={() => console.log("hello")}>
                                                                    Read more...
                                                                </p>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div ref={messagesEndRef} />
                                    </div>
                                    <form onSubmit={() => console.log("submit")} style={{ padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                                        {isListening ?
                                            <span style={{ cursor: 'pointer' }} onClick={toggleListen}>
                                                <i class="bi bi-mic-fill" style={{ fontSize: '2em', color: `#${form.themeColor}` }}></i>
                                            </span>
                                            : <span style={{ cursor: 'pointer' }} onClick={toggleListen} >
                                                <i class="bi bi-mic-mute-fill" style={{ fontSize: '2em', color: `#${form.themeColor}` }}></i>
                                            </span>
                                        }
                                        <input
                                            style={{ border: 'none', boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', borderRadius: '20px', padding: '10px', marginRight: '10px', width: 'calc(100% - 40px)', marginLeft: '10px' }}
                                            type="text"
                                            value={input}
                                            onChange={(e) => setInput(e.target.value)}
                                            placeholder={isListening ? "Listening..." : "Type your message..."}
                                            disabled
                                        />

                                        <button type="submit" style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
                                            <i class="bi bi-send" style={{ fontSize: '1.5em' }}></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog header="" visible={visibleFlowPopUp} style={{ width: '30vw' }} onHide={() => setvisibleFlowPopUp(false)}>
                {loading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <ProgressSpinner />
                        <p>Uploading...</p>
                    </div>
                )}
                {loading ? null : (
                    <div>
                        <div className="p-fluid mt-3">

                            <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }} // Hide the input
                                ref={FlowfileInputRef} // React ref to trigger click
                                onChange={handleflowFileChange} // Function to handle file selection
                                id="fileInput" // Optional: for label association
                                name="Logo"
                            />
                            <div className="p-d-flex p-jc-end">
                                <Button label="Upload flow Diagram" icon="pi pi-upload" onClick={() => FlowfileInputRef.current.click()} />
                            </div>
                        </div>
                        <div className="p-fluid">
                            <div className="divider">
                                <div className="line"></div>
                                <div className="or">OR</div>
                                <div className="line"></div>
                            </div>
                            <div className="p-d-flex p-jc-end">
                                <Button label="Create Flow Diagram" icon="pi pi-arrow-right" severity="success" rounded
                                    onClick={() => {
                                        navigate('/flow', { state: { chatbot } })
                                    }} />
                            </div>
                        </div>
                    </div>
                )}
            </Dialog>

        </div>
    )
}

export default BotStudio;











