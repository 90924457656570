import React from "react";

const Terms = () => {
    return (
        <div>
            <h3>Terms and Conditions</h3>
            <p>By signing up, you agree to the terms and conditions of Dynapt Max.</p>
            <p>1. You agree to use the services provided by Dynapt Max for legal purposes only.</p>
            <p>2. You agree to not use the services provided by Dynapt Max for any illegal activities.</p>
            <p>3. You agree to not use the services provided by Dynapt Max for any activities that may harm the reputation of Dynapt Max.</p>
            <p>4. You agree to not use the services provided by Dynapt Max for any activities that may harm the reputation of any other individual or organization.</p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut justo sem, scelerisque ac eros et, imperdiet placerat tortor. Suspendisse vitae metus lectus. 
            Donec viverra tristique dolor. Sed posuere mollis ex vitae tincidunt. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. 
            Vivamus vehicula tellus non aliquam blandit. Fusce sollicitudin tortor dolor, sed tempus tortor pellentesque et. Aliquam eu ligula nibh. Phasellus eros neque, luctus id sollicitudin sed, fringilla eu odio.
            Curabitur at ullamcorper justo. Vestibulum laoreet odio id mi dignissim, ut interdum felis interdum.
            Aliquam sodales tempus porttitor. Pellentesque sed risus quam. Curabitur lacinia magna non eros pharetra, eget dictum sapien maximus. Aliquam erat volutpat. Nunc a laoreet mauris, eget blandit lacus. Nulla a tempor ante. Donec tortor erat, tincidunt eu iaculis ac, lobortis maximus est.

            Integer ultricies, risus in aliquam efficitur, arcu risus scelerisque purus, non ullamcorper erat velit a mi. Proin interdum nisl augue, id mollis urna fringilla et. Nulla venenatis dignissim ipsum. Etiam pharetra maximus ligula id eleifend. Mauris non cursus ligula. Nunc eget augue eu diam pulvinar tempus a nec elit. Suspendisse vel magna suscipit risus congue tempus. Aenean ipsum turpis, posuere ac enim et, scelerisque pretium ante.

            Ut eu purus eget ligula volutpat fringilla. Vestibulum tempor mauris sem, eu suscipit velit tristique ut. Nunc at erat sit amet mi ultricies bibendum. Nullam hendrerit vitae quam at aliquam. Sed eu metus et mauris porta pretium. Fusce nunc nulla, imperdiet vitae cursus sit amet, gravida sit amet lectus. Integer dictum tellus eros, ut elementum mi posuere rutrum. Curabitur in facilisis diam, at consectetur ipsum.

            Phasellus eu scelerisque odio. Nullam justo tellus, lacinia eu nunc vitae, finibus blandit risus. Suspendisse auctor malesuada lorem, ac facilisis tortor efficitur at. Mauris consequat, nisi non scelerisque semper, dui nisi gravida mi, a luctus turpis sapien ut nibh. Morbi sapien dolor, suscipit eget rutrum in, mollis quis elit. Nulla erat urna, tincidunt at blandit vitae, porta id felis. Nullam fermentum scelerisque dolor vitae ultrices.
                    
        </div>
    );
    }

export default Terms;