import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from "../auth/authContext";


export default function Protected(props) {
    const { Component } = props
    const navigate = useNavigate();
    const { userLoggedIn } = useAuth();

    useEffect(() => {
        if (!userLoggedIn) {
            console.log("===============")
            navigate('/');
          }
    }, [])
    return (
        <div>
        {userLoggedIn && (
            <div>
                <Component />
            </div>
        )}
        </div>
    )
}
