import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import SidebarMenu from "./sidebar";
import { Toast } from "primereact/toast";
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { BASE_URL } from "../utils/config";
import { useAuth } from "../auth/authContext";
import { confirmDialog } from 'primereact/confirmdialog';

const Repos = () => {
    const toast = useRef(null);
    const navigate = useNavigate();
    const { userDetails, userPackage } = useAuth();
    const [repos, setRepos] = useState([]);
    const [repoName, setRepoName] = useState("");
    const [repoType, setRepoType] = useState("");
    const [dialogVisible, setDialogVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentRepoId, setCurrentRepoId] = useState(null);

    const [bucketName, setBucketName] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    const getRandomLightColor = () => {
        const r = Math.floor(Math.random() * 56) + 200;
        const g = Math.floor(Math.random() * 56) + 200;
        const b = Math.floor(Math.random() * 56) + 200;
        return `rgb(${r},${g},${b})`;
    };
    const repoTypes = [
        { label: 'FAQs', value: 'faqs' },
        { label: 'Documents', value: 'documents' },
        { label: 'Web Links', value: 'weblinks' },
    ];

    useEffect(() => {
        fetchRepos();
        localStorage.removeItem('userRepo');
    }, []);

    useEffect(() => {
        initialFunction();
    }, []);

    const initialFunction = async () => {
        if (!accessToken) {
            const newToken = await getAccessToken();
            setAccessToken(newToken);
        }
    }

    useEffect(() => {
        initialBucketCreation();
    }, [accessToken]);

    const initialBucketCreation = async () => {
        try {
            const newBucketName = userDetails.FirstName.toLowerCase() + userDetails.UserId;
            const bucketExists = await checkBucketExists(newBucketName);
            if (!bucketExists) {
                const bucket = await createNewBucket();
                await CreateDatabase();
                setBucketName(bucket);
            } else {
                // await CreateDatabase();
                setBucketName(newBucketName);
            }
        } catch (error) {
            console.error('Error during initial bucket creation:', error);
        }
    };

    const createNewBucket = async () => {
        if (!accessToken) {
            console.log('No access token');
            return;
        }

        const url = 'https://storage.googleapis.com/storage/v1/b';
        const params = new URLSearchParams({ 'project': 'max-prod-417007' });
        const headers = {
            'Authorization': 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
        };
        const data = {
            "name": userDetails.FirstName.toLowerCase() + userDetails.UserId,
            "location": "ASIA-SOUTH1",
            "storageClass": "STANDARD",
            "iamConfiguration": {
                "uniformBucketLevelAccess": {
                    "enabled": true
                }
            }
        };

        try {
            const response = await fetch(`${url}?${params.toString()}`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error:', errorData);
            } else {
                const responseData = await response.json();
                console.log('Success:', responseData);
                return responseData.name;
            }
        } catch (error) {
            console.error('Error creating bucket:', error);
        }
    };
    const checkBucketExists = async (bucketName) => {
        const url = `https://storage.googleapis.com/storage/v1/b/${bucketName}`;
        const headers = {
            'Authorization': `Bearer ${accessToken}`,
        };

        try {
            const response = await fetch(url, { method: 'GET', headers });
            if (response.ok) {
                console.log(`Bucket ${bucketName} exists`);
                return true;
            } else if (response.status === 404) {
                console.log(`Bucket ${bucketName} does not exist`);
                return false;
            } else {
                throw new Error(`Failed to check bucket: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error checking bucket existence:', error);
            return false;
        }
    };

    const getAccessToken = async () => {
        try {
            const response = await fetch(
                "https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/get_google_auth_access_token",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.log("error while generating access token ");
        }
    };

    const fetchRepos = async () => {
        try {
            if (!userDetails) {
                console.error('User details not available');
                setRepos([]);
                return;
            }

            if (!BASE_URL) {
                console.error('BASE_URL not defined in the environment');
                setRepos([]);
                return;
            }

            const response = await fetch(`${BASE_URL}/team_details/get_repository_details_by_UserId?UserId=${userDetails.UserId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
            });

            if (!response.ok) {
                setRepos([]);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Network response was not ok' });
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setRepos(data);
        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch Repos' });
        }
    };

    const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
    
    const openDialog = () => {
        const reposLength = repos.length;
        console.log(reposLength);

        if (userPackage === 'Free') { 
            setShowUpgradeDialog(true);
            return;
        } else if (userPackage === 'Basic' && reposLength >= 5) {
            setShowUpgradeDialog(true);
            return;
        } else if (userPackage === 'Advanced') {
            // No restriction for Advanced package
        }

        setRepoName("");
        setRepoType("");
        setEditMode(false);
        setDialogVisible(true);
    };

    const isValidName = (name) => {
        const NAMEDATALEN = 32; // By default
        const maxNameLength = NAMEDATALEN - 1;
        const regexUnquoted = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
        const regexQuoted = /^"[^"]+"$/;

        // Check length
        if (name.length > maxNameLength) {
            return false;
        }

        // Check unquoted names
        if (regexUnquoted.test(name)) {
            return true;
        }

        // Check quoted names
        if (regexQuoted.test(name)) {
            return true;
        }

        return false;
    };

    const saveRepo = async () => {
        if (!isValidName(repoName)) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Name cannot be empty, must start with a letter or underscore, cannot exceed 32 characters, and can only contain letters, digits, and underscores.' });
            return;
        }

        if (repoName.trim()) {
            const repoExists = repos.some(repo => repo.RepositoryName.toLowerCase() === repoName.trim().toLowerCase());
            if (repoExists) {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Repository name already exists' });
                return;
            }


            try {
                const url = editMode ? `${BASE_URL}/update_repository?repository_id=${currentRepoId}` : `${BASE_URL}/create_repository`;
                // const method = 'POST';
                const body = JSON.stringify({ RepositoryName: repoName, UserId: userDetails.UserId });
                // const headers = {
                //     'Content-Type': 'application/json',
                //     'accept': 'application/json'
                // };

                // const response = await fetch(url, { method, headers, body });

                // if (!response.ok) {
                //     toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to save repository' });
                //     throw new Error('Failed to save repository');
                // }

                // const Jsonresponse = response.json()
                // console.log("Jsonresponse===>", Jsonresponse);

                // if (editMode === false) {
                //     await CreateTable(Jsonresponse.Id)
                // }

                // toast.current.show({ severity: 'success', summary: 'Success', detail: `Repository ${editMode ? 'updated' : 'added'}`, life: 3000 });

                // setDialogVisible(false);
                // fetchRepos();

                await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': 'application/json'
                    },
                    body: body
                })
                    .then(response => {
                        if (!response.ok) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to save repository' });
                            throw new Error('Failed to save repository');
                        }
                        return response.json();
                    })
                    .then(data => {
                        // if (editMode === false) {
                        //     await CreateTable(data.Id)
                        // }
                        // CreateDatabase();
                        toast.current.show({ severity: 'success', summary: 'Success', detail: `Repository ${editMode ? 'updated' : 'added'}`, life: 3000 });

                        setDialogVisible(false);
                        fetchRepos();
                    })
                // .catch(error => {
                //     // Handle errors here
                //     console.error('Error:', error);
                //     toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to save repository' });
                // });
            } catch (error) {
                console.error('Error:', error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to save repository' });
            }
        }
    };

    const editRepo = (repo) => {
        setRepoName(repo.name);
        setRepoType(repo.type);
        setCurrentRepoId(repo.Id);
        setEditMode(true);
        setDialogVisible(true);
    };

    const deleteRepo = async (repoId) => {
        try {
            const response = await fetch(`${BASE_URL}/delete_repository?Repository_id=${repoId}`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json'
                }
            });

            if (!response.ok) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete repository' });
                throw new Error('Failed to delete repository');
            }

            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Repository deleted', life: 3000 });
            fetchRepos();
        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete repository' });
        }
    };

    const reject = () => {
        // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const confirm = (id) => {
        confirmDialog({
            message: 'Are you sure you want to delete this Repository?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => deleteRepo(id),
            reject
        });
    };

    const handleRepoClick = (repo, type) => {
       // setUserRepo(repo);
        localStorage.setItem('userRepo',JSON.stringify(repo));
        switch (type) {
            case 'faqs':
                navigate("/faqs");
                break;
            case 'documents':
                navigate("/documents");
                break;
            case 'weblinks':
                navigate("/weblinks");
                break;
            default:
                break;
        }
    };

    const CreateDatabase = async () => {
        try {
            const projectId = process.env.REACT_APP_PROJECT_ID;
            const dbInstanceName = process.env.REACT_APP_DB_INSTANCE_NAME;
            console.log("accessToken:===>", accessToken);

            const url = `https://sqladmin.googleapis.com/v1/projects/${projectId}/instances/${dbInstanceName}/databases`;
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            };
            const data = {
                project: projectId,
                instance: dbInstanceName,
                name: `${userDetails.FirstName.toLowerCase()}${userDetails.UserId}`,
            };

            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const result = await response.json();
            console.log("====db created susessfully", result);
            //   setResponse(result);
            //   setError(null);
        } catch (error) {
            console.log("error==========================>", error)
            //   setResponse(null);
            //   setError(error.message);
        }
    };

    const CreateTable = async (repoId) => {
        console.log("repoId=====>", repoId);
        const url = 'http://34.100.159.235:5000/table_create';
        const headers = {
            'Content-Type': 'application/json',
        };
        const data = {
            user_name: `${userDetails.FirstName.toLowerCase()}${userDetails.UserId}`,
            repository_name: repoId,
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const result = await response.json();
        } catch (error) {
            console.log("failled to create table====>", error)
        }
    };

    return (
        <div className="app-container">
            <Toast ref={toast} />
            <SidebarMenu />
            <div className="content">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    {repos.length === 0 ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '150px',
                                width: '150px',
                                cursor: 'pointer',
                                border: '1px solid #ccc',
                                borderRadius: '8px'
                            }}
                            onClick={openDialog}
                        >
                            <i className="pi pi-plus" style={{ fontSize: '2em' }}></i>
                        </div>
                    ) : (
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                            gap: '16px',
                            width: '80%',
                            margin: 'auto'
                        }}>


                            {repos.map((repo, index) => (
                                <Card
                                    key={index}
                                    title={repo.RepositoryName}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '16px',
                                        cursor: 'pointer',
                                        border: '1px solid #ccc',
                                        borderRadius: '8px',
                                        textAlign: 'center',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        position: 'relative',
                                        backgroundColor: getRandomLightColor(),
                                    }}
                                >
                                    <Button
                                        icon="pi pi-pencil"
                                        style={{
                                            position: 'absolute',
                                            top: '6px',
                                            right: '32px',
                                            backgroundColor: 'transparent',
                                            borderColor: 'transparent',
                                            color: '#000',
                                            outline: 'none',
                                            boxShadow: 'none'
                                        }}
                                        onClick={() => editRepo(repo)}
                                    />
                                    <Button
                                        icon="pi pi-trash"
                                        style={{
                                            backgroundColor: 'transparent',
                                            borderColor: 'transparent',
                                            position: 'absolute',
                                            top: '6px',
                                            right: '8px',
                                            color: '#000',
                                            outline: 'none',
                                            boxShadow: 'none'
                                        }}
                                        onClick={() => confirm(repo.Id)}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                        <div style={{ flex: 1, textAlign: 'left' }}>
                                            <span
                                                onClick={() => handleRepoClick(repo, 'faqs')}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#000',
                                                    transition: 'color 0.3s',
                                                    display: 'block',
                                                    marginBottom: '15px',
                                                }}
                                                onMouseEnter={(e) => e.target.style.color = '#007bff'}
                                                onMouseLeave={(e) => e.target.style.color = '#000'}
                                            >
                                                FAQs
                                            </span>
                                            <span
                                                onClick={() => handleRepoClick(repo, 'documents')}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#000',
                                                    transition: 'color 0.3s',
                                                    display: 'block',
                                                    marginBottom: '15px',
                                                }}
                                                onMouseEnter={(e) => e.target.style.color = '#007bff'}
                                                onMouseLeave={(e) => e.target.style.color = '#000'}
                                            >
                                                Documents
                                            </span>
                                            <span
                                                onClick={() => handleRepoClick(repo, 'weblinks')}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#000',
                                                    transition: 'color 0.3s',
                                                    display: 'block',
                                                    marginBottom: '15px',
                                                }}
                                                onMouseEnter={(e) => e.target.style.color = '#007bff'}
                                                onMouseLeave={(e) => e.target.style.color = '#000'}
                                            >
                                                WebLinks
                                            </span>
                                        </div>
                                    </div>
                                </Card>
                            ))}

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '150px',
                                    cursor: 'pointer',
                                    border: '1px solid #ccc',
                                    borderRadius: '8px',
                                    textAlign: 'center',
                                }}
                                onClick={openDialog}
                            >
                                <i className="pi pi-plus" style={{ fontSize: '2em' }}></i>
                            </div>
                        </div>
                    )}
                </div>

                <Dialog header={editMode ? "Edit Repository" : "Add Repository"} visible={dialogVisible} style={{ width: '50vw', padding: '2rem' }} onHide={() => setDialogVisible(false)}>
                    <div className="p-fluid">
                        <div className="p-field p-grid" style={{ marginBottom: '1.5rem' }}>
                            <label htmlFor="name" className="p-col-12 p-md-3">Repository Name</label>
                            <div className="p-col-12 p-md-9">
                                <InputText id="name" value={repoName} onChange={(e) => setRepoName(e.target.value)} className="p-inputtext-lg" />
                            </div>
                        </div>
                        {/* <div className="p-field p-grid" style={{ marginBottom: '1.5rem' }}>
                        <label htmlFor="type" className="p-col-12 p-md-3">Repository Type</label>
                        <div className="p-col-12 p-md-9">
                            <Dropdown id="type" value={repoType} options={repoTypes} onChange={(e) => setRepoType(e.value)} placeholder="Select a Type" className="p-dropdown-lg" />
                        </div>
                    </div> */}
                        <div className="p-field p-grid" style={{ marginBottom: '1.5rem' }}>
                            <div className="p-col-12 p-md-offset-3 p-md-9">
                                <Button label="Save" icon="pi pi-check" onClick={saveRepo} className="p-button-lg" />
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Upgrade Required" visible={showUpgradeDialog} style={{ width: '50vw' }} modal onHide={() => setShowUpgradeDialog(false)}>
                <div className="p-grid">
                    <div className="p-col-12">
                        <p>Please upgrade your plan to add more team members.</p>
                    </div>
                    <div className="p-col-12">
                        <button onClick={()=>{ navigate('/subscription')}} className="p-button p-component">
                            Upgrade
                        </button>
                    </div>
                </div>
                </Dialog>

            </div>
        </div>
    );
};

export default Repos;
