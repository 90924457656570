import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { useAuth } from "../auth/authContext";
import { InputTextarea } from 'primereact/inputtextarea';
import { doSignOut } from "../auth/firebase/auth";
import { useNavigate } from "react-router-dom";
import SidebarMenu from "./sidebar";
import { Dialog } from 'primereact/dialog';
import { BASE_URL } from '../utils/config'
import { Toast } from 'primereact/toast';

import { confirmDialog } from 'primereact/confirmdialog';

const FAQ = () => {
    let navigate = useNavigate();
    const toast = React.useRef(null);
    const { userDetails } = useAuth();
    const [globalFilter, setGlobalFilter] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleAdd, setvisibleAdd] = useState(false);
    const [visibleUpdate, setvisibleUpdate] = useState(false);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [CurrentFaqID, setCurrentFaqID] = useState();
    const [FAQs, setFAQs] = useState([]);
    const rowsPerPage = 8;

    const userRepo = JSON.parse(localStorage.getItem('userRepo'));

    useEffect(()=>{
        if(!userRepo){
            navigate("/repos");
        }
    },[]);
    
    //console.log(userRepo);

    let count = 0;
    useEffect(() => {
        // Fetch FAQs from the API
        if (count === 0 && userRepo) {
            count = count + 1
            fetchFAQs();
        }
    }, []); // Empty dependency array ensures this effect runs only once after initial render

    const fetchFAQs = async () => {
        try {
            if (!userDetails) {
                console.error('User details not available');
                setFAQs([]);
                return;
            }

            if (!BASE_URL) {
                console.error('BASE_URL not defined in the environment');
                setFAQs([]);
                return;
            }

           // console.log("UserID=========>", userDetails.UserId);
            const response = await fetch(`${BASE_URL}/team_details/get_FAQ_by_UserId?UserId=${userDetails.UserId}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json'
                },
            });

            if (!response.ok) {
                setFAQs([]);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Network response was not ok' });
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // console.log("FAQDATA===============>", data);
            const filteredFAQs = data.filter(faq => faq.RepositoryId === userRepo.Id); 
            setFAQs(filteredFAQs);
        } catch (error) {                                                             
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch FAQs' });
            setFAQs([]);
        }
    };

    const onGlobalFilterChange = (e) => {
        setGlobalFilter(e.target.value);
        setCurrentPage(1);  // Reset to first page when filter changes
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end align-items-center ">
                <Button label="Add" outlined severity="success" style={{ marginRight: "5px" }} onClick={() => {
                    setvisibleAdd(true);
                    setAnswer("");
                    setQuestion("");
                }} 
                tooltip="Add new FAQs" tooltipOptions={{ position: 'left' }}
                />
                <IconField iconPosition="right">
                    <InputIcon className="pi pi-search" />
                    <InputText
                        value={globalFilter}
                        onChange={onGlobalFilterChange}
                        placeholder="Search..."
                    />
                </IconField>
            </div>
        );
    };

    const CreateFAQs = async () => {
        try {
            if (answer.trim() === "" || question.trim() === "") {
                console.error(`Question and Answer Can't be empty`);
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: `Question and Answer Can't be empty` });
                return;
            }

            const newFAQ = {
                Answer: answer,
                Question: question,
                RepositoryId: userRepo.Id, 
                UserId: userDetails.UserId
            };

            const response = await fetch(`${BASE_URL}/create_FAQ`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify(newFAQ)
            });

            if (!response.ok) {
                console.error('Failed to create FAQ');
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to create FAQ' });
                throw new Error('Failed to create FAQ');
            }

            const data = await response.json();
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'FAQ created successfully' });
            fetchFAQs();
            setvisibleAdd(false); // Close the dialog after creating the FAQ
        } catch (error) {
            console.error('Error creating FAQ:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
        }
    };

    const handleUpdateFAQs = async () => {
        try {
            if (answer.trim() === "" || question.trim() === "") {
                console.error(`Question and Answer Can't be empty`);
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: `Question and Answer Can't be empty` });
                return;
            }

            const updatedFAQ = {
                Answer: answer,
                Question: question,
                RepositoryId: userRepo.Id,
                UserId: userDetails.UserId
            };

            const response = await fetch(`${BASE_URL}/update_FAQ?FAQ_Id=${CurrentFaqID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify(updatedFAQ)
            });

            if (!response.ok) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to Update FAQ' });
                throw new Error('Failed to Update FAQ');
            }

            const data = await response.json();
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'FAQ updated successfully' });
            fetchFAQs();
            setvisibleUpdate(false); // Close the dialog after updating the FAQ
        } catch (error) {
            console.error('Error updating FAQ:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
        }
    };

    const DeleteFAQ = async (id) => {
        try {
            const response = await fetch(`${BASE_URL}/delete_FAQ?FAQ_Id=${id}`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json'
                }
            });

            if (!response.ok) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete FAQ' });
                throw new Error('Failed to delete FAQ');
            }

            const data = await response.json();
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'FAQ deleted successfully' });
            fetchFAQs();
        } catch (error) {
            console.error('Error:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.message });
        }
    };

    const handleEdit = (rowData) => {
        setQuestion(rowData.Question);
        setAnswer(rowData.Answer);
        setvisibleUpdate(true);
        setCurrentFaqID(rowData.Id);
    };

    const handleDelete = (rowData) => {
        setCurrentFaqID(rowData.Id);
        confirm(rowData.Id);
    };

    const renderEditIcon = (rowData) => {
        return (
            <div className='flex gap-3'>
                <i className="pi pi-pencil" onClick={() => handleEdit(rowData)} style={{ cursor: 'pointer' }}></i>
                <i className="pi pi-trash" onClick={() => handleDelete(rowData)} style={{ cursor: 'pointer' }}></i>
            </div>
        );
    };

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const confirm = (id) => {
        confirmDialog({
            message: 'Are you sure you want to delete this FAQ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => DeleteFAQ(id),
            reject
        });
    };

    return (
        <div>
        {userRepo && (
            <div className="app-container">
                <Toast ref={toast} />
                <SidebarMenu />
                
                <div className="content">
                    <span>
                        <Button icon="pi pi-arrow-left" rounded outlined onClick={() => navigate('/repos')} severity="success" className="mb-2" />
                    </span>
                    <div className="flex justify-content-between align-items-center mb-4">
                    <h2>{userRepo.RepositoryName}</h2>
                    </div>
                    <DataTable value={FAQs} paginator responsiveLayout="scroll"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{first} to {last} of {totalRecords} entries"
                        rows={rowsPerPage} totalRecords={FAQs.length}
                        onPageChange={(e) => setCurrentPage(e.page + 1)}
                        first={(currentPage - 1) * rowsPerPage}
                        globalFilter={globalFilter}
                        header={renderHeader()}>
                        <Column field="Question" header="Question" sortable ></Column>
                        <Column field="Answer" header="Answer" sortable ></Column>
                        <Column header="Action" body={renderEditIcon}></Column>
                    </DataTable>
                </div>

                <Dialog header="Add FAQs" visible={visibleAdd} style={{ width: '50vw' }} onHide={() => setvisibleAdd(false)}>
                    <div style={{ padding: "20px" }}>
                        <span className="p-float-label mb-5" style={{ width: '100%' }}>
                            <InputTextarea id="question" rows={5} autoResize style={{ width: '100%' }} value={question} onChange={(e) => setQuestion(e.target.value)} />
                            <label htmlFor="question">Question</label>
                        </span>
                        <span className="p-float-label mb-5" style={{ width: '100%' }}>
                            <InputTextarea id="answer" rows={5} autoResize style={{ width: '100%' }} value={answer} onChange={(e) => setAnswer(e.target.value)} />
                            <label htmlFor="answer">Answer</label>
                        </span>
                        <div className="flex justify-content-end mt-4">
                            <Button label="Cancel" className="p-button-text mr-2" onClick={() => setvisibleAdd(false)} />
                            <Button label="Submit" onClick={CreateFAQs} />
                        </div>
                    </div>
                </Dialog>

                <Dialog header="Update FAQs" visible={visibleUpdate} style={{ width: '50vw' }} onHide={() => {
                    setvisibleUpdate(false);
                    setCurrentFaqID('');
                }}>
                    <div style={{ padding: "20px" }}>
                        <span className="p-float-label mb-5" style={{ width: '100%' }}>
                            <InputTextarea id="question" rows={5} autoResize style={{ width: '100%' }} value={question} onChange={(e) => setQuestion(e.target.value)} />
                            <label htmlFor="question">Question</label>
                        </span>
                        <span className="p-float-label mb-5" style={{ width: '100%' }}>
                            <InputTextarea id="answer" rows={5} autoResize style={{ width: '100%' }} value={answer} onChange={(e) => setAnswer(e.target.value)} />
                            <label htmlFor="answer">Answer</label>
                        </span>
                        <div className="flex justify-content-end mt-4">
                            <Button label="Cancel" className="p-button-text mr-2" onClick={() => {
                                setvisibleUpdate(false);
                                setCurrentFaqID('');
                            }} />
                            <Button label="Update" onClick={handleUpdateFAQs} />
                        </div>
                    </div>
                </Dialog>
            </div>
        )}
        
        </div>
    );
}

export default FAQ;
