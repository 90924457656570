import React from 'react';
import { Card } from 'primereact/card';
import 'primereact/resources/themes/saga-blue/theme.css'; // Change theme as needed
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import SidebarMenu from "./sidebar";

const DashboardIframe = () => {
    return (
        <div className="app-container">
            <SidebarMenu />
            <div className="content" style={{padding:0}}>
                <div className="p-d-flex p-jc-center p-ai-center p-mt-5" style={{height:'100%'}}>
                    <Card title="DynaptMax Dashboard" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <iframe
                            title="DynaptMax_Dashboard"
                            width="100%"
                            height='580'
                            style={{ flex: 1, border: 0 }}
                            src="https://app.powerbi.com/view?r=eyJrIjoiNmQ2ODE5Y2QtYzJjYy00MzY1LThiNjctMTFmNjdmNWUzZmM0IiwidCI6IjFjZGQzODJkLTI5MmItNDc5YS05ZTczLTEzOTBlYmNhMWJiOCJ9"
                            frameBorder="0"
                            allowFullScreen="true"
                            
                        ></iframe>
                    </Card>
                </div>
            </div>
        </div>

    );
};

export default DashboardIframe;
