/* eslint-disable react/prop-types */
import { useCallback, useState } from 'react';
import { Handle, Position, NodeResizer } from 'reactflow';

const handleStyle = { left: 10 };

const TextNode = ({ data, selected, id, setNodes }) => {
  const [width, setWidth] = useState(data.width || 220);
  const [height, setHeight] = useState(data.height || 55);
  
  const onChange = useCallback((evt) => {
    const value = evt.target.value;
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              value: value,
            },
          };
        }
        return node;
      })
    );
  }, [id, setNodes]);

  const onResize = (event, params) => {
    setWidth(params.width);
    setHeight(params.height);
  };

  return (
    <>
      <NodeResizer
        color="white"
        isVisible={selected}
        minWidth={150}
        minHeight={50}
        maxHeight={180}
        maxWidth={450}
        onResize={onResize}
      />
      <Handle type="target" position={Position.Top} />
      <div className='node' style={{ width, height }}>
        <textarea
          id="text"
          name="text"
          value={data.value || ''}
          onChange={onChange}
          className="nodrag"
        />
      </div>
      <Handle type="source" position={Position.Bottom} id="a" />
      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        style={handleStyle}
      />
    </>
  );
}

export default TextNode;


