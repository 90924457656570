import React from 'react';
import { useState, useEffect } from 'react';
import SidebarMenu from './sidebar';
import avatar1 from '../assests/avatar_1.png'
import avatar2 from '../assests/avatar_2.png'
import avatar3 from '../assests/avatar_3.png'
import background1 from '../assests/background1.jpg'
import background2 from '../assests/background2.jpg'
import background3 from '../assests/background3.jpg'
import background4 from '../assests/background4.jpg'
import background7 from '../assests/background7.png'
import background10 from '../assests/background10.png'
import background11 from '../assests/background11.png'
import background14 from '../assests/background14.jpg'
import background14_1 from '../assests/background14_1.png'
import background14_2 from '../assests/background14_2.png'
import background14_3 from '../assests/background14_3.png'
import background14_4 from '../assests/background14_4.png'
import background16 from '../assests/background16.png'
import background17 from '../assests/background17.png'
import background18 from '../assests/background18.png'
import background19 from '../assests/background19.png'
import background22 from '../assests/background22.png'
import background25 from '../assests/background25.png'
import background26 from '../assests/background26.png'
import background27 from '../assests/background27.png'
import community1 from '../assests/Ellipse 78.png'
import community2 from '../assests/Ellipse 79.png'
import community3 from '../assests/Ellipse 80.png'
import community4 from '../assests/Ellipse 81.png'

import { useAuth } from '../auth/authContext';
import { Carousel } from 'primereact/carousel';

const Home = () => {
  const { userLoggedIn } = useAuth();


  const [isHovered, setIsHovered] = useState(false);

  const sectionStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundImage: `url(${background7})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };

  const cardStyle = {
    backgroundColor: '#ffffff',
    padding: '20px',
    margin: '10px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    flex: '1',
    minWidth: '280px',
    minHeight: '300px',
    transition: 'border-color 0.3s ease, transform 0.3s ease',
    borderBottom: '2px solid transparent',
    borderRight: '2px solid transparent',
  };

  const headingCardStyle = {
    ...cardStyle,
    backgroundColor: '#d4edda',
    flex: '1.5',
    minWidth: '420px',
  };

  const handleMouseEnter = (e) => {
    e.currentTarget.style.transform = 'scale(1.01)';
    e.currentTarget.style.boxShadow = '8px 8px 0px 0px rgba(0, 100, 0, 0.9)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = 'scale(1)';
    e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
  };

  const cardsContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '1200px'
  };

  const buttonStyle = {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px'
  };

  const headingStyle = {
    fontSize: '24px',
    margin: '0 0 10px 0'
  };

  const images = [background1, background2, background3, background4];
  const texts = [
    "Equip your team with <span style='color: green'>workflow automation</span> and knowledge base <span style='color: green'>integration</span>.",
    "Partner with a <span style='color: green'>reliable provider</span> to handle surges in <span style='color: green'>support volume</span>.",
    "Streamline agent workflows and ensure no <span style='color: green'>inquiry falls through</span>.",
    "Implement chatbots for <span style='color: green'>24/7</span> support and <span style='color: green'>automated</span> ticketing for faster resolution."
  ];

  const images2 = [background14_1, background14_2, background14_3, background14_4];

  const [index, setIndex] = useState(0);
  const [index2, setIndex2] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
      setIndex2((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{
        width: '100vw',
        backgroundColor: '#ffffff',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        // padding: '20px',
        boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
        zIndex: '4',
      }}>
        <SidebarMenu />
      </div>
      <div style={{
        marginTop: '115px',
        width: '100vw'
      }}>

        <section style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}>
          <div style={{
            position: 'relative',
            marginTop: '30px',
            width: '100%',
          }}>
            <img
              src={images[index]}
              alt="Background"
              style={{
                width: '100%',
                borderRadius: '10px',
                height: '90vh'
              }}
            />
            <div style={{
              position: 'absolute',
              top: '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(255, 255, 255, 255)',
              borderRadius: '25px',
              padding: '10px 20px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              alignItems: 'center'
            }}>
              <input
                type="text"
                placeholder="create according to you"
                style={{
                  border: 'none',
                  outline: 'none',
                  fontSize: '16px',
                  marginRight: '10px',
                  width: '400px'
                }}
              />
            </div>
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px'
            }}>
              <button style={{ padding: '5px 10px', backgroundColor: 'rgba(255, 255, 255, 0.6)', border: 'none', cursor: 'pointer', borderRadius: '5px', fontSize: '14px' }}>About us</button>
              <button style={{ padding: '5px 10px', backgroundColor: 'rgba(255, 255, 255, 0.6)', border: 'none', cursor: 'pointer', borderRadius: '5px', fontSize: '14px', marginLeft: '25px' }}>Templates</button>
              <button style={{ padding: '5px 10px', backgroundColor: 'rgba(255, 255, 255, 0.6)', border: 'none', cursor: 'pointer', borderRadius: '5px', fontSize: '14px', marginLeft: '25px' }}>Teams</button>
              <button style={{ padding: '5px 10px', backgroundColor: 'rgba(255, 255, 255, 0.6)', border: 'none', cursor: 'pointer', borderRadius: '5px', fontSize: '14px', marginLeft: '25px' }}>Pricing</button>

            </div>
          </div>
          <div style={{
            marginTop: '20px',
            padding: '0 20px'
          }}>
            <h1>
              <h1 dangerouslySetInnerHTML={{ __html: texts[index] }}></h1>
            </h1>
          </div>
        </section>


        <section style={{
          padding: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{ flex: '1', textAlign: 'center', paddingRight: '20px', maxWidth: '20%', marginLeft: '10px', fontSize: '1.5em' }}>
            <h2 style={{ color: 'green', fontSize: '2em' }}>AI AVATARS</h2>
            <p style={{ textAlign: 'left' }}>Looking for seamless integration without breaking the bank? Our solution offers both affordability and ease of implementation, ensuring minimal disruption to your existing setup. With our low-price offering and hassle-free integration.</p>
            <button style={{ backgroundColor: 'green', color: 'white', padding: '10px 20px', borderRadius: '5px', border: 'none', cursor: 'pointer', fontSize: '1em', marginTop: '10px' }}>View Demo</button>
          </div>
          <div style={{ flex: '2', display: 'flex', justifyContent: 'center', position: 'relative', fontSize: '2em', marginTop: '5%', marginLeft: '10%' }}>
            <div style={{ zIndex: '2' }}>
              <img src={avatar1} alt="Avatar 1" style={{ width: '90%', height: '80%', marginRight: '-10px' }} />
              <p style={{ textAlign: 'center' }}>IT Helpdesk AI Agent</p>
            </div>
            <div style={{ position: 'relative', zIndex: '3' }}>
              <img src={avatar2} alt="Avatar 2" style={{ width: '90%', height: '80%', position: 'relative' }} />
              <p style={{ textAlign: 'center' }}>Mental Health AI Agent</p>
            </div>
            <div style={{ zIndex: '2' }}>
              <img src={avatar3} alt="Avatar 3" style={{ width: '90%', height: '80%', marginLeft: '-10px' }} />
              <p style={{ textAlign: 'center' }}>Ask Dyna</p>
            </div>
          </div>
        </section>

        <section style={{
          padding: '50px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}>
          <div style={{
            width: '100%', 
            display: 'flex',
            justifyContent: 'center', 
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <h2 style={{fontFamily: 'Jost',color: '#182C62',overflowWrap:'break-word',fontSize: '4em' }}>DynaptMAX Bot Studio</h2>
            <p style={{ fontFamily: 'Jost',color: '#182C62',fontSize: '3em' }}>Create your Generative AI based Talking AI Agent in minutes!</p>
          </div>

          <div style={{
            width: '100%', height: "600px", display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <iframe width="100%" height='100%'
              // style=" background-color: black; box-shadow: 0px 4px 25px 0px #213C87;"
              style={{ backgroundColor: 'black', boxShadow: '0px 4px 25px 0px #213C87' }}
              src="https://www.youtube.com/embed/_pC0rDQp7O4?autoplay=1&loop=1&mute=1&playsinline=1&controls=1"
              frameborder="0" allow="autoplay; encrypted-media" allowfullscreen id="use-cases">
            </iframe>
          </div>

        </section>



        <section style={sectionStyle}>
          <div style={cardsContainerStyle}>
            <div style={cardStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <h3>Integrated ITSM System</h3>
              <p>DynaptMAX automatically generates tickets for all requests as well as summarizes conversations for human support through its integrated ITSM system.</p>

            </div>
            <div style={headingCardStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <h2 style={headingStyle}>Support offerings</h2>
              <p>DynaptMAX is a Generative-AI based help desk system designed to revolutionize customer support</p>
              <button style={buttonStyle}>view all services</button>
            </div>
            <div style={cardStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <h3>24x7 AI Support</h3>
              <p>Our 24X7x365 MAX bot services guarantee round the clock support at a reasonable price, backed by time bound SLAs.</p>

            </div>
            <div style={cardStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <div style={{ flex: '1' }}>
                  <h3>Provide trustworthy & secure answers</h3>
                  <p>Dyna, our groundbreaking AI chatbot, utilizes cutting-edge AI technology to provide secure, precise responses, resolving customer inquiries and promptly reducing your team's ticket volume.</p>
                </div>
                <div style={{ flex: '2', marginLeft: '10%', borderColor: '#d4edda', borderRadius: '5px' }}>
                  <img src={background19} alt='image' style={{ width: '80%', height: '80%' }} />
                </div>
              </div>
            </div>
            <div style={cardStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <div style={{ flex: '1' }}>
                  <h3>Omni-channel</h3>
                  <p>Dynapt is an omni-channel service, utilizing channels like Email, WhatsApp, Web, Teams, Inbound Calls Bot, etc., to communicate with clients while tracking all their requests and their solution ETAs.</p>
                </div>
                <div style={{ flex: '2', marginLeft: '10%' }}>
                  <img src={background18} alt='image' style={{ width: '80%', height: '80%' }} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ padding: '50px', textAlign: 'left' }}>

          <div style={{ display: 'flex', gap: '20px', alignItems: 'center', flexWrap: 'wrap' }}>
            <div style={{ flex: '1', maxWidth: '30%' }}>
              <h2 style={{ fontSize: '2em', color: '#2E7D32' }}>Who Can Benefit from Us</h2>
              <h3>Undertakes laborious physical exercise except to obtain some advantage from it.</h3>
              <div style={{
                padding: '10px 20px',
                backgroundColor: 'transparent',
                fontSize: '1.2em',
                marginBottom: '10px',
                position: 'relative',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                display: 'flex',
                alignItems: 'center'
              }}
                onMouseOver={(e) => {
                  e.currentTarget.querySelector('span').style.opacity = '1';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.querySelector('span').style.opacity = '0';
                }}>
                <span style={{
                  display: 'inline-block',
                  opacity: '0',
                  transition: 'all 0.3s ease',
                  color: '#2E7D32',
                  marginRight: '10px',
                  fontSize: '1.5em'
                }}>&#x25B6;</span>
                Business owners
              </div>
              <div style={{
                padding: '10px 20px',
                backgroundColor: 'transparent',
                fontSize: '1.2em',
                marginBottom: '10px',
                position: 'relative',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                display: 'flex',
                alignItems: 'center'
              }}
                onMouseOver={(e) => {
                  e.currentTarget.querySelector('span').style.opacity = '1';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.querySelector('span').style.opacity = '0';
                }}>
                <span style={{
                  display: 'inline-block',
                  opacity: '0',
                  transition: 'all 0.3s ease',
                  color: '#2E7D32',
                  marginRight: '10px',
                  fontSize: '1.5em'
                }}>&#x25B6;</span>
                Language Learners
              </div>
              <div style={{
                padding: '10px 20px',
                backgroundColor: 'transparent',
                fontSize: '1.2em',
                marginBottom: '10px',
                position: 'relative',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                display: 'flex',
                alignItems: 'center'
              }}
                onMouseOver={(e) => {
                  e.currentTarget.querySelector('span').style.opacity = '1';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.querySelector('span').style.opacity = '0';
                }}>
                <span style={{
                  display: 'inline-block',
                  opacity: '0',
                  transition: 'all 0.3s ease',
                  color: '#2E7D32',
                  marginRight: '10px',
                  fontSize: '1.5em'
                }}>&#x25B6;</span>
                Professionals
              </div>
              <div style={{
                padding: '10px 20px',
                backgroundColor: 'transparent',
                fontSize: '1.2em',
                position: 'relative',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                display: 'flex',
                alignItems: 'center'
              }}
                onMouseOver={(e) => {
                  e.currentTarget.querySelector('span').style.opacity = '1';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.querySelector('span').style.opacity = '0';
                }}>
                <span style={{
                  display: 'inline-block',
                  opacity: '0',
                  transition: 'all 0.3s ease',
                  color: '#2E7D32',
                  marginRight: '10px',
                  fontSize: '1.5em'
                }}>&#x25B6;</span>
                Ecommerce
              </div>
            </div>
            <div style={{ flex: '1', position: 'relative', textAlign: 'center' }}>
              <img src={background14} alt="image" style={{ maxWidth: '100%', height: 'auto', boxShadow: '0 0 10px rgba(0,0,0,0.1)', borderRadius: '10px' }} />
              <div style={{ position: 'absolute', top: '5%', left: '40%', transform: 'translateX(-50%)', display: 'flex', gap: '10px', flexWrap: 'wrap', zIndex: '2' }}>
                <div style={{ padding: '10px 20px', backgroundColor: 'white', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', fontSize: '1em' }}>
                  Generate AI Agent
                </div>
                <div style={{ padding: '10px 20px', backgroundColor: 'white', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', fontSize: '1em' }}>
                  Essays
                </div>
                <div style={{ padding: '10px 20px', backgroundColor: 'white', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', fontSize: '1em' }}>
                  Employment
                </div>
                <div style={{ padding: '10px 20px', backgroundColor: 'white', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', fontSize: '1em' }}>
                  All Features
                </div>
              </div>
              <img src={images2[index2]} alt="image" style={{
                maxWidth: '75%',
                height: 'auto',
                position: 'absolute',
                bottom: '5%',
                left: '50%',
                transform: 'translateX(-50%)',
                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                zIndex: '1'
              }} />
            </div>
          </div>
        </section>


        {/* 
            <section style={{ padding: '50px', textAlign: 'left', backgroundColor: '#182c62'}}>
              <h2 style={{ fontSize: '8em', color: '#2E7D32', textAlign: 'center', marginLeft:'350px', position: 'relative' }}>How IT'S WORK</h2>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap:'50px' }}>
                <div style={{ flex: '1', textAlign: 'center' }}>
                  <div style={{ margin: '20px 0', padding: '40px', backgroundColor: '#b8b2d8', borderRadius: '8px' }}>
                    <h3 style={{ fontSize: '1.5em', color: '#2E7D32' }}>01</h3>
                    <p style={{ fontSize: '1.2em', color: '#333' }}>Select your template</p>
                  </div>
                </div>
                <div style={{ flex: '1' }}>
                  <div style={{ margin: '20px 0', padding: '40px', backgroundColor: '#1a1f2b', borderRadius: '8px' }}>
                    <h3 style={{ fontSize: '1.5m', color: '#2E7D32' }}>01</h3>
                    <h2 style={{ fontSize: '1.5em', color: '#f0f0f0' }}>Select your template</h2>
                    <p style={{ fontSize: '1.5em', color: '#f0f0f0' }}>Chooses to enjoy a pleasure that has annoying consequences or one who avoids a pain that produces no resultant pleasure the master builder of human happiness. </p>
                  </div>
                </div>
              </div>
            </section> */}



        <section style={{
          padding: '50px',
          textAlign: 'center'
        }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {/* <h2>Why you should choose us</h2> */}
          <div style={{ display: 'block', margin: '0 auto' }}>
            <img src={(isHovered) ? background11 : background10} alt="CEO" style={{ width: '100%', height: '100%' }} />
          </div>
        </section>

        <section style={{
          padding: '50px',
          textAlign: 'center'
        }}>
          <div style={{ position: 'relative' }}>
            <img src={background16} alt="white web background" style={{ width: '100%', height: '100%' }} />
            <img src={background17} alt="red web background" style={{
              maxWidth: '100%',
              height: 'auto',
              position: 'absolute',
              bottom: '10%',
              left: '50%',
              transform: 'translateX(-50%)',
              boxShadow: '0 0 10px rgba(0,0,0,0.1)',
              zIndex: '1'
            }} />
            <div style={{
              position: 'absolute',
              top: '15%',
              left: '30%',
              transform: 'translateX(-50%)',
              color: '#3F1BE3',
              fontSize: '6em',
              fontWeight: 'bold',
              zIndex: '2'
            }}>
              Create your own
            </div>
            <div style={{
              position: 'absolute',
              top: '25%',
              left: '25%',
              transform: 'translateX(-50%)',
              color: '#501DE4',
              fontSize: '6em',
              fontWeight: 'bold',
              zIndex: '2'
            }}>
              Repository
            </div>
            <div style={{
              position: 'absolute',
              top: '40%',
              left: '70%',
              transform: 'translateX(-50%)',
              zIndex: '2'
            }}>
              <img src={background22} alt='image' style={{ height: '120%', width: '120%' }} />
            </div>
          </div>
        </section>



        <footer style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          padding: '40px 50px',
          backgroundColor: 'white',
          color: '#333'
        }}>
          <div style={{ flex: '1 1 100%', marginBottom: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ flex: '1', textAlign: 'left' }}>
                <h3 style={{ fontSize: '2em', color: '#2E7D32', marginBottom: '10px' }}>Our Community</h3>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <img src={community1} alt="Community member 1" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                  <img src={community2} alt="Community member 2" style={{ width: '50px', height: '50px', borderRadius: '50%', marginLeft: '-10px' }} />
                  <img src={community3} alt="Community member 3" style={{ width: '50px', height: '50px', borderRadius: '50%', marginLeft: '-10px' }} />
                  <img src={community4} alt="Community member 4" style={{ width: '50px', height: '50px', borderRadius: '50%', marginLeft: '-10px' }} />
                </div>
                <p style={{ fontSize: '1.5em', color: '#555' }}>high quality effective!...</p>
              </div>
              <div style={{ flex: '1', textAlign: 'left' }}>
                <h3 style={{ fontSize: '2em', color: '#2E7D32', marginBottom: '10px', marginLeft: '10px' }}>Useful Links</h3>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '30px' }}>
                  <div style={{ marginBottom: '10px' }}>
                    <ul style={{ listStyle: 'none', padding: '0', margin: '0', fontSize: '1.5em', color: '#555', lineHeight: '2' }}>
                      <li>• Home</li>
                      <li>• How it's work</li>
                      <li>• Pricing</li>
                      <li>• Contact</li>
                      <li>• Creative writing</li>
                    </ul>
                  </div>
                  <div>
                    {/* <ul style={{ listStyle: 'none', padding: '0', margin: '0', fontSize: '1.2em', color: '#555', lineHeight: '1.6' }}>
                                        <li>• About Us</li>
                                        <li>• FAQ</li>
                                        <li>• Help center</li>
                                        <li>• Community Page</li>
                                    </ul> */}
                  </div>
                </div>
              </div>
              <div style={{ flex: '1', textAlign: 'left', maxWidth: '20%' }}>
                <h3 style={{ fontSize: '2em', color: '#2E7D32', marginBottom: '10px' }}>Subscribe Us</h3>
                <p style={{ fontSize: '1.5em', color: '#555', marginBottom: '10px' }}>Subscribe to our newsletter & get updates to your inbox directly.</p>
                <input type="email" placeholder="Email address..." style={{
                  width: '100%',
                  padding: '10px',
                  marginTop: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  marginBottom: '10px'
                }} />
                <button style={{
                  width: '100%',
                  padding: '10px',
                  backgroundColor: '#2E7D32',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  fontSize: '1.5em'
                }}>Subscribe Us</button>
              </div>
            </div>
          </div>
          {/* <div style={{ flex: '1 1 100%', textAlign: 'left', marginBottom: '20px' }}>
                    <h4 style={{ fontSize: '1.2em', color: '#2E7D32', marginBottom: '10px' }}>CEO & CO-founder</h4>
                    <div style={{ width: '100%', height: '50px', backgroundColor: '#D6F5D6', borderRadius: '5px' }}></div>
                </div> */}
          <div style={{ display: 'flex', flex: '1 1 100%', borderTop: '1px solid #ccc', justifyContent: 'space-between' }}>
            <div style={{ flexBasis: '100%', marginTop: '20px', paddingTop: '10px', textAlign: 'left' }}>
              <p style={{ fontSize: '0.8em', color: '#555', marginBottom: '5px' }}>Terms & conditions apply</p>
              <p style={{ fontSize: '0.8em', color: '#555', marginBottom: '5px' }}>All Rights Reserved.</p>
            </div>
            <div style={{ marginRight: '50px' }}>
              <p style={{ fontSize: '1.5em', color: '#555', marginBottom: '5px', marginTop: '30px' }}>Follow on</p>
              <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                <div style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: '#ccc' }}>
                  <img src={background25} style={{ position: 'relative', top: '15%', left: '15%' }} />
                </div>
                <div style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: '#ccc' }}>
                  <img src={background26} style={{ position: 'relative', top: '20%', left: '20%' }} />
                </div>
                <div style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundColor: '#ccc' }}>
                  <img src={background27} style={{ position: 'relative', top: '20%', left: '20%' }} />
                </div>
              </div>
            </div>
          </div>
        </footer>

      </div>
    </div>
  );
};

export default Home;